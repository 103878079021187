import React, { useState, useEffect } from 'react'
import styles from './ModalDestacado.module.css'

import { FC } from 'react';
import ReactModal from 'react-modal';
import usePlanesApi from '../../../hooks/usePlanesApi';
import Loading from '../../Loading/Loading';
import { Plan } from '../../../interfaces/Plan';
import { SiGmail } from 'react-icons/si';
import { FaWhatsapp } from 'react-icons/fa';

// Establece el elemento raíz del modal en el documento (debe hacerse una vez)
ReactModal.setAppElement('#root');

interface ModalDestacadoProps {
    dataUsuario;
    plan: Plan;
    isOpen: boolean;
    onRequestClose: () => void;
    reiniciarFormulario: () => void;
}

const ModalDestacado: FC<ModalDestacadoProps> = ({
    isOpen,
    onRequestClose,
    dataUsuario,
    plan,
    reiniciarFormulario
}) => {

    const [data, setData] = useState({
        telefono: "",
        nombre: dataUsuario?.nombre,
        idUser: dataUsuario?._id,
        correo: dataUsuario?.correo,
        nombrePlan: plan.titulo,
        origen: 'Web app'
    })
    const [mensajeErrorForm, setMensajeErrorForm] = useState(null);
    const [selectedInput, setSelectedInput] = useState(null);

    const { mensajeApiPlanes, error, loading, destacarPlan } = usePlanesApi();

    useEffect(() => {
        setData({
            ...data,
            nombre: dataUsuario?.nombre,
            correo: dataUsuario?.correo,
            idUser: dataUsuario?._id,
            nombrePlan: plan.titulo,
        })
    }, [dataUsuario, plan])


    const handleInputFocus = (event) => {
        setSelectedInput(event.target.name);
    };

    const handleInputBlur = () => {
        setSelectedInput(null);
    };

    const handleChange = (name, value) => {
        setData({ ...data, [name]: value });
    };

    const handleDestacarPlan = async () => {
        setMensajeErrorForm(null)
        if (!data.telefono) {
            setMensajeErrorForm("Por favor, indica un número de teléfono para que podamos contactar.")
            return;
        }
        const res = await destacarPlan(data)
        if (res) {
            reiniciarFormulario()
        }
    }

    const handleMailClick = () => {
        const subject = encodeURIComponent('Destacar el plan publicado');
        const body = encodeURIComponent('¡Hola! Me gustaría destacar el plan que he publicado. ¿Me podéis dar más información?');
        const mailtoUrl = `mailto:planlocal.contacto@gmail.com?subject=${subject}&body=${body}`;
        window.open(mailtoUrl, '_blank');
    };

    const handleWhatsAppClick = () => {
        const message = encodeURIComponent('¡Hola! Me gustaría destacar el plan que he publicado. ¿Me podéis dar más información?');
        const whatsappUrl = `https://wa.me/34622481046?text=${message}`;
        window.open(whatsappUrl, '_blank');
    };

    return (
        <ReactModal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Saber más"
            className={styles.modal}
            overlayClassName={styles.overlay}
        >
            <h2 className={styles.tituloModal}>¡El plan ha sido publicado!</h2>
            <p className={styles.textoInfo}><strong>¡Destaca tu plan</strong> y aparece <strong>siempre</strong> al principio desde <strong> solo 2€</strong> por semana o <strong>por 5€</strong> al mes!</p>
            <div>
                <span className={styles.contactoText}>Puedes contactarnos por</span>
                <span onClick={handleMailClick} className={styles.icono}>
                    <SiGmail size={24} color="#D44638" />
                </span>
                <span className={styles.contactoText}>o</span>
                <span onClick={handleWhatsAppClick} className={styles.icono}>
                    <FaWhatsapp size={24} color="#25D366" />
                </span>
            </div>
            <p>O déjanos tu teléfono y te contactaremos por Whatsapp 🤗</p>
            <div className={styles.formGroup}>
                <input
                    type="text"
                    name="telefono"
                    id="telefono"
                    placeholder='Teléfono'
                    maxLength={50}
                    value={data.telefono}
                    className={`${styles.formInputTelefono} ${selectedInput === 'telefonoContacto' ? styles.focused : ''}`}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                    autoComplete="tel"
                />
            </div>
            <div className={styles.botonesModal}>
                <button onClick={onRequestClose} className={styles.botonCancelar}>Volver</button>
                <button onClick={handleDestacarPlan} className={styles.botonAceptar} disabled={loading} >Saber más</button>
            </div>
            {mensajeApiPlanes && <p>{mensajeApiPlanes}</p>}
            {mensajeErrorForm && <p className={styles.error}>{mensajeErrorForm}</p>}
            {error && <p className={styles.error}>{error}</p>}
            {loading && <Loading />}
        </ReactModal>
    );
}

export default ModalDestacado;
