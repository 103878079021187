import React, { FC, useEffect, useState, useRef } from 'react';
import ReactGoogleAutocomplete from 'react-google-autocomplete';
import styles from './GooglePlacesAutocomplete.module.css'
import { saveLocation } from '../../utils/saveLocationStorage';
import { AiOutlineClose } from 'react-icons/ai';

interface GooglePlacesAutocompleteComponent {
    placeholder?: string;
    data: any;
    setData: (any) => void;
    handleBuscarPlanes?: (any) => void;
    setToken?: (any) => void
}

const GooglePlacesAutocompleteComponent: FC<GooglePlacesAutocompleteComponent> = ({
    placeholder = 'Buscar ubicación',
    data,
    setData,
    handleBuscarPlanes,
    setToken,
}) => {

    const searchContainerRef = useRef(null);

    useEffect(() => {
        const styleElement = document.createElement("style");
        styleElement.innerHTML = `
            .pac-container {
                z-index: 1051 !important; /* Asegúrate de que el contenedor de sugerencias esté por encima de otros elementos */
            }
            .pac-container:after {
                display: none !important; /* Oculta el mensaje de "powered by Google" */
            }
            .pac-item {
                font-size: 14px; /* Ajusta el tamaño de fuente de las sugerencias si es necesario */
            }
            .pac-icon {
                display: none !important; /* Oculta los iconos junto a las sugerencias */
            }
        `;

        document.head.appendChild(styleElement);
        return () => {
            document.head.removeChild(styleElement);
        };
    }, []);

    const handlePlaceSelected = (place) => {
      if(setToken)  setToken(true)
        if (place?.address_components) {
            const addressComponents = place.address_components;
            let street = '';
            let streetNumber = '';
            let city = '';
            addressComponents.forEach(component => {
                if (component.types.includes('route')) {
                    street = component.long_name;
                }
                if (component.types.includes('street_number')) {
                    streetNumber = component.long_name;
                }
                if (component.types.includes('locality')) {
                    city = component.long_name;
                }
            });

            const formattedAddress = `${street} ${streetNumber} ${city}`;
            const lat = place.geometry.location.lat();
            const lng = place.geometry.location.lng();

            setData(prevData => ({
                ...prevData,
                direccion: formattedAddress.trim(),
                municipio: city,
                ubicacion: {
                    type: 'Point',
                    coordinates: [lng, lat],
                }
            }));
            saveLocation({ lng, lat }, city);
        }
    };

    const handleChange = (e) => {

        setData({
            ...data,
            direccion: e.target.value
        })
    }

    const onClean = () => {
        setData(prevData => ({
            ...prevData,
            direccion: "",
            municipio: null,
            ubicacion: null
        }));
    }

    return (
        <div className={styles.locationSearchContainer} ref={searchContainerRef} >
            <div className={styles.inputButton}>

                <ReactGoogleAutocomplete
                    onChange={handleChange}
                    apiKey="AIzaSyBQ7VfutSto5dA3k6I6PYw8ukXur7EhlR8"
                    onPlaceSelected={handlePlaceSelected}
                    options={{
                        types: ['geocode'],
                        componentRestrictions: { country: 'es' },
                    }}
                    className={styles.locationSearchInput}
                    placeholder={placeholder}
                    
                    value={data?.direccion}
                    onKeyUp={handleBuscarPlanes}
                />
                 {handleBuscarPlanes &&  <AiOutlineClose  className={styles.iconDelete} onMouseDown={(e) => e.preventDefault()}  onClick={onClean} />}
                {handleBuscarPlanes &&
                    <button
                        className={styles.botonBuscar}
                        onClick={handleBuscarPlanes}
                    >
                        Buscar
                    </button>}
            </div>
        </div>
    );
};

export default GooglePlacesAutocompleteComponent