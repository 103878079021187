import React from 'react'
import styles from './FaseCategorias.module.css'
import Categorias from '../../../../components/Categorias/Categorias'
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

const FaseCategorias = ({ setFase, categorias, setCategorias }) => {


  return (
    <section className={styles.container}>
      <div className={styles.title}>
        <span>SELECCIONA LAS CATEGORÍAS</span>
      </div>
      <Categorias
        primeraOpcion='Otros'
        categorias={categorias}
        setCategorias={setCategorias}
        containerWrap={true}
      />
      <div className={styles.container}>
        <div className={styles.buttonContainer}>
            <button className={styles.volverButton} onClick={() => setFase(prevFase => prevFase - 1)}>
              <FaArrowLeft className={styles.flechaVolverIcono} />
            </button>
            <button className={styles.continueButton} onClick={() => setFase(prevFase => prevFase + 1)}>
              Continuar <FaArrowRight className={styles.flechaAvanzarIcono} />
            </button>
        </div>
      </div>
    </section>
  )
}

export default FaseCategorias