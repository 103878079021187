import React from 'react'
import './Politicas.css'

const AvisoLegal = () => {
  return (
    <section className='contenido'>
        <h1>Aviso Legal</h1>
        <p>Última actualización: 20/07/2024</p>
        <p>Este Aviso Legal establece los términos y condiciones para el uso de nuestro sitio web de eventos. Al acceder y utilizar nuestro sitio web, aceptas cumplir y estar sujeto/a a este Aviso Legal. Si no estás de acuerdo con estos términos, te recomendamos que no utilices nuestro sitio web.</p>
        <h2>Información general</h2>
        <p>1.1. Titular del sitio web: PlanLocal</p>
        <p> 1.2. Contacto: planlocal.contacto@gmail.com</p>
        <h2>Propiedad intelectual</h2>
        <p>2.1. Derechos de autor: Todos los contenidos presentes en nuestro sitio web, incluyendo pero no limitado a textos, gráficos, logotipos, imágenes, videos, música y software, están protegidos por derechos de autor y otros derechos de propiedad intelectual. Estos contenidos son propiedad nuestra o de nuestros licenciantes. Queda prohibida cualquier reproducción, distribución, modificación o utilización de estos contenidos sin nuestro consentimiento expreso por escrito.</p>
        <p>2.2. Marcas comerciales: Todas las marcas comerciales, logotipos y nombres comerciales presentes en nuestro sitio web son propiedad nuestra o de nuestros licenciantes. Queda prohibido el uso no autorizado de cualquier marca comercial, logotipo o nombre comercial sin nuestro consentimiento previo por escrito.</p>
        <h2>Exclusión de responsabilidad</h2>
        <p>3.1. Información general: La información proporcionada en nuestro sitio web tiene un carácter meramente informativo. No nos responsabilizamos de la exactitud, actualidad o integridad de dicha información. El uso de cualquier información o materiales en nuestro sitio web es bajo tu propio riesgo.</p>
        <p>3.2. Enlaces a sitios web de terceros: Nuestro sitio web puede contener enlaces a sitios web de terceros. Estos enlaces se proporcionan únicamente para tu conveniencia. No tenemos control sobre el contenido o las políticas de privacidad de estos sitios web externos. No nos responsabilizamos de ningún daño o pérdida resultante del uso de estos enlaces o del acceso a sitios web de terceros.</p>
        <h2>Ley aplicable y jurisdicción</h2>
        <p>Este Aviso Legal se rige por las leyes del estado Español y la Unión Europea.</p>  
    </section>
  )
}

export default AvisoLegal