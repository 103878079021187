import React from 'react'
import styles from './RadioDistancia.module.css'

interface RadioDistanciaProps {
    radio: number;
    setRadio: (radio: number) => void;
}

const RadioDistancia: React.FC<RadioDistanciaProps> = ({
    radio,
    setRadio,
}) => {
    
    const handleRadioChange = (event) => {
        const newValue = event.target.value;
        setRadio(newValue);
        const percentage = ((event.target.value - event.target.min) / (event.target.max - event.target.min)) * 100;
        event.target.style.background = `linear-gradient(to right, #000000 ${percentage}%, black ${percentage}%)`;
    };
    return (
        <section className={styles.containerDistancia}>
            <div className={styles.containerTextoDistancia}>
                <p className={styles.distancia}>Distancia:&nbsp;</p>
                <p className={styles.kilometros}>{radio} km</p>
            </div>
            <div className={styles.containerInput}>
                <input
                    type="range"
                    min={0}
                    max={50}
                    step={1}
                    value={radio}
                    onChange={handleRadioChange}
                    className={styles.rangeInput}
                />
                {/* <Slider
          min={0}
          max={50}
          step={1}
          value={radio}
          onChange={handleSliderChange}
        /> */}
            </div>
        </section>
    )
}

export default RadioDistancia