import React from 'react'
import './Politicas.css'

const CondicionesGenerales = () => {
  return (
    <section className='contenido'>
      <h1>Condiciones Generales de Uso</h1>
      <p>Última actualización: 20/07/2024</p>
      <p>Bienvenido/a a nuestro sitio web de eventos. Al acceder y utilizar nuestro sitio web, aceptas cumplir y estar sujeto/a a las siguientes Condiciones Generales de Uso. Si no estás de acuerdo con estas condiciones, te recomendamos que no utilices nuestro sitio web.</p>
      <h2>Uso del sitio web</h2>
      <p>1.1. Aceptación de responsabilidad: Nuestro sitio web se proporciona con el objetivo de brindar información sobre eventos y facilitar la compra de entradas. No nos responsabilizamos de la precisión, actualidad o integridad de la información proporcionada en el sitio web. El uso de cualquier información o materiales en nuestro sitio web es bajo tu propio riesgo.</p>
      <p>1.2. Acceso y disponibilidad: Nos esforzamos por mantener nuestro sitio web disponible de forma continua, pero no garantizamos su disponibilidad ininterrumpida o libre de errores. Nos reservamos el derecho de suspender, interrumpir o modificar el acceso al sitio web en cualquier momento y sin previo aviso.</p>
      <p>1.3. Registro: Algunas funciones de nuestro sitio web pueden requerir que te registres. Al registrarte, te comprometes a proporcionar información precisa y actualizada. Eres responsable de mantener la confidencialidad de tus credenciales de acceso y de todas las actividades que ocurran bajo tu cuenta.</p>
      <h2>Propiedad intelectual</h2>
      <p>2.1. Derechos de autor: Todos los contenidos presentes en nuestro sitio web, incluyendo pero no limitado a textos, gráficos, logotipos, imágenes, videos, música y software, están protegidos por derechos de autor y otros derechos de propiedad intelectual. Estos contenidos son propiedad nuestra o de nuestros licenciantes. Queda prohibida cualquier reproducción, distribución, modificación o utilización de estos contenidos sin nuestro consentimiento expreso por escrito.</p>
      <p>2.2. Marcas comerciales: Todas las marcas comerciales, logotipos y nombres comerciales presentes en nuestro sitio web son propiedad nuestra o de nuestros licenciantes. Queda prohibido el uso no autorizado de cualquier marca comercial, logotipo o nombre comercial sin nuestro consentimiento previo por escrito.</p>
      <h2>Enlaces a sitios web de terceros</h2>
      <p>Nuestro sitio web puede contener enlaces a sitios web de terceros. Estos enlaces se proporcionan únicamente para tu conveniencia. No tenemos control sobre el contenido o las políticas de privacidad de estos sitios web externos. No nos responsabilizamos de ningún daño o pérdida resultante del uso de estos enlaces o del acceso a sitios web de terceros. Te recomendamos que revises las políticas de privacidad y las condiciones de uso de dichos sitios web antes de interactuar con ellos.</p>
      <h2>Limitación de responsabilidad</h2>
      <p>En la máxima medida permitida por la ley, no seremos responsables por ningún daño directo, indirecto, incidental, especial o consecuente que surja del uso de nuestro sitio web o de la información contenida en él. PlanLocal se compromete a evitar el uso inadecuado de nuestra aplicación evitando la publicación de contenido apropiado haciendo uso de los sistemas que provee Google Cloud añadido a nuestro compromiso de revisar el contenido de la aplicación constantemente para eliminar todo aquel contneido que sea inapropiado.</p>
    </section>
  )
}

export default CondicionesGenerales