import { useRef, useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import './PrimerHTML.css'

const PrimerHTML = () => {
  const videoRef = useRef(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const onVideoEnd = () => {
    videoRef.current.currentTime = 0;
    videoRef.current.play();
  };

  // useInView hook se encargará de detectar si el video está visible en el viewport
  const [ref, inView] = useInView({
    triggerOnce: true, // Solo activamos el trigger una vez para evitar repetición
    rootMargin: '0px', // El video será detectado cuando esté completamente en el viewport
  });

  /*  useEffect(() => {
     // Cuando el elemento se vuelve visible, iniciamos la reproducción
     if (inView) {
       setIsVideoPlaying(true);
     } else {
       // Pausamos la reproducción cuando sale del viewport
       setIsVideoPlaying(false);
       videoRef.current.pause();
     }
   }, [inView]); */

  useEffect(() => {
    // Controlamos la reproducción del video basado en el estado isVideoPlaying
    if (isVideoPlaying && videoRef.current) {
      videoRef.current.play();
    } else if (!isVideoPlaying && videoRef.current) {
      videoRef.current.pause();
    }
  }, [isVideoPlaying]);

  return (
    <section>
      <h2 className='tituloContainer'>Encuentra planes, eventos y experiencias cerca de casa.</h2>
      <article className='badgesStores'>
        <a href='https://play.google.com/store/apps/details?id=com.EventZone&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' target="_blank" rel="noopener noreferrer">
          <img alt='Disponible en Google Play' className='googlePlayBadge' src='/images/googlePlayBadge.png' />
        </a>
        <a href='https://apps.apple.com/es/app/planlocal/id6566418095?itsct=apps_box_link&itscg=30200' target="_blank" rel="noopener noreferrer">
          <img alt='Disponible en Apple Store' className='appleBadge' src='/images/appleBadge.png' />
        </a>
      </article>
      <img src='/images/fotoInicial-horizontal.png' alt="app móvil PlanLocal disponible en iOS y en Android" className='fotoInicial horizontal' />
      <img src='/images/fotoInicial-vertical.jpg' alt="app móvil PlanLocal disponible en iOS y en Android" className='fotoInicial vertical' />

      <article className='containerFotoBestPromo'>
        <div className="descriptionSentence">
          <p>Busca el nombre de tu municipio y descubre todo tipo de planes para hacer hoy, el próximo fin de semana o en tus vacaciones.</p>
        </div>
      </article>
      <h2 className='tituloContainer'>Publica tu evento o tu experiencia y atrae público local de forma gratuita.</h2>
      <h2 className='tituloContainer'>¡Descarga PlanLocal y comienza a planear!</h2>
      
      {/* <article className='containerVideoPublicarPlan'>
        <div ref={ref}>
          <video
            ref={videoRef}
            width="600"
            height="600"
            className='video'
            controls
            muted
            onEnded={onVideoEnd} >
            <source src="videos/videoPublicarPlan.mp4" type="video/mp4" />
            Tu navegador no soporta la reproducción de este vídeo.
          </video>
        </div>
      </article> */}
      <article className='badgesStores'>
        <a href='https://play.google.com/store/apps/details?id=com.EventZone&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' target="_blank" rel="noopener noreferrer">
          <img alt='Disponible en Google Play' className='googlePlayBadge' src='/images/googlePlayBadge.png' />
        </a>
        <a href='https://apps.apple.com/es/app/planlocal/id6566418095?itsct=apps_box_link&itscg=30200' target="_blank" rel="noopener noreferrer">
          <img alt='Disponible en Apple Store' className='appleBadge' src='/images/appleBadge.png' />
        </a>
      </article>
    </section>
  );
}

export default PrimerHTML