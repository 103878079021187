import React, { useState, FC } from 'react'
import styles from './ModalOlvidoPassword.module.css'
import ReactModal from 'react-modal'
import useUsuariosApi from '../../../hooks/useUsuariosApi'
import Loading from '../../Loading/Loading'

interface ModalOlvidoPasswordProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

const ModalOlvidoPassword: FC<ModalOlvidoPasswordProps> = ({
  isOpen,
  onRequestClose,
}) => {

  const [mensaje, setMensaje] = useState(null)
  const [data, setData] = useState({
    correo: ''
  })
  const [error, setError] = useState(null)

  const { errorUsuarioApi, loading, emailOlvidePassword } = useUsuariosApi();

  const handleChange = (event) => {
    setData({ correo: event.target.value.trim() });
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleOlvidoPassword()
    }
  };

  const handleOlvidoPassword = async () => {

    try {
      setMensaje(null)
      const res = await emailOlvidePassword(data);
      if (res) {
        setMensaje(res?.data?.mensaje);
        setError(false)
      }

    } catch (error) {
      setError(true);
    }
  }

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Confirmación"
      className={styles.modal}
      overlayClassName={styles.overlay}
  /*     overlayClassName="overlay" */
    >
      <h2 className={styles.tituloModal}>Restablecer Contraseña</h2>
      <div className={styles.formGroupPerfil}>
        <label htmlFor="emailOlvidoPassword" className={styles.formLabelOlvidoPassword}>
          Enviaremos un enlace de recuperación de contraseña a
        </label>
        <div className={styles.containerOlvidoPassword}>
          <input
            type="email"
            name="emailOlvidoPassword"
            id="emailOlvidoPassword"
            className={styles.formInputOlvidoPassword}
            placeholder='Escribe tu correo electrónico'
            value={data.correo}
            onChange={handleChange}
            autoComplete="email"
            onKeyDown={handleKeyDown}
          />
        </div>
      </div>
      {error === false ? (
        <div className={styles.botonesModal}>
          <button onClick={onRequestClose} className={styles.botonCancelar}>Cerrar</button>
          <button onClick={handleOlvidoPassword} className={styles.botonActualizar}>Enviar un nuevo correo</button>
        </div>)
        : (
          <div className={styles.botonesModal}>
            <button onClick={onRequestClose} className={styles.botonCancelar}>Cancelar</button>
            <button onClick={handleOlvidoPassword} className={styles.botonActualizar}>Enviar</button>
          </div>
        )}

      {loading && <Loading />}
      {errorUsuarioApi && <p className={styles.textoError}>{errorUsuarioApi}</p>}
      {mensaje && <p>{mensaje}</p>}
    </ReactModal>
  )
}

export default ModalOlvidoPassword