import React, {useEffect} from 'react'
import styles from './PlanesUsuario.module.css'

import usePlanesApi from '../../../hooks/usePlanesApi';
import Loading from '../../../components/Loading/Loading';
import ListaPlanes from '../../../components/ListaPlanes/ListaPlanes';

const PlanesUsuario = () => {
  const { getPlanesDeUnUsuario, error, loading, noPlanes, planes, eliminarPlan } = usePlanesApi();

  useEffect(() => {
    getPlanesDeUnUsuario();
  }, []);

  return (
    <section className={styles.containerDeleteUpdate}>
      <h1>Planes Creados</h1>
      
      {loading ? (
        <Loading/>
      ) : noPlanes ? (
        <h2 className={styles.noPlanes}>No has creado ningún plan.</h2>
      ) : (
        <div className={styles.listaPlanItem}>
          {planes && planes.map((plan, index) => (
           /*  <article className={styles.planItem} key={plan._id} >
              <PlanItem plan={plan} eliminarPlan={eliminarPlan} />
            </article> */
            <ListaPlanes key={plan._id} plan={plan} url={`/editar-plan/${plan._id}`} />
          ))}
        </div>
      )}
      {error && (<p className={styles.error}>{error}</p>)}
    </section>
  )
}

export default PlanesUsuario