import React from 'react'
import './Politicas.css'

const PoliticasPrivacidad = () => {
  return (
      <section className='contenido'>
        <h1>Política de privacidad</h1>
        <div>
          <p>Esta política de privacidad describe cómo se manejarán los datos personales recolectados por la aplicación de eventos PlanLocal. Al utilizar la aplicación, aceptas la recolección y el uso de tus datos personales de acuerdo con esta política de privacidad.</p>
        </div>
        <h2>Datos personales</h2>
        <div>
          <p>La aplicación recolectará los siguientes datos personales:</p>
          <p>Ubicación: la aplicación solicita acceso a la ubicación del usuario para poder mostrar eventos cercanos. La aplicación solo utilizará la ubicación del usuario mientras la aplicación esté abierta y no compartirá esta información con terceros.</p>
          <p>Correo electrónico: la aplicación solicita acceso al correo electrónico del usuario para poder enviar confirmaciones de registro y recordatorios de eventos. La aplicación no compartirá esta información con terceros.</p>
          <p> Además, la aplicación permitirá a los usuarios subir carteles de eventos para publicarlos en la aplicación. Estos carteles podrán incluir información sobre eventos de terceros, como el título y la foto. La aplicación solo utilizará esta información de manera informativa y publicitaria para mostrar los eventos en la aplicación.</p>
        </div>
        <h2>Uso de datos</h2>
        <div>
          <p>La aplicación utilizará los datos recolectados para los siguientes fines:</p>
          <p>Mostrar eventos cercanos al usuario.</p>
          <p>Enviar confirmaciones de registro y recordatorios de eventos al correo electrónico del usuario.</p>
          <p>Mejorar la calidad de la aplicación y los servicios que ofrece.</p>
          <p>Utilizar la información incluida en los carteles de eventos únicamente con fines informativos y publicitarios para mostrar los eventos en la aplicación.</p>
        </div>
        <h2>Compartir datos</h2>
        <div>
          <p>La aplicación no compartirá los datos recolectados con terceros, ni los utilizará para fines publicitarios. La información recolectada se utilizará únicamente para enviar correos electrónicos internos, tales como confirmaciones de registro y recordatorios de eventos, y para mostrar los eventos en la aplicación. La aplicación no compartirá los datos con ninguna otra entidad o persona, excepto en los casos en que se requiera por ley.</p>
        </div>
        <h2>Seguridad de los datos</h2>
        <div>
          <p>La aplicación tomará medidas razonables para proteger los datos recolectados, como el cifrado y la seguridad de la red. La aplicación almacenará los datos en servidores seguros.</p>
        </div>
        <h2>Contacto</h2>
        <div>
          <p>Si tienes alguna pregunta o inquietud sobre esta política de privacidad, puedes contactarnos escribiendo a planlocal.contacto@gmail.com.</p>
        </div>
        <h2>Actualizaciones</h2>
        <div>
          <p>Nos reservamos el derecho de actualizar esta política de privacidad en cualquier momento. Te recomendamos que revises esta política regularmente para estar al tanto de cualquier cambio.</p>
        </div>
      </section>
  )
}

export default PoliticasPrivacidad