import React from 'react'
import styles from './Patrocinador.module.css'
import { FaArrowRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const Patrocinador = ({ patrocinador }) => {

    const navigate = useNavigate()

    const handleClick = () => {
        if (patrocinador.empresa === "Eurona") {
            navigate('/eurona')
        } else {
            window.open(`${patrocinador.enlaceEmpresa}`, '_blank');
        }

        // Alternativamente, podrías usar:
        // navigate(`/patrocinador/${patrocinador.titulo.replace(/\s+/g, '-')}/${patrocinador._id}`);
    };

    return (
        <article className={styles.patrocinadorCard} onClick={handleClick} style={{ cursor: 'pointer' }}>
            {patrocinador?.archivoCartel?.url !== undefined &&
                <div className={styles.patrocinadorImage}>
                    <img
                        src={patrocinador?.archivoCartel?.url}
                        onError={(e) => { const target = e.target as HTMLImageElement; target.style.display = 'none'; }}
                        alt={patrocinador.archivoCartel.nombre || patrocinador.titulo} />
                </div>}

            <div className={styles.patrocinadorInfo}>

                <div className={styles.patrocinador}>
                    <span>{patrocinador?.tipo?.toUpperCase()}</span>
                </div>
                <div className={styles.patrocinadorTitle}>
                    <span >{patrocinador.titulo}</span>
                </div>

                <div className={styles.patrocinadorDescription}>
                    <span>{patrocinador.descripcion}</span>
                </div>

                <div className={styles.buttonContainer}>
                    <button className={styles.continueButton} onClick={handleClick}>
                        Saber más <FaArrowRight className={styles.flechaAvanzarIcono} />
                    </button>
                </div>

            </div>
        </article>
    );
}

export default Patrocinador