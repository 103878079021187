import { useState } from 'react';
import axios from 'axios';
import { urlEndpoints } from '../utils/globalVariables';

const usePatrocinadoresApi = () => {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [mensajeApiPatrocinadores, setMensajeApiPatrocinadores] = useState(null)

    const emailEurona = async (nombre, telefono) => {
        try {
            setLoading(true);
            setError(null);
            const response = await axios.post(`${urlEndpoints}/api/emailEurona/`, { nombre, telefono })
            setError(null);
            setMensajeApiPatrocinadores(response.data.mensaje)
            return response;
        } catch (error) {
            setError(error.response?.data?.mensaje || 'Ha ocurrido un error al enviar los datos, por favor vuelve a intentarlo');
        }
        finally {
            setLoading(false);
        }
    }

    

    return {
        loading,
        error,
        mensajeApiPatrocinadores,
        emailEurona,
    };
};

export default usePatrocinadoresApi;