import React, { useContext, useEffect, useState } from 'react'
import styles from './HeaderDetallePlan.module.css'
import AuthContext from '../../../context/AuthContext';
import { FaHeart, FaRegHeart, FaMapMarkerAlt } from 'react-icons/fa';
import { IoMdShare } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import usePlanesApi from '../../../hooks/usePlanesApi';
import { isSameDay } from 'date-fns';
import { formatDateShort } from '../../../utils/formatDate';
import { Plan } from '../../../interfaces/Plan';

interface HeaderDetallePlanProps {
    plan: Plan;
}

const HeaderDetallePlan: React.FC<HeaderDetallePlanProps> = ({ plan }) => {

    const navigate = useNavigate();

    const [favorito, setFavorito] = useState(false);

    const { isLoggedIn } = useContext(AuthContext);

    const { error, planes, loading, anadirPlanFavorito, eliminarPlanFavorito, getPlanesFavoritos } = usePlanesApi();

    useEffect(() => {
        if (isLoggedIn) {
            getPlanesFavoritos().then(() => {
                comprobarFavorito();
            });
        }
    }, [isLoggedIn, plan?._id]);

    useEffect(() => {
        comprobarFavorito();
    }, [planes]);
    const comprobarFavorito = async () => {
        if (planes.length > 0 && isLoggedIn) {
            const idCoincide = planes.some((planItem) => planItem._id === plan?._id);
            if (idCoincide) {
                setFavorito(true)
            } else {
                setFavorito(false)
            }
        } else {
            setFavorito(false);
        }
    };

    const handleShare = () => {

        let fechas: string;

        if (isSameDay(plan.fechaInicio, plan.fechaFin)) {
            fechas = formatDateShort(plan.fechaInicio)
        } else {
            fechas = `Del ${formatDateShort(plan.fechaInicio)} al ${formatDateShort(plan.fechaFin)} `
        }

        const mensajeCompartirPlan = `${plan.titulo}\n\n${plan.direccion}\n\n${fechas}\n\nmás información en https://www.planlocal.es/${plan.titulo.replace(/\s+/g, '-')}/${plan._id}`;
        const encodedMensaje = encodeURIComponent(mensajeCompartirPlan);
        const url = `https://wa.me/?text=${encodedMensaje}`;

        window.open(url);
    };

    const handlePlanFavorito = async () => {
        if (!isLoggedIn) {
            navigate('/iniciar-sesion');
            return;
        }
        if (favorito) {
            setFavorito(false);
            await eliminarPlanFavorito(plan._id);   
        } else {
            setFavorito(true);
            await anadirPlanFavorito(plan._id);    
        }
    };


    if (!plan && error) {
        { error && <div className={styles.error}>{error}</div> }
    }

    return (
        <div className={`${styles.header} ${plan?.tipo === 'experiencia' ? styles.experiencia : plan?.tipo === 'evento' ? styles.evento : ''}`}>
            <div className={styles.upContainer}>
                <h2 className={styles.titulo}>
                    {plan?.titulo}
                </h2>
                <div className={styles.iconos}>
                    <div onClick={handlePlanFavorito}>
                        {favorito ? <FaHeart className={styles.icon} /> : <FaRegHeart className={styles.icon} />}
                    </div>
                    <div onClick={handleShare}>
                        <IoMdShare className={styles.icon} />
                    </div>
                </div>

                {error && <div className={styles.error}>{error}</div>}
            </div>
            <div className={styles.downContainer}>
                <FaMapMarkerAlt className={styles.icon} />
                <div className={styles.location}>
                    <span>{plan?.municipio}</span>
                </div>
            </div>
        </div>
    )
}

export default HeaderDetallePlan