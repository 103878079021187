import React, { FC, useEffect, useState } from 'react'
import styles from './ModalUpdateEmail.module.css'
import ReactModal from 'react-modal'
import Loading from '../../Loading/Loading';
import useUsuariosApi from '../../../hooks/useUsuariosApi';

interface ModalUpdateEmailProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

const ModalUpdateEmail: FC<ModalUpdateEmailProps> = ({
  isOpen,
  onRequestClose,
}) => {

  const [data, setData] = useState("")
  const [mensaje, setMensaje] = useState(null)

  const { errorUsuarioApi, setErrorUsuarioApi, loading, updateDatosUsuario } = useUsuariosApi();

  const handleChange = (event) => {
    setData(event.target.value.trim());
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit()
    }
  };

  const handleSubmit = async () => {

    if (!data) {
      setErrorUsuarioApi('El campo no puede estar vacío.');
      return;
    }

    setMensaje(null)
    setErrorUsuarioApi(null);

    const res = await updateDatosUsuario({ correo: data })

    if (res) {
      setMensaje(res?.data?.mensaje || 'La contraseña ha sido actualizada')
      setData("")
    }
  }
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Confirmación"
      className={styles.modal}
      overlayClassName={styles.overlay}
    >
      <h2 className={styles.tituloModal}>Actualizar correo</h2>
      <div className={styles.formGroupPerfil}>
        <label htmlFor="correo" className={styles.formLabelOlvidoPassword}>
          Escribe tu correo
        </label>
        <div className={styles.containerUpdateEmail}>
          <input
            type="text"
            name="correo"
            id="correo"
            className={styles.formInput}
            placeholder="prueba@gmail.com"
            value={data}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
          />
        </div>
      </div>

      <div className={styles.botonesModal}>
        <button onClick={onRequestClose} className={styles.botonCancelar}>Cerrar</button>
        <button onClick={handleSubmit} className={styles.botonActualizar}>Actualizar</button>
      </div>


      {loading && <Loading />}
      {errorUsuarioApi && <p className={styles.textoError}>{errorUsuarioApi}</p>}
      {mensaje && <p>{mensaje}</p>}
    </ReactModal>
  )
}

export default ModalUpdateEmail