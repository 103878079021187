import styles from './ModalInformativo.module.css'
import ReactModal from 'react-modal'
import { FC } from 'react';

interface ModalInformativoProps {
  isOpen: boolean;
  onRequestClose: () => void;
  onConfirm: () => void;
  titulo: string;
  texto: string;
}

const ModalInformativo: FC<ModalInformativoProps> = ({
  isOpen,
  onRequestClose,
  onConfirm,
  titulo,
  texto
}) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Confirmación"
      className={styles.modal}
      overlayClassName={styles.overlay}
    >
      <h2 className={styles.tituloModal}>{titulo}</h2>
      <p>{texto}</p>
      <div className={styles.containerBotonCerrar}>
        <button onClick={onConfirm} className={styles.botonCerrar}>Cerrar</button>
      </div>

    </ReactModal>
  )
}

export default ModalInformativo