import React from 'react'
import './Politicas.css'


const CookiesWeb = () => {
  return (
    <section className='contenido'>
      <h1>1. ¿Qué son las cookies?</h1>
      <div>
        <p>Las cookies son pequeños archivos de texto que se almacenan en tu dispositivo cuando visitas nuestro Sitio web. Estas cookies nos permiten recopilar cierta información sobre tu visita y mejorar tu experiencia de usuario. Las cookies pueden ser temporales (se eliminan automáticamente cuando cierras tu navegador) o persistentes (se almacenan en tu dispositivo durante un período de tiempo determinado).</p>
      </div>
      <h2>2. Tipos de cookies utilizadas:</h2>
      <div>
        <p>Utilizamos una única cookie en nuestro Sitio web:</p>
        <p>Cookie de ubicación: Esta cookie almacena un token que contiene la latitud y la longitud de una ubicación previamente buscada por ti en nuestro Sitio web. Esta información nos permite recordar tu ubicación para ofrecerte resultados más relevantes en futuras búsquedas de eventos.</p>
      </div>
      <h2>3. Finalidad de las cookies</h2>
      <div>
        <p>La cookie de ubicación que utilizamos tiene la siguiente finalidad:</p>
        <p>Personalización de la experiencia:  </p>
        <p>Almacenamos la latitud y la longitud de tu ubicación seleccionada para encontrar eventos y mejorar la relevancia de los resultados de búsqueda de eventos en futuras visitas a nuestro Sitio web.</p>
        <p>Consentimiento del usuario:</p>
        <p>Cuando visitas nuestro Sitio web por primera vez, se te solicita que aceptes el uso de cookies a través de un banner de cookies. Al hacer clic en "Aceptar" en el banner, estás dando tu consentimiento para el uso de la cookie de ubicación y aceptando esta Política de Cookies. Puedes gestionar tus preferencias de cookies en cualquier momento a través de la configuración de tu navegador.</p>
      </div>
      <h2>5. Opciones de gestión de cookies</h2>
      <div>
        <p>Puedes gestionar las cookies y tus preferencias de privacidad a través de la configuración de tu navegador. Puedes bloquear o eliminar las cookies existentes, así como configurar tu navegador para que te notifique cuando se coloquen nuevas cookies en tu dispositivo. Ten en cuenta que al desactivar o eliminar las cookies, es posible que algunas funciones de nuestro Sitio web no funcionen correctamente.</p>
      </div>
      <h2>6. Cómo deshabilitar las cookies y otros dispositivos:</h2>
      <div>
        <p>La mayoría de navegadores web permiten gestionar las preferencias de cookies u otros dispositivos de almacenamiento, para tener un control más preciso sobre la privacidad.</p>
        <p>Los siguientes links muestran las instrucciones para desactivar la configuración de las cookies en cada navegador:</p>
        <div>
          <p>1. <b>Internet Explorer </b> (<a href="https://support.microsoft.com/es-es/windows/eliminar-y-administrar-cookies-168dab11-0753-043d-7c16-ede5947fc64d">https://support.microsoft.com/es-es/windows/eliminar-y-administrar-cookies-168dab11-0753-043d-7c16-ede5947fc64d</a>)</p>
          <p>En el menú de herramientas, seleccione “Opciones de Internet”.</p>
          <p>Haga clic en la pestaña de privacidad.</p>
          <p>Podrá configurar la privacidad con un cursor con seis posiciones que le permite controlar la cantidad de cookies que se instalarán: Bloquear todas las cookies, Alta, Media Alto, Media (nivel por defecto), Baja, y Aceptar todas las cookies.</p>
        </div>
        <div>
          <p>2. <b>Mozilla Firefox</b> (<a href="https://support.mozilla.org/es/kb/cookies-informacion-que-los-sitios-web-guardan-en-?redirectlocale=en-US">https://support.mozilla.org/es/kb/cookies-informacion-que-los-sitios-web-guardan-en-?redirectlocale=en-US</a>)</p>
          <p>En la parte superior de la ventana de Firefox hacer clic en el menú Herramientas.</p>
          <p>Seleccionar Opciones.</p>
          <p>Seleccionar el panel Privacidad.</p>
          <p>En la opción Firefox podrá elegir Usar una configuración personalizada para el historial para configurar las opciones.</p>
        </div>
        <div>
          <p>3. <b> Google Chrome</b> (<a href="https://support.google.com/chrome/answer/95647?hl=es">https://support.google.com/chrome/answer/95647?hl=es</a>)</p>
          <p>Hacer clic en el menú situado en la barra de herramientas.</p>
          <p>Seleccionar Configuración.</p>
          <p>Hacer clic en Mostar opciones avanzadas.</p>
          <p>En la sección 'Privacidad' hacer clic en el botón Configuración de contenido.</p>
          <p>En la sección de 'Cookies' se pueden configurar las opciones.</p>
        </div>
        <div>
          <p>4. <b>Safari</b> (<a href="https://support.apple.com/es-es/guide/safari/sfri11471/mac">https://support.apple.com/es-es/guide/safari/sfri11471/mac</a>)</p>
          <p>En el menú de configuración, seleccione la opción de “preferencias”.</p>
          <p>Abra la pestaña de privacidad.</p>
          <p>Seleccione la opción que quiera de la sección de “bloquear cookies”.</p>
          <p>Recuerde que ciertas funciones y la plena funcionalidad del Sitio pueden no estar disponibles después de deshabilitar los cookies.</p>
        </div>
      </div>
      <h2>7. Políticas de privacidad de terceros</h2>
      <div>
        <p>Nuestro Sitio web puede contener enlaces a sitios web de terceros. Ten en cuenta que no tenemos control sobre las cookies utilizadas por estos sitios web externos. Te recomendamos que consultes las políticas de privacidad y las políticas de cookies de estos sitios web de terceros para obtener más información sobre sus prácticas de privacidad.</p>
      </div>
    </section>
  )
}

export default CookiesWeb