import React, { useEffect, useState } from 'react'
import styles from './FechasExperiencia..module.css'
import { convertirDiaANumero } from '../../utils/convertirDiaANumero';
import { FaArrowRight } from 'react-icons/fa';
import { convertirNumeroADia } from '../../utils/convertirNumeroADia';


const FechasExperiencia = ({ data, setData }) => {

    const [horariosDisponibles, setHorariosDisponibles] = useState([]);

    useEffect(() => {
      if(data.horariosDisponibles.length !== 0){
        const horariosConvertidos = data.horariosDisponibles.map(horario => ({
            ...horario,
            diaSemana: convertirNumeroADia(horario.diaSemana)
        }));
        setHorariosDisponibles(horariosConvertidos)
      }
    }, [data?._id])

    useEffect(() => {
        if (horariosDisponibles.length !== 0) {
            const horariosConvertidos = horariosDisponibles.map(horario => ({
                ...horario,
                diaSemana: convertirDiaANumero(horario.diaSemana) 
            }));
            setData({
                ...data,
                horariosDisponibles: horariosConvertidos
            })
        }
    }, [horariosDisponibles])

    // Función para manejar la selección de un día
    const handleDiaSeleccionado = (dia) => {

        const horarioExistente = horariosDisponibles.find(horario => horario.diaSemana === dia);
        if (horarioExistente) {
            // Si el día ya está en la lista, lo eliminamos
            const nuevosHorarios = horariosDisponibles.filter(horario => horario.diaSemana !== dia);
            setHorariosDisponibles(nuevosHorarios);
        } else {
            // Si el día no está en la lista, lo agregamos con horas por defecto
            setHorariosDisponibles([...horariosDisponibles, { diaSemana: dia, horaInicio: '', horaFin: '' }]);
        }
    };

    // Función para manejar el cambio en la hora de inicio o fin
    const handleHoraChange = (dia, tipoHora, value) => {
        const nuevosHorarios = horariosDisponibles.map(horario => {
            if (horario.diaSemana === dia) {
                return { ...horario, [tipoHora]: value };
            }
            return horario;
        });
        setHorariosDisponibles(nuevosHorarios);
    };

    // Función para obtener la inicial correcta del día de la semana
    const getDiaInicial = (dia) => {
        if (dia === 'Miércoles') return 'X';
        return dia.charAt(0);
    };

/*     const handleSwitchChange = (checked) => {
        setSiempreDisponible(checked);
        setData({
            ...data,
            siempreDisponible: checked
          })
    }; */

    return (
        <div className={styles.container}>
     {/*        {!siempreDisponible && */}
                    <div className={styles.textoPregunta}>
                        <span>¿Cuándo está disponible la experiencia?</span>
                    </div>
                    <div className={styles.buttonGroup}>
                        {['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'].map((dia) => (
                            <button
                                key={dia}
                                className={`${styles.buttonDiaSemana} ${horariosDisponibles.some((horario) => horario.diaSemana === dia) ? styles.selected : ''
                                    }`}
                                onClick={() => handleDiaSeleccionado(dia)}
                            >
                                {getDiaInicial(dia)}
                            </button>
                        ))}
                    </div>
                    {horariosDisponibles.length !== 0 &&
                        <div>
                            <span>¿En qué horario?</span>
                        </div>}
                    <div className={styles.schedule}>
                        {horariosDisponibles
                            .map((horario) => (
                                <div key={horario.diaSemana} className={styles.scheduleItem}>
                                    <div className={styles.textoDiaSemana}>
                                        <span>{horario.diaSemana}</span>
                                    </div>
                                    <div className={styles.containerTimeInput}>
                                        <div className={styles.timeInput}>
                                            <span>De:</span>
                                            <input
                                                type="time"
                                                min="00:00"
                                                max="23:59"
                                                className={styles.input}
                                                value={horario.horaInicio}
                                                onChange={(e) => handleHoraChange(horario.diaSemana, 'horaInicio', e.target.value)}
                                            />
                                        </div>
                                        <FaArrowRight />
                                        <div className={styles.timeInput}>
                                            <span>hasta:</span>
                                            <input
                                                type="time"
                                                min="00:00"
                                                max="23:59"
                                                className={styles.input}
                                                value={horario.horaFin}
                                                onChange={(e) => handleHoraChange(horario.diaSemana, 'horaFin', e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
               {/*  } */}

{/* 
            <div className={styles.containerSwitch}>
                <Switch
                    onChange={handleSwitchChange}
                    checked={siempreDisponible}
                    className={styles.switch}
                />
                <span>Siempre disponible</span>
            </div> */}
        </div>
    )
}

export default FechasExperiencia