import React, { useEffect } from 'react'
import styles from './DetalleOrganizacion.module.css'
import useOrganizacionApi from '../../hooks/useOrganizacionApi'
import { useParams } from 'react-router-dom';
import Loading from '../../components/Loading/Loading';
import Linkify from 'react-linkify';
import ListaPlanes from '../../components/ListaPlanes/ListaPlanes';
import { MdEmail, MdPhone } from 'react-icons/md';
import { FaMapMarkerAlt } from 'react-icons/fa';

const DetalleOrganizacion = () => {

  const { organizacionId } = useParams();

  const { organizacion, loading, errorOrganizacionApi, getOrganizacionById } = useOrganizacionApi()

  useEffect(() => {
    getOrganizacionById(organizacionId)
  }, [])

  const handleOpenGoogleMaps = () => {
    const url = `https://www.google.com/maps?q=${organizacion.direccion}`;
    window.open(url, '_blank');
  };

  return (
    <section className={styles.container}>
      {!organizacion ?
        (<Loading />)
        :
        (<div>
          <h1 className={styles.nombre}>{organizacion?.nombre}</h1>
          {organizacion?.archivoLogo?.url &&
            <div className={styles.imageContainer}>
              <img src={organizacion?.archivoLogo?.url} alt={organizacion.nombre} className={styles.image}
                onError={(e) => {
                  const target = e.target as HTMLImageElement; target.style.display = 'none';
                }}
              />
            </div>}
          <div className={styles.containerInfo}>
            {organizacion?.descripcion !== 'undefined' &&
              <p className={styles.descripcion}>
                <Linkify>{organizacion?.descripcion}</Linkify>
              </p>}
          </div>

          <div className={styles.horizontalBar}></div>

          <div className={styles.containerInfo}>
            {organizacion?.direccion &&
              <div>
                <FaMapMarkerAlt className={styles.icono} />
                <span onClick={handleOpenGoogleMaps} className={styles.textoDireccion}>{organizacion?.direccion}</span>
              </div>
            }

            {organizacion?.emailContacto &&
              <div className={styles.containerEmailContacto}>
                <MdEmail fontSize={20} className={styles.icono} />
                <a href={`mailto:${organizacion.emailContacto}`} className={styles.contactLink}>{organizacion.emailContacto}</a>
              </div>
            }

            {organizacion?.telefonoContacto &&
              <div className={styles.containerTelefonoContacto}>
                <MdPhone fontSize={20} className={styles.icono} />
                <a href={`tel:${organizacion.telefonoContacto}`} className={styles.contactLink}>{organizacion.telefonoContacto}</a>
              </div>
            }
          </div>

          <div className={styles.horizontalBar}></div>

          {organizacion.planes.length !== 0 && (
            <div>
              <h3>Planes:</h3>
            </div>
          )}
          {organizacion.planes.length !== 0 && organizacion.planes.map((plan, index) => (
            <ListaPlanes key={plan._id} plan={plan} url={`/plan/${plan.titulo.replace(/\s+/g, '-')}/${plan._id}`} />
          ))}
        </div>
        )}
      {errorOrganizacionApi && <p className={styles.error}>{errorOrganizacionApi}</p>}
    </section>
  )
}

export default DetalleOrganizacion