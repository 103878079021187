import React, { useState, useEffect } from 'react';
import styles from './CookierBar.module.css'

const CookieBar = () => {
  const [showCookieBar, setShowCookieBar] = useState(true);

  const acceptCookies = () => {
    localStorage.setItem('cookiesAccepted', 'true');
    setShowCookieBar(false);
  };

  useEffect(() => {
    const cookiesAccepted = localStorage.getItem('cookiesAccepted');
    if (cookiesAccepted) {
      setShowCookieBar(false);
    }
  }, []);

  if (!showCookieBar) {
    return null;
  }

  return (
    <div className={styles.cookieBar}>
      <p>
        Este sitio web utiliza cookies para mejorar tu experiencia. Al continuar navegando, aceptas el uso de cookies.
      </p>
      <button onClick={acceptCookies}>Aceptar</button>
    </div>
  );
};

export default CookieBar;
