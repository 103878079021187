import React, { useEffect, useRef, useState } from 'react'
import styles from './Opcional.module.css'
import CheckboxPrivacidad from '../../../../components/CheckboxPrivacidad/CheckboxPrivacidad';
import { Link, useNavigate } from 'react-router-dom';
import useUsuariosApi from '../../../../hooks/useUsuariosApi';
import useOrganizacionApi from '../../../../hooks/useOrganizacionApi';
import usePlanesApi from '../../../../hooks/usePlanesApi';
import Loading from '../../../../components/Loading/Loading';
import { FaArrowLeft, FaPlus } from 'react-icons/fa';
import { MdAdd } from 'react-icons/md';
import ModalDestacado from '../../../../components/Modals/ModalDestacado/ModalDestacado';
import { validateEmail } from '../../../../utils/validateEmail';
const Opcional = ({
  setFase,
  data,
  setData,
  categorias,
  mensajeFormulario,
  setMensajeFormulario
}) => {

  const [selectedInput, setSelectedInput] = useState(null);
  const [aceptaPoliticaPrivacidad, setAceptaPoliticaPrivacidad] = useState(false);
  const [modal, setModal] = useState(false);

  const navigate = useNavigate();

  const { crearPlan, error, setError, loading, plan } = usePlanesApi();

  const { dataUsuario, errorUsuarioApi, getUsuario, } = useUsuariosApi();

  const { organizacion, errorOrganizacionApi, organizacionesDeUnUsuario } = useOrganizacionApi();

  const tokenLogueado = localStorage.getItem('tokenLogueado')

  const errorRef = useRef(null);

  useEffect(() => {
    getUsuario(tokenLogueado);
  }, [])

  useEffect(() => {
    if (mensajeFormulario) {
      if (errorRef.current) {
        errorRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [mensajeFormulario, loading, errorUsuarioApi]);

  useEffect(() => {
    if (dataUsuario) organizacionesDeUnUsuario();
  }, [dataUsuario])

  const handleChange = (name, value) => {

    let transformedValue = value;

    if (name === 'fechaInicio' || name === 'fechaFin') {
      transformedValue = new Date(value);
    }

    setData({ ...data, [name]: transformedValue });
  };

  const handleInputFocus = (event) => {
    setSelectedInput(event.target.name);
  };

  const handleInputBlur = () => {
    setSelectedInput(null);
  };

  const handleNavigateCrearOrganizacion = () => {
    window.open('/crear-organizacion', '_blank');
  };

  const handleSubmit = async (e) => {

    e.preventDefault();
    setMensajeFormulario("")

    const { titulo, descripcion, direccion, municipio, emailContacto, telefonoContacto, ubicacion, archivoCartel, fechaInicio, fechaFin, organizacionId, horariosDisponibles, precio, tipo } = data;

    const formData = new FormData();

    if (!aceptaPoliticaPrivacidad) {
      setMensajeFormulario("Debes aceptar la política de privacidad.")
      return;
    }

    /* if(emailContacto){
      const correctEmail = validateEmail(data.correo)
      if (!correctEmail) {
        setMensajeFormulario("El correo electrónico no tiene un formato válido.");
        return;
      }
    } */
   

    if (emailContacto) formData.append('emailContacto', emailContacto.trim());
    if (telefonoContacto) formData.append('telefonoContacto', telefonoContacto.trim());
    if (organizacionId) formData.append('organizacionId', organizacionId);
    if (descripcion) formData.append('descripcion', descripcion.trim());
    if (precio) formData.append('precio', precio);

    formData.append('titulo', titulo.trim());
    formData.append('direccion', direccion);
    formData.append('municipio', municipio);
    formData.append('tipo', tipo);
    formData.append('categorias', categorias.join(','));
    formData.append('ubicacion', JSON.stringify(ubicacion));
    formData.append('archivoCartel', archivoCartel);
    // formData.append('ubicacion', JSON.stringify(data.ubicacion));
    if (fechaInicio && fechaFin) {
      formData.append('fechaInicio', fechaInicio.toISOString());
      formData.append('fechaFin', fechaFin.toISOString());
    }

  
      formData.append('horariosDisponibles', JSON.stringify(horariosDisponibles));
    

    const res = await crearPlan(formData)

    if (res) {
      setError(null)
      setAceptaPoliticaPrivacidad(false)
      if (precio && precio > 10) {
        setModal(true)
      } else {
        reiniciarFormulario()
      }
      setMensajeFormulario(res?.data?.mensaje || '¡Genial! El plan ha sido publicado.')
    }

  }

  const reiniciarFormulario = () => {
    setData({
      ...data,
      titulo: '',
      descripcion: '',
      archivoCartel: null,
      direccion: '',
      municipio: '',
      emailContacto: '',
      telefonoContacto: '',
      ubicacion: {},
      tipo: '',
      fechaInicio: null,
      fechaFin: null,
      horariosDisponibles: [],
      precio: '',
      organizacion: '',
      organizacionId: null,
      urlArchivoTemporal: null,
    });
    /*   placesRef.current?.clear();
      setBooleanFechaFin(false)
      setBooleanFechaInicio(false) */
  }

  const handleCloseModal = () => {
    setModal(false);
    navigate('/');
  };


  return (
    <section className={styles.container}>

      <div className={styles.formGroup}>
        <label htmlFor="descripcion">
          Descripción
        </label>
        <textarea
          name="descripcion"
          id="descripcion"
          value={data.descripcion}
          className={`${styles.formTextArea} ${selectedInput === 'descripcion' ? styles.focused : ''}`}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
          autoComplete="off"
        />
      </div>

      <div className={styles.formGroup}>
        <label htmlFor="precio">
          Precio
        </label>
        <input
          type="number"
          min="0"
          step="0.01"
          name="precio"
          id="precio"
          placeholder='10€'
          value={data.precio}
          className={` ${selectedInput === 'precio' ? styles.focused : ''}`}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
        />
      </div>
      {!errorOrganizacionApi && <div className={styles.organizacionGroup}>
        <div className={styles.formGroup}>
          <label htmlFor="organizacionId">Organiza:</label>
          <select name="organizacionId" id="organizacionId"
            onChange={(e) => handleChange(e.target.name, e.target.value)}>
            <option value="">Selecciona una opción</option>
            {organizacion && organizacion.map((organizacionItem) => (
              <option key={organizacionItem._id} value={organizacionItem._id}>
                {organizacionItem.nombre}
              </option>
            ))}
          </select>
        </div>
        <div className={styles.iconContainer} onClick={handleNavigateCrearOrganizacion}>
          <FaPlus fontSize={26} />
        </div>
      </div>}

      <div className={styles.formGroup}>
        <label htmlFor="emailContacto">
          Correo electrónico
        </label>
        <input
          type="text"
          name="emailContacto"
          id="emailContacto"
          placeholder='Correo electrónico'
          value={data.emailContacto}
          className={`${selectedInput === 'emailContacto' ? styles.focused : ''}`}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
        />
      </div>

      <div className={styles.formGroup}>
        <label htmlFor="telefonoContacto">
          Teléfono de contacto
        </label>
        <input
          type="text"
          name="telefonoContacto"
          id="telefonoContacto"
          placeholder='Teléfono'
          maxLength={50}
          value={data.telefonoContacto}
          className={`${selectedInput === 'telefonoContacto' ? styles.focused : ''}`}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
          autoComplete="tel"
        />
      </div>

      <CheckboxPrivacidad aceptado={aceptaPoliticaPrivacidad} setAceptado={setAceptaPoliticaPrivacidad} />

      <div className={styles.containerButtons}>
        <button className={styles.volverButton} onClick={() => setFase(prevFase => prevFase - 1)}>
          <FaArrowLeft className={styles.flechaVolverIcono} />
        </button>
        <button
          type='submit'
          className={styles.submitButton}
          onClick={handleSubmit}
          disabled={loading}
        >
          Publicar
        </button>
      </div>
      {loading && <Loading />}
      {error && <p className={styles.error}>{error}</p>}
      {mensajeFormulario && <p className={styles.error} ref={errorRef}>{mensajeFormulario}</p>}
      {errorUsuarioApi && <p className={styles.error}>{errorUsuarioApi}</p>}
      {errorOrganizacionApi && <p className={styles.error}>{errorOrganizacionApi}</p>}

      <ModalDestacado
        isOpen={modal}
        onRequestClose={handleCloseModal}
        dataUsuario={dataUsuario}
        plan={data}
        reiniciarFormulario={reiniciarFormulario}
      />
    </section>
  )
}

export default Opcional