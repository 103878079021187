import React, { useEffect, useState } from 'react'
import styles from './FiltroFechas.module.css';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';

const FiltroFechas = ({ setSelectedDate }) => {

    const [filterDateSelected, setFilterDateSelected] = useState("")
    const [textoFecha, setTextoFecha] = useState("")
    const [contadorFecha, setContadorFecha] = useState(0)

    useEffect(() => {
        handleDate();
    }, [filterDateSelected, contadorFecha]);

    const handleFechaAnterior = () => {
        if (contadorFecha > 0) {
            if (filterDateSelected === "finDeSemana" || filterDateSelected === "semana") {
                setContadorFecha(contadorFecha - 7)
            }
            if (filterDateSelected === "mes") {
                setContadorFecha(contadorFecha - 1)
            }
        }
    }

    const handleFechaPosterior = () => {
        if (contadorFecha >= 0) {
            if (filterDateSelected === "finDeSemana" || filterDateSelected === "semana") {
                setContadorFecha(contadorFecha + 7)
            }
            if (filterDateSelected === "mes") {
                setContadorFecha(contadorFecha + 1)
            }
        }
    }

    const handleDate = () => {
        const hoy = new Date();

        if (filterDateSelected === "finDeSemana") {
            const primerDiaFinDeSemana = new Date(hoy);
            primerDiaFinDeSemana.setDate(hoy.getDate() + (contadorFecha + 5 - hoy.getDay())); // Viernes
            const ultimoDiaFinDeSemana = new Date(hoy);
            ultimoDiaFinDeSemana.setDate(hoy.getDate() + (contadorFecha + 7 - hoy.getDay())); // Domingo


            // Obtener el día en número
            const viernesNumero = primerDiaFinDeSemana.getDate();
            const domingoNumero = ultimoDiaFinDeSemana.getDate();

            // Obtener el mes en formato de letra
            const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
            const mesLetra = meses[ultimoDiaFinDeSemana.getMonth()];

            //Fijar fecha para filtro backend
            setSelectedDate({
                fechaInicioFiltro: primerDiaFinDeSemana,
                fechaFinFiltro: ultimoDiaFinDeSemana,
            })

            setTextoFecha(`${viernesNumero} - ${domingoNumero} ${mesLetra}`)
        } else if (filterDateSelected === "semana") {
            const hoy = new Date();
            const primerDiaSemana = new Date(hoy);
            primerDiaSemana.setDate(contadorFecha + hoy.getDate() - hoy.getDay() + 1); // Lunes
            const ultimoDiaSemana = new Date(hoy);
            ultimoDiaSemana.setDate(contadorFecha + hoy.getDate() - hoy.getDay() + 7); // Domingo


            // Obtener el día en número
            const lunesNumero = primerDiaSemana.getDate();
            const domingoNumero = ultimoDiaSemana.getDate();

            // Obtener el mes en formato de letra
            const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
            const mesLetra = meses[ultimoDiaSemana.getMonth()];

            //Fijar fecha para filtro backend
            setSelectedDate({
                fechaInicioFiltro: primerDiaSemana,
                fechaFinFiltro: ultimoDiaSemana,
            })

            setTextoFecha(`${lunesNumero} - ${domingoNumero} ${mesLetra}`);
        } else if (filterDateSelected === "mes") {
            const hoy = new Date();
            const mesActual = hoy.getMonth(); // Obtener el mes actual en número (0-11)
            const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

            const nombreMesActual = meses[contadorFecha + mesActual]; // Obtener el nombre del mes actual

            //Fijar fecha para filtro backend
            const primerDiaMes = new Date(hoy.getFullYear(), contadorFecha + mesActual, 1);
            const ultimoDiaMes = new Date(hoy.getFullYear(), contadorFecha + mesActual + 1, 0);
            setSelectedDate({
                fechaInicioFiltro: primerDiaMes,
                fechaFinFiltro: ultimoDiaMes,
            })

            setTextoFecha(nombreMesActual);
        } else if (filterDateSelected === "hoy") {
            setTextoFecha("Hoy")
            // Obtener la fecha actual
            const fechaActual = new Date();

            // Establecer la fecha de inicio del día de hoy con la primera hora (00:00:00)
            const fechaInicio = new Date(fechaActual);
            fechaInicio.setHours(0, 0, 0, 0);

            // Establecer la fecha de fin del día de hoy con la última hora (23:59:59)
            const fechaFin = new Date(fechaActual);
            fechaFin.setHours(23, 59, 59, 999);

            // Asignar las fechas a setSelectedDate
            setSelectedDate({
                fechaInicioFiltro: fechaInicio,
                fechaFinFiltro: fechaFin,
            });
        }

    }

    const handleChangeOptionDate = (e) => {
        setFilterDateSelected(e.target.value)
        setContadorFecha(0)
    }

    return (
        <section className={styles.section}>
            {textoFecha && textoFecha !== "Hoy" && (
                <div className={styles.containerFechas}>
                    {!(filterDateSelected === "mes" && contadorFecha === 0) &&
                        !(filterDateSelected === "finDeSemana" && contadorFecha === 0) &&
                        !(filterDateSelected === "semana" && contadorFecha === 0) && (
                            <div className={styles.containerIcono} onClick={handleFechaAnterior}>
                                <FaArrowLeft />
                            </div>
                        )}
                    <div className={styles.containerTexto}>
                        <span>{textoFecha}</span>
                    </div>
                    <div className={styles.containerIcono} onClick={handleFechaPosterior}>
                        <FaArrowRight />
                    </div>
                </div>
            )}
            <select id="fechas" value={filterDateSelected} onChange={handleChangeOptionDate} className={styles.select}>
                <option value="">Filtrar por fecha</option>
                <option value="hoy">Hoy</option>
                <option value="finDeSemana">Fin de semana</option>
                <option value="semana">Semana</option>
                <option value="mes">Mes</option>
            </select>
        </section>
    );
}

export default FiltroFechas