import React, { useEffect } from 'react';
import styles from './Categorias.module.css';

interface CategoriasProps {
    primeraOpcion: string;
    categorias: string[];
    containerWrap?: boolean;
    setCategorias: (categorias: string[]) => void;
    scrollViewRef?: React.RefObject<HTMLDivElement>;
}

const Categorias: React.FC<CategoriasProps> = ({
    primeraOpcion,
    categorias,
    setCategorias,
    scrollViewRef,
    containerWrap,
}) => {
    const toggleCategoria = (categoria: string) => {
        let nuevasCategorias: string[];
        if (categoria === 'Otros') {
            nuevasCategorias = [categoria];
        } else {
            nuevasCategorias = categorias.includes(categoria)
                ? categorias.filter(cat => cat !== categoria)
                : [...categorias.filter(cat => cat !== 'Otros'), categoria];
        }
        setCategorias(nuevasCategorias);
    };

    // Use effect to ensure at least one category is selected
    useEffect(() => {
        if (categorias.length === 0) {
            setCategorias(['Otros']);
        }
    }, [categorias, setCategorias]);
    
    return (
        <section
            className={`${styles.containerCategoria} ${containerWrap ? styles.containerWrap : ''}`}
            ref={scrollViewRef}
        >
            <div
                className={`${styles.categoria} ${containerWrap ? styles.categoriaWrap : ''} ${categorias.includes('Otros') ? styles.categoriaSeleccionada : ''}`}
                onClick={() => toggleCategoria('Otros')}
            >
                <span className={styles.textoCategoria}>{primeraOpcion}</span>
            </div>
            <div
                className={`${styles.categoria} ${containerWrap ? styles.categoriaWrap : ''} ${categorias.includes('Deporte') ? styles.categoriaSeleccionada : ''}`}
                onClick={() => toggleCategoria('Deporte')}
            >
                <span className={styles.textoCategoria}>Deporte</span>
            </div> 
            <div
                className={`${styles.categoria} ${containerWrap ? styles.categoriaWrap : ''} ${categorias.includes('Fiestas de pueblo') ? styles.categoriaSeleccionada : ''}`}
                onClick={() => toggleCategoria('Fiestas de pueblo')}
            >
                <span className={styles.textoCategoria}>Fiestas de pueblo</span>
            </div>
            <div
                className={`${styles.categoria} ${containerWrap ? styles.categoriaWrap : ''} ${categorias.includes('Aventura') ? styles.categoriaSeleccionada : ''}`}
                onClick={() => toggleCategoria('Aventura')}
            >
                <span className={styles.textoCategoria}>Aventura</span>
            </div>
            <div
                className={`${styles.categoria} ${containerWrap ? styles.categoriaWrap : ''} ${categorias.includes('Turismo') ? styles.categoriaSeleccionada : ''}`}
                onClick={() => toggleCategoria('Turismo')}
            >
                <span className={styles.textoCategoria}>Turismo</span>
            </div>
            <div
                className={`${styles.categoria} ${containerWrap ? styles.categoriaWrap : ''} ${categorias.includes('Fiesta') ? styles.categoriaSeleccionada : ''}`}
                onClick={() => toggleCategoria('Fiesta')}
            >
                <span className={styles.textoCategoria}>Fiesta</span>
            </div>
            <div
                className={`${styles.categoria} ${containerWrap ? styles.categoriaWrap : ''} ${categorias.includes('Infantil') ? styles.categoriaSeleccionada : ''}`}
                onClick={() => toggleCategoria('Infantil')}
            >
                <span className={styles.textoCategoria}>Infantil</span>
            </div>
            <div
                className={`${styles.categoria} ${containerWrap ? styles.categoriaWrap : ''} ${categorias.includes('Cultura') ? styles.categoriaSeleccionada : ''}`}
                onClick={() => toggleCategoria('Cultura')}
            >
                <span className={styles.textoCategoria}>Cultura</span>
            </div>
            
            <div
                className={`${styles.categoria} ${containerWrap ? styles.categoriaWrap : ''} ${categorias.includes('Taller') ? styles.categoriaSeleccionada : ''}`}
                onClick={() => toggleCategoria('Taller')}
            >
                <span className={styles.textoCategoria}>Taller</span>
            </div>
            <div
                className={`${styles.categoria} ${containerWrap ? styles.categoriaWrap : ''} ${categorias.includes('Gastronomía') ? styles.categoriaSeleccionada : ''}`}
                onClick={() => toggleCategoria('Gastronomía')}
            >
                <span className={styles.textoCategoria}>Gastronomía</span>
            </div>
        </section>
    );
};

export default Categorias;
