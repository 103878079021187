import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styles from './CheckboxPrivacidad.module.css'

interface CheckboxPrivacidadProps {
    aceptado: boolean;
    setAceptado: (valor: boolean) => void;
}

const CheckboxPrivacidad: React.FC<CheckboxPrivacidadProps> = ({ aceptado, setAceptado }) => {

    const navigate = useNavigate();

    const handleCheckboxChange = () => {
        setAceptado(!aceptado);
    };

    const handleNavigationPrivacidad = async () => {
        navigate('/politica-privacidad');
      }
    return (
        <div className={styles.containerPrivacidad}>
            <input
                type="checkbox"
                checked={aceptado}
                onChange={handleCheckboxChange}
            />
            <span>
                Acepto la
            </span>
            <span className={styles.textoIrPoliticaPrivacidad} onClick={handleNavigationPrivacidad}>
            política de privacidad.
            </span>
        </div>
    )
}

export default CheckboxPrivacidad