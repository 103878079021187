import React, { useEffect } from 'react'
import styles from './OrganizacionesUsuario.module.css'
import useOrganizacionApi from '../../../hooks/useOrganizacionApi'
import OrganizacionItem from './OrganizacionItem/OrganizacionItem';
import Loading from '../../../components/Loading/Loading';
import { Link } from 'react-router-dom';

const OrganizacionesUsuario = () => {

  const { loading, errorOrganizacionApi, organizacion, organizacionesDeUnUsuario, eliminarOrganizacion } = useOrganizacionApi();

  useEffect(() => {
    organizacionesDeUnUsuario()
  }, [])

  return (
    <section className={styles.containerDeleteUpdate}>
      <h1>Tus organizaciones</h1>
      {loading ? (
        <Loading />
      ) : !organizacion || organizacion?.length === 0 ? (

        <section>
          <h2 className={styles.noEventos}>No administras ninguna organizacion.</h2>
          <p>Haz click&nbsp;
            <Link
              to={`/crear-organizacion`}
            >
              aquí
            </Link>
            &nbsp;para crear una organización</p>
        </section>
      ) : (
        <div className={styles.listaEventoItem}>
          {organizacion && organizacion.map((organizacionItem, index) => (
            <article className={styles.organizacionItem} key={organizacionItem._id} >
              <OrganizacionItem organizacion={organizacionItem} eliminarOrganizacion={eliminarOrganizacion} />
            </article>
          ))}
        </div>
      )}
      {errorOrganizacionApi && (<p className={styles.error}>{errorOrganizacionApi}</p>)}
    </section>
  )
}

export default OrganizacionesUsuario