import React, { useEffect, useState, useContext } from 'react'
import { RiPencilLine } from 'react-icons/ri';

import styles from './InfoPerfil.module.css'
import useUsuariosApi from '../../../hooks/useUsuariosApi';
import AuthContext from '../../../context/AuthContext';
import Loading from '../../../components/Loading/Loading';
import ModalConfirmacion from '../../../components/Modals/ModalConfirmacion/ModalConfirmacion';
import ModalUpdatePassword from '../../../components/Modals/ModalUpdatePassword/ModalUpdatePassword';
import ModalOlvidoPassword from '../../../components/Modals/ModalOlvidoPassword/ModalOlvidoPassword';
import ModalUpdateNombre from '../../../components/Modals/ModalUpdateNombre/ModalUpdateNombre';
import ModalUpdateEmail from '../../../components/Modals/ModalUpdateEmail/ModalUpdateEmail';


const InfoPerfil = () => {

  /*   const [modalDelete, setModalDelete] = useState(false);
    const [modalCambiarPassword, setModalCambiarPassword] = useState(false);
    const [modalOlvido, setModalOlvido] = useState(false); */

  const [modals, setModals] = useState({
    modalDelete: false,
    modalCambiarPassword: false,
    modalOlvido: false,
    modalInputPerfil: false,
    modalInputNombre: false,
    modalInputCorreo: false
  });

  const { loading, dataUsuario, errorUsuarioApi, getUsuario, deleteUsuario, updateDatosUsuario } = useUsuariosApi();

  const { setIsLoggedIn } = useContext(AuthContext);

  const tokenLogueado = localStorage.getItem('tokenLogueado')
  const google = localStorage.getItem('google')

  useEffect(() => {
    getUsuario(tokenLogueado);
  }, [])


  const handleOpenModal = (modalName) => {
    setModals({ ...modals, [modalName]: true });
  };

  const handleCloseModal = async (modalName) => {
    setModals({ ...modals, [modalName]: false });
    await getUsuario(tokenLogueado)
  };


  /*  const handleCloseModalDelete = () => {
     setModalDelete(false);
   };
 
   const handleOpenModalDelete = () => {
     setModalDelete(true);
     setMensaje(null)
   };
 
   const handleCloseCambiarPassword = async () => {
     setModalCambiarPassword(false);
        // await getUsuario(tokenLogueado);
   };
 
   const handleOpenModalCambiarPassword = () => {
     setModalCambiarPassword(true);
     setMensaje(null)
   };
 
   const handleCloseModalOlvido = () => {
     setModalOlvido(false);
   };
 
   const handleOpenModalOlvido = () => {
     setModalOlvido(true);
     setMensaje(null)
   }; */

  const handleEliminarCuenta = async () => {
    await deleteUsuario(tokenLogueado)

    if (!errorUsuarioApi) {
      localStorage.removeItem('tokenLogueado')
      setIsLoggedIn(false)
    }
  }

  const handleLogout = (e) => {
    localStorage.removeItem("google")
    localStorage.removeItem('tokenLogueado')
    setIsLoggedIn(false)
    e.stopPropagation();
  }

  return (
    <section className={styles.containerPerfil}>
      {loading ?
        (<Loading />)
        : (
          <div>
            <h1>Perfil</h1>
            <div className={styles.formGroupPerfil}>
              <label htmlFor="nombre" >
                Nombre
              </label>
              <div className={styles.containerInput}>
                <input
                  type="text"
                  name="nombre"
                  id="nombre"
                  className={styles.formInputPerfil}
                  value={dataUsuario?.nombre}
                  autoComplete="off"
                  disabled
                />
                {!google &&
                  <RiPencilLine color="#555555" onClick={() => handleOpenModal("modalInputNombre")} size={24} className={styles.iconoEditarPassword} />}
              </div>
            </div>
            <div className={styles.formGroupPerfil}>
              <label htmlFor="correo">
                Correo electrónico
              </label>
              <div className={styles.containerInput}>
                <input
                  type="email"
                  name="correo"
                  id="correo"
                  className={styles.formInputPerfil}
                  value={dataUsuario?.correo}
                  autoComplete="email"
                  autoCapitalize="none"
                  disabled
                />
                {!google &&
                  <RiPencilLine color="#555555" onClick={() => handleOpenModal("modalInputCorreo")} size={24} className={styles.iconoEditarPassword} />}

              </div>
            </div>
            {!google &&
              <div className={styles.formGroupPerfil}>
                <label htmlFor="passwordPerfil">
                  Contraseña
                </label>
                <div className={styles.containerInput}>
                  <input
                    type="password"
                    name="passwordPerfil"
                    id="passwordPerfil"
                    className={styles.formInputPerfil}
                    value={dataUsuario?.password}
                    autoComplete="off"
                    disabled
                  />
                  <RiPencilLine color="#555555" onClick={() => handleOpenModal("modalCambiarPassword")} size={24} className={styles.iconoEditarPassword} />
                </div>
                <p className={styles.textoOlvidoPassword} onClick={() => handleOpenModal("modalOlvido")}>He olvidado mi contraseña</p>
              </div>}

            <div className={styles.botonesPerfil}>
              <button onClick={handleLogout} className={styles.botonLogoutPerfil}>Cerrar sesión</button>
              <button onClick={() => handleOpenModal("modalDelete")} className={styles.botonEliminarPerfil}>Eliminar cuenta</button>
              {errorUsuarioApi && <p className={styles.error}>{errorUsuarioApi}</p>}
            </div>
          </div>
        )}
      
      <ModalConfirmacion
        titulo={'Eliminar Cuenta'}
        texto={'¿Estás segur@ de que deseas eliminar tu cuenta? Esta acción es irreversible.'}
        isOpen={modals.modalDelete}
        onRequestClose={() => handleCloseModal("modalDelete")}
        onConfirm={handleEliminarCuenta}
      />
      <ModalUpdateNombre
        isOpen={modals.modalInputNombre}
        onRequestClose={() => handleCloseModal("modalInputNombre")}
      />
      <ModalUpdateEmail
        isOpen={modals.modalInputCorreo}
        onRequestClose={() => handleCloseModal("modalInputCorreo")}
      />
      <ModalUpdatePassword
        isOpen={modals.modalCambiarPassword}
        onRequestClose={() => handleCloseModal("modalCambiarPassword")}
      />
      <ModalOlvidoPassword
        isOpen={modals.modalOlvido}
        onRequestClose={() => handleCloseModal("modalOlvido")}
      />
    </section>
  )
}

export default InfoPerfil