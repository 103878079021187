import React, { useState } from 'react'
import styles from './DetalleEurona.module.css'
import usePatrocinadoresApi from '../../../hooks/usePatrocinadoresApi'

const DetalleEurona = () => {

  const { error, emailEurona, mensajeApiPatrocinadores, loading } = usePatrocinadoresApi();

  const [data, setData] = useState({
    telefono: '',
    email: '',
  })
  const [selectedInput, setSelectedInput] = useState(null);
  const [errorForm, setErrorForm] = useState("")

  const handleChange = (name, value) => {
    setData({ ...data, [name]: value });
  };

  const handleInputFocus = (event) => {
    setSelectedInput(event.target.name);
  };

  const handleInputBlur = () => {
    setSelectedInput(null);
  };

  const handleSubmit = async (e) => {

    if(!data.telefono){
      setErrorForm("Por favor, adjunta tu teléfono para poder contactarte");
      return;
    }
  }

  //url img info detalle: https://storage.googleapis.com/repoblando.appspot.com/Infografi%CC%81a%20PUR%20-%20versio%CC%81n%20marketing%20y%20aten%20vf%20corregida%20(1).pdf

  //url img icono: https://storage.googleapis.com/repoblando.appspot.com/icono-Eurona.png
  return (
    <article className={styles.container}>
      <h2>¿Vives en una zona sin fibra o el Internet que te llega es de mala calidad?</h2>
      <h3>¡Descubre Internet Satélite!</h3>
      <div className={styles.imageContainer}>
        <img src="/images/infografia-eurona.png" alt="Infografía para internet por satélite" className={styles.image}
          onError={(e) => {
            const target = e.target as HTMLImageElement; target.style.display = 'none';
          }}
        />
      </div>
      <div className={styles.text}>
        <span>Infórmate sin compromiso 🤗</span>
      </div>

      <div className={styles.formGroup}>
        <input
          type="email"
          name="email"
          id="email"
          placeholder='Escribe tu email'
          maxLength={50}
          value={data.email}
          className={`${selectedInput === 'email' ? styles.focused : ''}`}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
          autoComplete="email"
        />
      </div>

      <div className={styles.formGroup}>
        <input
          type="text"
          name="telefono"
          id="telefono"
          placeholder='Escribe tu teléfono'
          maxLength={50}
          value={data.telefono}
          className={`${selectedInput === 'telefono' ? styles.focused : ''}`}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
          autoComplete="tel"
        />
      </div>
      <div className={styles.containerButton}>
        <button
          type='submit'
          className={styles.submitButton}
          onClick={handleSubmit}
          disabled={loading}
        >
          Saber más
        </button>
      </div>

      <p>Eurona, líder en Internet Rural</p>
      {mensajeApiPatrocinadores && <p>{mensajeApiPatrocinadores}</p>}
      {error && <p className={styles.error}>{error}</p>}
      {errorForm && <p className={styles.errorForm}>{errorForm}</p>}
    </article>
  )
}

export default DetalleEurona