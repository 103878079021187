const formatDateShort = (date) => {
    const fechaFormateada = new Date(date);

    return fechaFormateada.toLocaleDateString('es-ES', {
        day: 'numeric',
        month: 'short'
    });
};

const formatDateLong = (date) => {
    const fechaFormateada = new Date(date);

    return fechaFormateada.toLocaleDateString('es-ES', {
        day: 'numeric',
        month: 'long',
        hour: 'numeric',
        minute: 'numeric',
    });
};

export {
    formatDateShort,
    formatDateLong
};