import styles from './ListaPlanes.module.css'
import { useNavigate } from 'react-router-dom';
import { isSameDay } from 'date-fns';
import { Plan } from '../../interfaces/Plan';
import { formatDateShort } from '../../utils/formatDate';

const ListaPlanes = ({ plan, url, enlaceWebExterior }: { plan: Plan, url: String, enlaceWebExterior?: string }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        if (enlaceWebExterior) {
            window.open(enlaceWebExterior, '_blank');
        } else {
            navigate(`${url}`);
        }
        // Alternativamente, podrías usar:
        // navigate(`/plan/${plan.titulo.replace(/\s+/g, '-')}/${plan._id}`);
    };

    return (
        <article className={styles.planCard} onClick={handleClick} style={{ cursor: 'pointer' }}>
            {plan?.archivoCartel?.url &&
                <div className={styles.planImage}>
                    <img
                        src={plan?.archivoCartel?.url}
                        onError={(e) => { const target = e.target as HTMLImageElement; target.style.display = 'none'; }}
                        alt={plan.archivoCartel.nombre || plan.titulo} />
                </div>}

            <div className={styles.planInfo}>

                {plan.destacado ? <div>
                    <div className={`${styles.tipoPlan} ${styles.destacado}`}>
                        <span>DESTACADO</span>
                    </div>
                    <div className={styles.planTitle}>
                        <span>{plan.titulo}</span>
                    </div>
                </div>
                    :
                    <div>
                        <div className={`${styles.tipoPlan} ${plan.tipo === 'destacado' ? styles.destacado : plan.tipo === 'experiencia' ? styles.experiencia : styles.evento}`}>
                            <span>{plan?.tipo?.toUpperCase()}</span>
                        </div>
                        <div className={styles.planTitle}>
                            <span >{plan.titulo}</span>
                        </div>
                    </div>}

                <div className={styles.planDescription}>
                    <span>{plan.descripcion}</span>
                </div>

                <div className={styles.planFooter}>
                    <div className={styles.planPrecio}>
                        {plan.precio && <span>{plan.precio}€</span>}
                    </div>
                    <div className={styles.horizontalBar}></div>
                    <div className={styles.containerMunicipioYDistancia}>
                        <span className={styles.planMunicipio}>{plan.municipio}&nbsp;</span>
                        {plan?.distancia && plan?.distancia !== "0km" && plan?.distancia !== "1km" && plan?.distancia !== "2km" && plan?.distancia !== "3km" && <span className={styles.planDistancia}>a {plan.distancia}</span>}
                    </div>
                    {plan.fechaInicio && (
                        <>
                            {isSameDay(new Date(plan.fechaInicio), new Date(plan.fechaFin))
                                ? (<p className={styles.planDate}>{formatDateShort(new Date(plan.fechaFin))}</p>)
                                : (<p className={styles.planDate}>{formatDateShort(new Date(plan.fechaInicio))} - {formatDateShort(new Date(plan.fechaFin))}</p>)}
                        </>
                    )}
                </div>

            </div>
        </article>
    );
}

export default ListaPlanes;
