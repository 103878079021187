import { useState } from 'react';
import axios from 'axios';
import { urlEndpoints } from '../utils/globalVariables';

const useUsuariosApi = () => {
  const [loading, setLoading] = useState(false);
  const [errorUsuarioApi, setErrorUsuarioApi] = useState(null);
  const [dataUsuario, setDataUsuario] = useState(null)


  const registrarUsuario = async (data) => {
    try {
      setLoading(true);
      setErrorUsuarioApi(null);

      const response = await axios.post(`${urlEndpoints}/api/usuario/register`, JSON.stringify(data), {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      return response;

    }
    catch (error) {
      setErrorUsuarioApi(error.response.data.mensaje || "Ha ocurrido un error inesperado, por favor vuelve a intentarlo ");
    }
    finally {
      setLoading(false);
    }
  }

  const loginNormal = async (data) => {

    try {
      setLoading(true);
      setErrorUsuarioApi(null);

      const response = await axios.post(`${urlEndpoints}/api/usuario/login`, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      return response

    }
    catch (error) {
      setErrorUsuarioApi(error.response.data.mensaje || "Ha ocurrido un error inesperado, por favor vuelve a intentarlo");
    }
    finally {
      setLoading(false);
    }
  }

  const loginGoogle = async (idToken) => {
    try {
      setLoading(true);
      setErrorUsuarioApi(null);

      const response = await axios.post(`${urlEndpoints}/api/usuario/login-google`, { id_token: idToken, platform: "web" }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      return response;
    }
    catch (error) {
      setErrorUsuarioApi(error.response.data.mensaje || 'Ha ocurrido un error inesperado, por favor vuelve a intentarlo');
    }
    finally {
      setLoading(false);
    }
  }

  const getUsuario = async (token) => {

    try {
      setLoading(true);
      setDataUsuario(null)

      setErrorUsuarioApi(null);

      if (!token) {
        setErrorUsuarioApi('Es necesario iniciar sesión.');

        setLoading(false);
        return
      }

      const response = await axios.get(`${urlEndpoints}/api/usuario`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      setDataUsuario(response.data.usuario)

      return response.data.usuario;

    }
    catch (error) {
      setErrorUsuarioApi(error.response.data.mensaje || 'Ha ocurrido un error inesperado.');
    }
    finally {
      setLoading(false);
    }
  }

  const deleteUsuario = async (token) => {
    try {
      setLoading(true);
      setErrorUsuarioApi(null);

      if (!token) {
        setErrorUsuarioApi('Es necesario iniciar sesión.');

        setLoading(false);
        return
      }

      const response = await axios.delete(`${urlEndpoints}/api/usuario`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      setDataUsuario(null)

      return response;

    } catch (error) {
      setErrorUsuarioApi(error.response.data.mensaje || 'Ha ocurrido un error inesperado, por favor vuelve a intentarlo.');
    }
    finally {
      setLoading(false);
    }
  }


  const updateDatosUsuario = async (data) => {
    try {
      setLoading(true);
      setErrorUsuarioApi(null);

      const token = await localStorage.getItem('tokenLogueado');

      // Verificar si el token está presente y si es válido
      if (!token) {
        setErrorUsuarioApi('Es necesario iniciar sesión para añadir eventos a la aplicación');

        setLoading(false);
        return
      }

      const response = await axios.patch(`${urlEndpoints}/api/usuario/cambiar-datos`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      return response;

    } catch (error) {
      console.log(error.response.data.mensaje)
      setErrorUsuarioApi(error.response.data.mensaje || "Ha ocurrido un error inesperado, por favor vuelve a intentarlo.");
    }
    finally {
      setLoading(false);
    }
  }

  const emailOlvidePassword = async (data) => {
    try {
      setLoading(true);
      setErrorUsuarioApi(null);

      const response = await axios.post(`${urlEndpoints}/api/usuario/restablecer-password`, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      return response;
    }
    catch (error) {
      console.log(error.response.data.mensaje)
      setErrorUsuarioApi(error.response.data.mensaje || "Ha ocurrido un error inesperado, por favor vuelve a intentarlo.");
    }
    finally {
      setLoading(false);
    }
  }

  const restablecerPassword = async (data, token) => {
    try {
      setLoading(true);
      setErrorUsuarioApi(null);

      const response = await axios.patch(`${urlEndpoints}/api/usuario/restablecer-password/${token}`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      return response;

    }
    catch (error) {
      console.log(error.response.data.mensaje)
      setErrorUsuarioApi(error.response.data.mensaje || "Ha ocurrido un error inesperado");
    }
    finally {
      setLoading(false);
    }
  }

  const confirmarCuenta = async (token) => {
    try {
      setLoading(true);
      setDataUsuario(null)
      setErrorUsuarioApi(null);

      const response = await axios.get(`${urlEndpoints}/api/usuario/confirmar-cuenta/${token}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      setDataUsuario(response.data.mensaje)

      return response;

    }
    catch (error) {
      console.log(error.response.data.mensaje)
      setErrorUsuarioApi(error.response.data.mensaje || "Ha ocurrido un error inesperado");
    }
    finally {
      setLoading(false);
    }
  }

  return {
    registrarUsuario,
    loginNormal,
    loginGoogle,
    getUsuario,
    deleteUsuario,
    updateDatosUsuario,
    emailOlvidePassword,
    restablecerPassword,
    setErrorUsuarioApi,
    confirmarCuenta,
    errorUsuarioApi,
    dataUsuario,
    loading,
  }
}
export default useUsuariosApi