import { useEffect, useContext, useState } from 'react'
//import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import { AiOutlineHeart, AiFillHeart } from 'react-icons/ai';
import { BsArrowRight } from 'react-icons/bs';
import { IoMdShare } from 'react-icons/io';
import { MdEmail, MdPhone } from 'react-icons/md';
import { isSameDay } from 'date-fns';
import Linkify from 'react-linkify';

import styles from './DetallePlan.module.css'
import ModalDetallePlan from '../../components/Modals/ModalDetallePlan/ModalDetallePlan';
import Loading from '../../components/Loading/Loading';
import AuthContext from '../../context/AuthContext';
import ModalConfirmacion from '../../components/Modals/ModalConfirmacion/ModalConfirmacion';
import usePlanesApi from '../../hooks/usePlanesApi';
import { formatDateLong, formatDateShort } from '../../utils/formatDate';
import { Link, useParams } from 'react-router-dom';
import HeaderDetallePlan from './HeaderDetallePlan/HeaderDetallePlan';
import { FaBuilding, FaMapMarkerAlt } from 'react-icons/fa';
import MapLeaflet from '../../components/MapLeaflet/MapLeaflet';


const DetallePlan = () => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalReport, setModalReport] = useState(false);
  const [mensaje, setMensaje] = useState(null);

  const { planId } = useParams();

  const { isLoggedIn } = useContext(AuthContext);

  const { loading, plan, planes, error, reportePlan, getPlanesFavoritos, getPlanPorId } = usePlanesApi();

  /* const location = useLocation();
  const params = new URLSearchParams(location.search);

  const titulo = params.get('titulo');
  const planId = params.get('planId');
  const descripcion = params.get('descripcion');
  const fechaInicio = params.get('fechaInicio');
  const fechaFin = params.get('fechaFin');
  let urlArchivo = params.get('urlArchivo');
  if (urlArchivo === 'undefined') {
    urlArchivo = undefined;
  }
  const direccion = params.get('direccion');
  const lng = params.get('lng');
  const lat = params.get('lat'); */
  /*  const center = {
     lat: parseFloat(lat),
     lng: parseFloat(lng),
   } */
  useEffect(() => {
    getPlanPorId(planId)
    if (isLoggedIn) getPlanesFavoritos()

    // Limpia el título de la página cuando el componente se desmonta
    return () => {
      document.title = 'Plan Local';
    };

  }, [])

  useEffect(() => {
    
    // Cambiar el título de la página cuando se carga el componente
    if (plan) document.title = plan.titulo;
  }, [planes])

  const center = {
    lat: plan?.ubicacion?.coordinates[1],
    lng: plan?.ubicacion?.coordinates[0],
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenModalReport = () => {
    setModalReport(true)
  }

  const handleCloseModalReport = () => {
    setModalReport(false)
  }

  const handleOpenGoogleMaps = () => {
    const url = `https://www.google.com/maps?q=${center.lat},${center.lng}`;
    window.open(url, '_blank');
  };

  const handleReportePlan = async () => {
    setModalReport(false)
    setMensaje(null)
    const res = await reportePlan(plan.titulo, plan.archivoCartel.url, plan.urlPlan)

    if (!error && res) {
      setMensaje(res.data.mensaje)
    }
  }

  //Añadir url del plan
  const handleShare = () => {

    let fechas: string;

    if (isSameDay(plan.fechaInicio, plan.fechaFin)) {
      fechas = formatDateShort(plan.fechaInicio)
    } else {
      fechas = `Del ${formatDateShort(plan.fechaInicio)} al ${formatDateShort(plan.fechaFin)} `
    }

    const mensajeCompartirPlan = `${plan.titulo}\n\n${plan.direccion}\n\n${fechas}\n\nmás información en https://www.planlocal.es/${plan.titulo.replace(/\s+/g, '-')}/${plan._id}`;
    const encodedMensaje = encodeURIComponent(mensajeCompartirPlan);
    const url = `https://wa.me/?text=${encodedMensaje}`;

    window.open(url);
  };
  /* ${plan.archivo.url} */
  const handleAddCalendar = () => {

    const fechaInicio = new Date(plan.fechaInicio);
    const fechaFin = new Date(plan.fechaFin);

    // Formatear la fecha y hora de inicio
    const fechaInicioFormateada = `${fechaInicio.getFullYear()}${(fechaInicio.getMonth() + 1).toString().padStart(2, '0')}${fechaInicio.getDate().toString().padStart(2, '0')}T${fechaInicio.getHours().toString().padStart(2, '0')}${fechaInicio.getMinutes().toString().padStart(2, '0')}${fechaInicio.getSeconds().toString().padStart(2, '0')}`;

    // Formatear la fecha y hora de finalización
    const fechaFinFormateada = `${fechaFin.getFullYear()}${(fechaFin.getMonth() + 1).toString().padStart(2, '0')}${fechaFin.getDate().toString().padStart(2, '0')}T${fechaFin.getHours().toString().padStart(2, '0')}${fechaFin.getMinutes().toString().padStart(2, '0')}${fechaFin.getSeconds().toString().padStart(2, '0')}`;

    // Codifica los detalles del plan en la URL
    const url = `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(plan.titulo)}&dates=${encodeURIComponent(fechaInicioFormateada)}/${encodeURIComponent(fechaFinFormateada)}&location=${encodeURIComponent(plan.direccion)}`;

    // Abre la URL en una nueva ventana o pestaña
    window.open(url, '_blank')
  }

  return (
    <section className={styles.containerDetallePlan}>
      {error && <p className={styles.error}>{error}</p>}

      {plan?.archivoCartel?.url &&
        <article className={styles.column1}>
          <div className={styles.imageContainer}>
            <img src={plan?.archivoCartel?.url} alt={plan.titulo} className={styles.image}
              onError={(e) => {
                const target = e.target as HTMLImageElement; target.style.display = 'none';
              }}
            />
          </div>
        </article>}

      <article className={plan?.archivoCartel?.url ? styles.column2 : styles.fullWidth}>
        <HeaderDetallePlan
          plan={plan}
        />

        {plan?.descripcion &&
          <div className={styles.containerInfo}>
            <div className={styles.label}>
              <span>¿CÓMO SERÁ?</span>
            </div>
            <div>
              <p className={styles.descripcion && styles.textoInfo}>
                <Linkify>{plan?.descripcion}</Linkify>
              </p>
            </div>
          </div>
        }

        <div className={styles.containerInfo}>
          <div className={styles.label}>
          {plan?.tipo === "evento" ? (
              <span>¿CUÁNDO SERÁ?</span>): 
              (<span>¿CUÁNDO ESTÁ DISPONIBLE?</span>)}
          </div>
          {plan?.tipo === "evento" ? (
            <div className={styles.containerFechaEvento}>
              {isSameDay(plan?.fechaInicio, plan?.fechaFin) ? (
                <span className={styles.textoInfo}>{formatDateLong(plan?.fechaInicio)}</span>
              ) : (
                <span className={styles.textoInfo}>
                  {formatDateLong(plan?.fechaInicio)} - {formatDateLong(plan?.fechaFin)}
                </span>
              )}
              <div className={styles.botonCalendario} onClick={handleAddCalendar}>
                <span>Guardar en mi calendario</span>
                <BsArrowRight className={styles.iconoDetalle} size={20} />
              </div>
            </div>)
            :
            (<ul>
              {plan?.horariosDisponibles.map((horario, index) => (
                <li key={index}>
                  <span>  {['Domingo','Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'][horario.diaSemana as number]}</span>
                  <span>: De {horario.horaInicio}</span>
                  <span> a {horario.horaFin}</span>
                </li>
              ))}
            </ul>)}
        </div>

        <div className={styles.horizontalBar}></div>

        {plan?.precio &&
          <div className={styles.containerInfo}>
            <div className={styles.label}>
              <span>¿CUÁNTO CUESTA?</span>
            </div>
            <div>
              <span>{plan.precio}€</span>
            </div>
          </div>
        }

        <div className={styles.containerInfo}>
          <div className={styles.label} >
            <span>¿DÓNDE?</span>
          </div>
          <div>
            <FaMapMarkerAlt className={styles.icono} />
            <span onClick={handleOpenGoogleMaps} className={styles.textoDireccion}>{plan?.direccion}</span>
          </div>
        </div>

        {plan?.ubicacion &&
          <div className={styles.containerMapa}>
            <MapLeaflet
              latitude={center.lat}
              longitude={center.lng}
              direccion={plan.direccion}
            />
          </div>
        }

        {plan?.organizacion &&
          <div className={styles.containerOrganizacion}>
            <div className={styles.label} >
              <span>ORGANIZADO POR:</span>
            </div>
            <div className={styles.organizacion}>
              <FaBuilding fontSize={20} className={styles.icono} />
              <Link
                to={`/org/${plan.organizacion.nombre}/${plan.organizacion._id}`}
                className={styles.contactLink}
              >
                <span>{plan.organizacion.nombre}</span>
              </Link>
            </div>
          </div>
        }

        {plan?.emailContacto &&
          <div className={styles.containerEmailContacto}>
            <MdEmail fontSize={20} className={styles.icono} />
            <a href={`mailto:${plan.emailContacto}`} className={styles.contactLink}>{plan.emailContacto}</a>
          </div>
        }

        {plan?.telefonoContacto &&
          <div className={styles.containerTelefonoContacto}>
            <MdPhone fontSize={20} className={styles.icono} />
            <a href={`tel:${plan.telefonoContacto}`} className={styles.contactLink}>{plan.telefonoContacto}</a>
          </div>
        }

        <div className={styles.horizontalBar}></div>

        <div className={styles.botonCalendario} onClick={handleShare}>
          <span>Compartir</span>
          <IoMdShare className={styles.iconoDetalle} size={20} />
        </div>

        <div className={styles.reportLink} onClick={handleOpenModalReport}>
          <span >Reportar mal uso</span>
        </div>
        {loading && <Loading />}
      {error && <p className={styles.error}>{error}</p>}
      {mensaje && <p >{mensaje}</p>}
      </article>

     

      <ModalConfirmacion
        isOpen={modalReport}
        onRequestClose={handleCloseModalReport}
        onConfirm={handleReportePlan}
        titulo={'Reportar plan'}
        texto={'¿Estás segur@ que deseas reportar el plan?'}
      />

      {plan &&
        <ModalDetallePlan
          isOpen={isModalOpen}
          onRequestClose={handleCloseModal}
          lng={plan.ubicacion.coordinates[0]}
          lat={plan.ubicacion.coordinates[1]}
          titulo={plan.titulo}
          fechaInicio={plan.fechaInicio}
          fechaFin={plan.fechaFin}
          direccion={plan.direccion}
          urlImagen={plan.archivoCartel?.url}
        />}
    </section>
  )
}

export default DetallePlan