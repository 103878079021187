import React, {useEffect} from 'react'
import styles from './PlanesFavoritos.module.css'
import usePlanesApi from '../../../hooks/usePlanesApi';
import Loading from '../../../components/Loading/Loading';
import ListaPlanes from '../../../components/ListaPlanes/ListaPlanes';


const PlanesFavoritos = () => {

  const { loading, getPlanesFavoritos, planes, noPlanes, error } = usePlanesApi();

  useEffect(() => {
    getPlanesFavoritos()
  }, [])
  
  return (
    <section className={styles.containerFavoritos}>
      <h1>Planes guardados</h1>
        {error && <p>{error}</p>}
      {/* ------- en local ------- */}
      {loading ? (
        // Muestra un spinner mientras se carga la lista de planes
        <Loading/>
      ) : noPlanes ? (
        // Muestra el mensaje de "No se encontraron planes" si no hay planes disponibles
        <h2>No tienes planes guardados</h2>
      ) : (
        <div className={styles.listaPlanesFavoritos}>
          {planes && planes.map((plan, index) => (
            <ListaPlanes key={plan._id} plan={plan} url={`/plan/${plan.titulo.replace(/\s+/g, '-')}/${plan._id}`} />
          ))}
        </div>
      )}
    
    </section>
  )
}

export default PlanesFavoritos