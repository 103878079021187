import React, { useEffect, useState } from 'react'
import styles from './OrganizacionItem.module.css'
import ModalConfirmacion from '../../../../components/Modals/ModalConfirmacion/ModalConfirmacion';
import Loading from '../../../../components/Loading/Loading';
import useOrganizacionApi from '../../../../hooks/useOrganizacionApi';
import CheckboxPrivacidad from '../../../../components/CheckboxPrivacidad/CheckboxPrivacidad';
import { validateEmail } from '../../../../utils/validateEmail';

const OrganizacionItem = ({ organizacion, eliminarOrganizacion }) => {

  const [data, setData] = useState({
    _id: '',
    fechaCreacion: new Date(),
    nombre: '',
    descripcion: '',
    direccion: '',
    emailContacto: '',
    telefonoContacto: '',
    archivoLogo: {
      data: undefined,
      nombre: '',
      path: '',
      contentType: '',
      md5sum: '',
      url: '',
      hash: '',
      width: 0,
      height: 0
    },
  });
  const [mensaje, setMensaje] = useState('');
  const [erroresFormulario, setErroresFormulario] = useState('');
  const [selectedInput, setSelectedInput] = useState(null);
  const [modal, setModal] = useState(false)
  const [archivoLogo, setArchivoLogo] = useState(null)
  const [aceptaPoliticaPrivacidad, setAceptaPoliticaPrivacidad] = useState(false);
  const [publicarInfoContacto, setPublicarInfoContacto] = useState(false);

  const { loading, errorOrganizacionApi, actualizarOrganizacion } = useOrganizacionApi();

  const idPrefix = `${organizacion._id}`;

  useEffect(() => {
    setArchivoLogo(organizacion.archivoLogo.url);
  }, [organizacion.archivoLogo.nombre, organizacion.archivoLogo.url]);

  useEffect(() => {
    setData(organizacion)
  }, [organizacion])

  const handleInputFocus = (event) => {
    setSelectedInput(event.target.name);
  };

  const handleInputBlur = () => {
    setSelectedInput(null);
  };

  const handleChange = (name, value) => {

    setData({ ...data, [name]: value });
    setMensaje('')
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setData({ ...data, archivoLogo: file });
    setArchivoLogo(URL.createObjectURL(file));
  };

  const handleDeleteOrganizacion = () => {
    setErroresFormulario('')

    setMensaje('')
    setModal(true)
  }

  const handleSubmitDelete = async () => {
    const res = await eliminarOrganizacion(organizacion._id);

    setErroresFormulario('')

    if (!errorOrganizacionApi && res) {
      setMensaje(res.data.mensaje)
    }
  }

  const handleSubmitUpdate = async (e) => {
    // Evita el envío automático del formulario
    // Llama a tu hook personalizado que realiza la petición a la API
    // Pasando los datos del formulario como parámetros
    // Ejemplo: miHookPersonalizado(formData);
    e.preventDefault();

    setErroresFormulario('')

    setMensaje('')

    const { nombre, descripcion, direccion, emailContacto, telefonoContacto, archivoLogo, } = data;

    /* if (!direccion) {
      setErroresFormulario('Parece que no has introducido la ubicación, por favor selecciona una dirección ofrecida por el autocompletado.');
      return;
    } */

    if (!nombre || !direccion || !emailContacto || !archivoLogo) {
      setErroresFormulario("Todos los campos, excepto la descripción y el teléfono de contacto, son obligatorios.")
      return;
    }

    if (!aceptaPoliticaPrivacidad) {
      setErroresFormulario("Debes aceptar la política de privacidad.")
      return;
    }

    const correctEmail = validateEmail(emailContacto)
    if (!correctEmail) {
      setErroresFormulario("El correo electrónico no tiene un formato válido.");
      return;
    }


    const formData = new FormData();

    formData.append('emailContacto', emailContacto.trim());

    formData.append('telefonoContacto', telefonoContacto.trim());

    formData.append('nombre', nombre.trim());
    formData.append('direccion', direccion.trim());
    if (data.descripcion !== '') {
      formData.append('descripcion', descripcion.trim());
    }

    formData.append('archivoLogo', archivoLogo as any);

    const res = await actualizarOrganizacion(organizacion._id, formData)
    setData(prevData => ({
      ...prevData,
      archivoCartel: {
        data: undefined,
        nombre: '',
        path: '',
        contentType: '',
        md5sum: '',
        url: '',
        hash: '',
        width: 0,
        height: 0
      }
    }));

    setErroresFormulario('')

    if (res) {
      setMensaje(res?.data?.mensaje || "Se ha actualizado la información");
    }
  };

  const handleCloseModal = () => {
    setModal(false)
  }


  return (
    <section className={styles.container}>
      <div className={styles.formGroup}>
        <label htmlFor={`${idPrefix}-nombre`} >
          Nombre
        </label>
        <input
          type="text"
          name="nombre"
          id={`${idPrefix}-nombre`}
          maxLength={50}
          value={data.nombre}
          className={`${selectedInput === 'nombre' ? 'focused' : ''}`}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
          autoComplete="off"
        />
      </div>
      <div className={styles.formGroup}>
        <label htmlFor={`${idPrefix}-descripcion`}>
          Descripción
        </label>
        <textarea
          name="descripcion"
          id={`${idPrefix}-descripcion`}
          value={data.descripcion}
          className={`${styles.textAreaOrganizacionItem} ${selectedInput === 'descripcion' ? 'focused' : ''}`}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
          autoComplete="off"
        />
      </div>

      <div className={styles.formGroup}>
        <label htmlFor={`${idPrefix}-direccion`} >
          Dirección
        </label>
        <input
          type="text"
          name="direccion"
          id={`${idPrefix}-direccion`}
          maxLength={50}
          value={data.direccion}
          className={`${selectedInput === 'direccion' ? 'focused' : ''}`}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
          autoComplete="off"
        />
      </div>
      <div className={styles.formGroup}>
        <label htmlFor={`${idPrefix}-archivoLogo`}>
          Logo
        </label>
        {archivoLogo && <div className={styles.containerLogoOrganizacion}>
          <img src={archivoLogo} alt={organizacion.archivoLogo.nombre} />
        </div>}
        <input
          type="file"
          name="archivoLogo"
          style={{ display: 'none' }}
          id={`${idPrefix}-archivoLogo`}
          className={`${styles.archivoEventoItem} ${selectedInput === 'archivoLogo' ? 'focused' : ''}`}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          accept=".png, .jpg, .jpeg"
          onChange={handleFileChange}
        />
        <button
          className={styles.buttonNuevoCartel}
          onClick={() => document.getElementById(`${idPrefix}-archivoLogo`).click()}>
          Seleccionar nuevo logo
        </button>
      </div>

      <div className={styles.formGroup}>
        <label htmlFor={`emailContacto`}>
          Correo electrónico
        </label>
        <input
          type="text"
          name="emailContacto"
          id="emailContacto"
          placeholder='Correo electrónico'
          value={data.emailContacto}
          className={`${styles.formInput} ${selectedInput === 'emailContacto' ? 'focused' : ''}`}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          onChange={(e) => handleChange(e.target.name, e.target.value)}

        />
      </div>

      <div className={styles.formGroup}>
        <label htmlFor={`telefonoContacto`}>
          Teléfono
        </label>
        <input
          type="text"
          name="telefonoContacto"
          id="telefonoContacto"
          placeholder='Teléfono'
          value={data.telefonoContacto}
          className={`${styles.formInput} ${selectedInput === 'telefonoContacto' ? 'focused' : ''}`}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          onChange={(e) => handleChange(e.target.name, e.target.value)}

        />
        <CheckboxPrivacidad aceptado={aceptaPoliticaPrivacidad} setAceptado={setAceptaPoliticaPrivacidad} />
      </div>

      <hr />
      <div className={styles.botonesSubmit}>
        <button
          type='submit'
          id="buttonSubmitDelete"
          className={styles.buttonSubmitDelete}
          onClick={handleDeleteOrganizacion}
        >
          Eliminar
        </button>
        <button
          type='submit'
          id="buttonSubmitUpdate"
          className={styles.buttonSubmitUpdate}
          onClick={handleSubmitUpdate}
        >
          Actualizar
        </button>
      </div>
      <ModalConfirmacion
        texto={'¿Estás segur@ de que deseas eliminar la organización?'}
        titulo={'Eliminar Organización'}
        isOpen={modal}
        onRequestClose={handleCloseModal}
        onConfirm={handleSubmitDelete}
      />
      {loading &&
        <div className={styles.loading}>
          <Loading />
        </div>}
      {erroresFormulario && <p className={styles.error}>{erroresFormulario}</p>}
      {errorOrganizacionApi && <p className={styles.error}>{errorOrganizacionApi}</p>}
      {mensaje !== '' && <p className={styles.mensaje}>{mensaje}</p>}
    </section>

  );
}

export default OrganizacionItem