import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import './RoutesComponent.css'

/* import AuthContext from '../context/AuthContext'; */
import ConfirmarCuenta from '../pages/ConfirmarCuenta/ConfirmarCuenta';
import Home from '../pages/Home/Home';
import Login from '../pages/Login/Login';
import Registro from '../pages/Registro/Registro';
import AvisoLegal from '../pages/Politicas/AvisoLegal';
import CondicionesGenerales from '../pages/Politicas/CondicionesGenerales';
import Cookies from '../pages/Politicas/Cookies';
import PoliticasPrivacidad from '../pages/Politicas/PoliticasPrivacidad';
import RestablecerPassword from '../pages/Perfil/RestablecerPassword/RestablecerPassword';
import NotFound from '../pages/NotFound/NotFound';
import CrearOrganizacion from '../pages/CrearOrganizacion/CrearOrganizacion';
import PlanesFavoritos from '../pages/Perfil/PlanesFavoritos/PlanesFavoritos';
import InfoPerfil from '../pages/Perfil/InfoPerfil/InfoPerfil';
import OrganizacionesUsuario from '../pages/Perfil/OrganizacionesUsuario/OrganizacionesUsuario';
import DetalleOrganizacion from '../pages/DetalleOrganizacion/DetalleOrganizacion';
import Publicar from '../pages/Publicar/Publicar';
import PlanesUsuario from '../pages/Perfil/PlanesUsuario/PlanesUsuario';
import PublicacionItemEdit from '../pages/Perfil/PlanesUsuario/PublicacionItemEdit.tsx/PublicacionItemEdit';
import DetallePlan from '../pages/DetallePlan/DetallePlan';
import ScrollToTop from '../components/ScrollToTop/ScrollToTop';
import DetalleEurona from '../components/Patrocinador/DetallePatrocinador/DetalleEurona';
import Nosotros from '../pages/Nosotros/Nosotros';

function RoutesComponent() {
  const location = useLocation();
  const isNosotrosPage = location.pathname === '/nosotros';

  /*  const { isLoggedIn } = useContext(AuthContext); */
  const tokenLogueado = localStorage.getItem('tokenLogueado')

  // Función para verificar la autenticación
  const requireAuth = (component) => {
    return tokenLogueado ? component : <Navigate to="/iniciar-sesion" replace />;
  };
  return (
    <main className={`${isNosotrosPage ? 'contentNosotros' : 'content'}`}>
      <ScrollToTop />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/plan/:titulo/:planId' element={<DetallePlan />} />
        <Route path='/nosotros' element={<Nosotros />} />
        <Route path='/eurona' element={<DetalleEurona />} />
        <Route path='/planes-favoritos' element={requireAuth(<PlanesFavoritos />)} />
        <Route path='/publicar' element={requireAuth(<Publicar />)} />
        <Route path='/perfil' element={requireAuth(<InfoPerfil />)} />
        <Route path='/mis-planes' element={requireAuth(<PlanesUsuario />)} />
        <Route path='/editar-plan/:publicacionId' element={requireAuth(<PublicacionItemEdit />)} />
        <Route path='/crear-organizacion' element={requireAuth(<CrearOrganizacion />)} />
        <Route path='/tus-organizaciones' element={requireAuth(<OrganizacionesUsuario />)} />
        <Route path='/org/:nombreOrganizacion/:organizacionId' element={<DetalleOrganizacion />} />
        <Route path='/iniciar-sesion' element={<Login />} />
        <Route path='/registro' element={<Registro />} />
        <Route path='/aviso-legal' element={<AvisoLegal />} />
        <Route path='/condiciones-generales' element={<CondicionesGenerales />} />
        <Route path='/cookies' element={<Cookies />} />
        <Route path='/politica-privacidad' element={<PoliticasPrivacidad />} />
        <Route path='/restablecer-password/:token' element={<RestablecerPassword />} />
        <Route path='/confirmar-cuenta/:token' element={<ConfirmarCuenta />} />
        <Route path="*" element={<NotFound />} />
        {/* <Route path='/contacto' element={<Contacto />} /> */}
      </Routes>
    </main>
  );
}

export default RoutesComponent;