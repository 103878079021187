import React, { useState, useEffect, useRef } from 'react'
import ReactModal from 'react-modal';
import styles from './PublicacionItemEdit.module.css'
import Switch from 'react-switch';
import { Plan } from '../../../../interfaces/Plan';
import usePlanesApi from '../../../../hooks/usePlanesApi';
import InputSearchPlace from '../../../../components/InputSearchPlace/InputSearchPlace';
import CheckboxPrivacidad from '../../../../components/CheckboxPrivacidad/CheckboxPrivacidad';
import ModalConfirmacion from '../../../../components/Modals/ModalConfirmacion/ModalConfirmacion';
import Loading from '../../../../components/Loading/Loading';
import { Link, useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import es from 'date-fns/locale/es';
import useUsuariosApi from '../../../../hooks/useUsuariosApi';
import useOrganizacionApi from '../../../../hooks/useOrganizacionApi';
import Categorias from '../../../../components/Categorias/Categorias';
import { convertirDiaANumero } from '../../../../utils/convertirDiaANumero';
import { convertirNumeroADia } from '../../../../utils/convertirNumeroADia';
import FechasEvento from '../../../../components/FechasEvento/FechasEvento';
import FechasExperiencia from '../../../../components/FechasExperiencia/FechasExperiencia';

ReactModal.setAppElement('#root');

const PublicacionItemEdit = () => {


    const [data, setData] = useState<Plan>({
        _id: '',
        fechaCreacion: new Date(),
        titulo: '',
        descripcion: '',
        direccion: '',
        municipio: '',
        emailContacto: '',
        telefonoContacto: '',
        tipo: '',
        precio: '',
        urlPlan: '',
        horariosDisponibles: [],
        categorias: [],
        archivoCartel: {
            data: undefined,
            nombre: '',
            path: '',
            contentType: '',
            md5sum: '',
            url: '',
            hash: '',
            width: 0,
            height: 0
        },
        ubicacion: {
            type: 'Point',
            coordinates: [0, 0]
        },
        fechaInicio: new Date(),
        fechaFin: new Date(),
        visible: false,
        destacado: false,
        usuario: [],
    });
    const [mensaje, setMensaje] = useState('');
    const [erroresFormulario, setErroresFormulario] = useState('');
    const [selectedInput, setSelectedInput] = useState(null);
    const [modal, setModal] = useState(false)
    const [cartelPlan, setCartelPlan] = useState(null)
    const [publicarInfoContacto, setPublicarInfoContacto] = useState(false);
    const [aceptaPoliticaPrivacidad, setAceptaPoliticaPrivacidad] = useState(false);
    const [horariosDisponibles, setHorariosDisponibles] = useState([]);

    const { publicacionId } = useParams();

    const { actualizarPlan, plan, error, setError, loading, eliminarPlan, getPlanPorId } = usePlanesApi();


    const { dataUsuario, errorUsuarioApi, getUsuario, } = useUsuariosApi();

    const { organizacion, errorOrganizacionApi, organizacionesDeUnUsuario } = useOrganizacionApi();

    const MAX_LENGTH = 0;

    const now = new Date();

    const remainingCharsTitulo = MAX_LENGTH + data.titulo.length || 1;

    const tokenLogueado = localStorage.getItem('tokenLogueado')

    useEffect(() => {
        getPlanPorId(publicacionId)
    }, [])

    useEffect(() => {
        getUsuario(tokenLogueado);
    }, [])

    useEffect(() => {
        if (dataUsuario) organizacionesDeUnUsuario();
    }, [dataUsuario])

    const errorRef = useRef(null);

  useEffect(() => {
    if (erroresFormulario) {
      if (errorRef.current) {
        errorRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [erroresFormulario, error, errorOrganizacionApi, errorUsuarioApi]);

    useEffect(() => {
        if (plan) {
            plan.fechaInicio = new Date(plan.fechaInicio);
            plan.fechaFin = new Date(plan.fechaFin);
            setCartelPlan(plan.archivoCartel.url)
            setData({
                ...plan,
                precio: plan.precio || '',
                descripcion: plan.descripcion || '',
                emailContacto: plan.emailContacto || '',
                telefonoContacto: plan.telefonoContacto || '',
                organizacionId: plan?.organizacion?._id,
            });
            if (plan.telefonoContacto || plan.emailContacto) {
                setPublicarInfoContacto(true)
            }

            const horariosConvertidos = plan.horariosDisponibles.map(horario => ({
                ...horario,
                diaSemana: convertirNumeroADia(horario.diaSemana)
            }));

            setHorariosDisponibles(horariosConvertidos)
           /*  setData({
                ...data,
                horariosDisponibles: horariosConvertidos
            }) */
        }
    }, [plan])
  
  /*   useEffect(() => {

        const horariosConvertidos = horariosDisponibles.map(horario => ({
            ...horario,
            diaSemana: convertirDiaANumero(horario.diaSemana) // Convertir a número
        }));

        setData({
            ...data,
            horariosDisponibles: horariosConvertidos
        })
    }, [horariosDisponibles]) */

    const setCategorias = (categorias: string[]) => {
        setData(prevData => ({
            ...prevData,
            categorias
        }));
    };

    const handleInputFocus = (event) => {
        setSelectedInput(event.target.name);
    };

    const handleInputBlur = () => {
        setSelectedInput(null);
    };

    const handleFechaInicioChange = (date) => {
        setData({ ...data, fechaInicio: date });
    }

    const handleFechaFinChange = (date) => {
        setData({ ...data, fechaFin: date });
    }

    const handleChange = (name, value) => {
        let transformedValue = value;

        if (name === 'fechaInicio' || name === 'fechaFin') {
            transformedValue = new Date(value);
        }

        setData({ ...data, [name]: transformedValue });
        setMensaje('')
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setData({ ...data, archivoCartel: file });
        setCartelPlan(URL.createObjectURL(file));
    };

    // Función para manejar la selección de un día
    const handleDiaSeleccionado = (dia) => {
        const horarioExistente = horariosDisponibles.find(horario => horario.diaSemana === dia);
        if (horarioExistente) {
            // Si el día ya está en la lista, lo eliminamos
            const nuevosHorarios = horariosDisponibles.filter(horario => horario.diaSemana !== dia);
            setHorariosDisponibles(nuevosHorarios);
        } else {
            // Si el día no está en la lista, lo agregamos con horas por defecto
            setHorariosDisponibles([...horariosDisponibles, { diaSemana: dia, horaInicio: '', horaFin: '' }]);
        }
    };

    // Función para manejar el cambio en la hora de inicio o fin
    const handleHoraChange = (dia, tipoHora, value) => {
        const nuevosHorarios = horariosDisponibles.map(horario => {
            if (horario.diaSemana === dia) {
                return { ...horario, [tipoHora]: value };
            }
            return horario;
        });
        setHorariosDisponibles(nuevosHorarios);

    };

    const handleDeletePlan = () => {
        if (error) {
            setError(null)
        }
        setErroresFormulario('')

        setMensaje('')
        setModal(true)
    }

    const handleSubmitDelete = async () => {
        setModal(false)
        const res = await eliminarPlan(plan._id);

        setErroresFormulario('')

        if (!error && res) {
            setMensaje(res.data.mensaje)
        }

    }

    const handleSubmitUpdate = async (e) => {
        // Evita el envío automático del formulario
        // Llama a tu hook personalizado que realiza la petición a la API
        // Pasando los datos del formulario como parámetros
        // Ejemplo: miHookPersonalizado(formData);
        e.preventDefault();

        if (error) {
            setError(null)
        }
        setErroresFormulario('')

        setMensaje('')

        const { titulo, descripcion, direccion, municipio, categorias, emailContacto, telefonoContacto, ubicacion, archivoCartel, fechaInicio, fechaFin, precio, tipo, organizacionId } = data;

        if (!direccion) {
            setErroresFormulario('Parece que no has introducido la ubicación, por favor selecciona una dirección ofrecida por el autocompletado.');
            return;
        }


        if (!titulo) {
            setErroresFormulario("El título es obligatorio")
            return;
        }

        if (!direccion) {
            setErroresFormulario('Parece que no has introducido la ubicación, por favor selecciona una dirección ofrecida por el autocompletado.');
            return;
        }

        if (!archivoCartel) {
            setErroresFormulario('Es obligatorio adjuntar un cartel.');
            return;
        }


        if (tipo === "evento") {
            if (!fechaFin) {
                setErroresFormulario('Parece que no has indicado la fecha de finalización del plan, por favor pulsa sobre el botón "Seleccionar fecha de fin" para añadir la fecha del plan.')
                return
            }
            if (!fechaInicio) {
                setErroresFormulario('Parece que no has indicado la fecha de inicio del plan, por favor pulsa sobre el botón "Seleccionar fecha de inicio" para añadir la fecha del plan.')
                return
            }

            if (fechaInicio.getTime() > fechaFin.getTime()) {
                setErroresFormulario('La fecha de inicio no puede ser posterior a la fecha de fin.');
                return;
            }
            const hoy = new Date();
            const ayer = new Date();
            ayer.setDate(hoy.getDate() - 1);

            if (ayer > fechaFin) {
                setErroresFormulario('La fecha de finalización no es correcta, no se pueden subir planes del pasado.');
                return;
            }

        }

        if (tipo === "experiencia"
            && (horariosDisponibles.length === 0  || horariosDisponibles.some(horario => !horario.horaInicio || !horario.horaFin))) {
            setErroresFormulario("No has añadido los días y/o horarios disponibles");
            return;
        }


        const formData = new FormData();

        if (publicarInfoContacto) {
            if (!aceptaPoliticaPrivacidad) {
                setErroresFormulario("Debes aceptar la política de privacidad.")
                return;
            }
            if (emailContacto) {
                formData.append('emailContacto', emailContacto.trim());
            }

            if (telefonoContacto) {
                formData.append('telefonoContacto', telefonoContacto.trim());
            }
        }

        if (descripcion) formData.append('descripcion', descripcion.trim());
        if (organizacionId) formData.append('organizacionId', organizacionId);
        if (precio) formData.append('precio', precio);

        formData.append('titulo', titulo.trim());
        formData.append('direccion', direccion);
        formData.append('municipio', municipio);
        formData.append('tipo', tipo);
        formData.append('categorias', categorias.join(','));
        formData.append('archivoCartel', archivoCartel as any);
        formData.append('ubicacion', JSON.stringify(ubicacion));


        // formData.append('ubicacion', JSON.stringify(data.ubicacion));
        if (fechaInicio && fechaFin) {
            formData.append('fechaInicio', fechaInicio.toISOString());
            formData.append('fechaFin', fechaFin.toISOString());
        }

        if (horariosDisponibles) {
            formData.append('horariosDisponibles', JSON.stringify(data.horariosDisponibles));
        }

        const res = await actualizarPlan(plan._id, formData)
        setData(prevData => ({
            ...prevData,
            archivoCartel: {
                data: undefined,
                nombre: '',
                path: '',
                contentType: '',
                md5sum: '',
                url: '',
                hash: '',
                width: 0,
                height: 0
            }
        }));

        setErroresFormulario('')

        if (res) {
            setMensaje(res.data.mensaje)
        }
    };

    const handleCloseModal = () => {
        setModal(false)
    }

    const handleSwitchChange = (checked) => {
        setPublicarInfoContacto(checked);
    };

    return (
        <article className={styles.container}>
            {!plan && !error ?
                (<Loading />)
                :
                (<section>
                    <div className={styles.formGroup}>
                        <label htmlFor={`titulo`} >
                            Título (50 caracteres máximo)
                        </label>
                        <input
                            type="text"
                            name="titulo"
                            id={`titulo`}
                            maxLength={50}
                            value={data.titulo}
                            className={`${selectedInput === 'titulo' ? 'focused' : ''}`}
                            onFocus={handleInputFocus}
                            onBlur={handleInputBlur}
                            onChange={(e) => handleChange(e.target.name, e.target.value)}
                            autoComplete="off"
                        />
                        <small>{remainingCharsTitulo} - 50</small>
                    </div>
                    <div className={styles.formGroup}>
                        <label htmlFor={`descripcion`}>
                            Descripción del plan
                        </label>
                        <textarea
                            name="descripcion"
                            id={`descripcion`}

                            value={data.descripcion}
                            className={`${styles.textAreaPlanItem} ${selectedInput === 'descripcion' ? 'focused' : ''}`}
                            onFocus={handleInputFocus}
                            onBlur={handleInputBlur}
                            onChange={(e) => handleChange(e.target.name, e.target.value)}
                            autoComplete="off"
                        />
                    </div>
                    <div className={styles.formGroup}>
                        <p>
                            Categorías:
                        </p>
                    <Categorias
                        primeraOpcion='Otros'
                        categorias={data.categorias}
                        setCategorias={setCategorias}
                    />
                    </div>
                    <div className={styles.formGroup}>
                        <label htmlFor={`ubicacion`} >
                            Ubicación
                        </label>
                        <InputSearchPlace data={data} setData={setData} idInput={`ubicacion`} />
                    </div>


                    {data.tipo === "evento" ?
                        (<FechasEvento
                            data={data}
                            setData={setData}
                        />)
                        :
                        (
                            <FechasExperiencia
                                /*           siempreDisponible={siempreDisponible}
                                          setSiempreDisponible={setSiempreDisponible} */
                                data={data}
                                setData={setData} />
                        )}

                    <div className={styles.formGroup}>
                        <label htmlFor={`archivoCartel`}>
                            Cartel del plan
                        </label>
                        {cartelPlan && <div className={styles.containerCartelPlan}>
                            <img src={cartelPlan} alt={plan.archivoCartel.nombre} />
                        </div>}
                        <input
                            type="file"
                            name="archivoCartel"
                            style={{ display: 'none' }}
                            id={`archivoCartel`}
                            className={`${styles.archivoPlanItem} ${selectedInput === 'archivoCartel' ? 'focused' : ''}`}
                            onFocus={handleInputFocus}
                            onBlur={handleInputBlur}
                            accept=".png, .jpg, .jpeg"
                            onChange={handleFileChange}
                        />
                        <button
                            className={styles.buttonNuevoCartel}
                            onClick={() => document.getElementById(`archivoCartel`).click()}>
                            Seleccionar nuevo cartel
                        </button>
                    </div>

                    {data.organizacion &&
                        <div className={styles.formGroup}>
                            <p>Organiza:&nbsp;
                                <Link
                                    to={`/org/${data.organizacion.nombre}/${data.organizacion._id}`}
                                >{data.organizacion.nombre}</Link>
                            </p>
                        </div>
                    }

                    <div className={styles.formGroup}>
                        <div>
                            <label htmlFor="organizacionId">Organiza:</label>
                            <select
                                name="organizacionId"
                                id="organizacionId"
                                onChange={(e) => handleChange(e.target.name, e.target.value)}
                                value={data.organizacionId}
                            >
                                {organizacion && organizacion.map((organizacionItem) => (
                                    <option key={organizacionItem._id} value={organizacionItem._id}>
                                        {organizacionItem.nombre}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            ¿No encuentras tu empresa, asociación, ayuntamiento...? Haz click&nbsp;
                            <Link to="/crear-organizacion" target="_blank">Aquí</Link>
                            &nbsp;para crear una nueva organización
                        </div>
                    </div>

                    <div className={styles.formGroup}>
                        <label htmlFor="precio">
                            Precio
                        </label>
                        <input
                            type="number"
                            min="0"
                            step="0.01"
                            name="precio"
                            id="precio"
                            value={data.precio}
                            className={` ${selectedInput === 'precio' ? styles.focused : ''}`}
                            onFocus={handleInputFocus}
                            onBlur={handleInputBlur}
                            onChange={(e) => handleChange(e.target.name, e.target.value)}
                        />
                    </div>

                    <div className={styles.contactoSwitch}>
                        <label className={styles.formLabel}>
                            Contacto
                        </label>
                        <Switch
                            onChange={handleSwitchChange}
                            checked={publicarInfoContacto}
                            className={styles.switch}
                        />
                    </div>

                    {publicarInfoContacto && (
                        <div className={styles.formGroup}>
                            <label htmlFor={`emailContacto`}>
                                Correo electrónico
                            </label>
                            <input
                                type="text"
                                name="emailContacto"
                                id="emailContacto"
                                placeholder='Correo electrónico'
                                value={data.emailContacto}
                                className={`${styles.formInput} ${selectedInput === 'emailContacto' ? 'focused' : ''}`}
                                onFocus={handleInputFocus}
                                onBlur={handleInputBlur}
                                onChange={(e) => handleChange(e.target.name, e.target.value)}

                            />
                        </div>
                    )}



                    {publicarInfoContacto && (
                        <div className={styles.formGroup}>
                            <label htmlFor={`telefonoContacto`}>
                                Teléfono
                            </label>
                            <input
                                type="text"
                                name="telefonoContacto"
                                id="telefonoContacto"
                                placeholder='Teléfono'
                                value={data.telefonoContacto}
                                className={`${styles.formInput} ${selectedInput === 'telefonoContacto' ? 'focused' : ''}`}
                                onFocus={handleInputFocus}
                                onBlur={handleInputBlur}
                                onChange={(e) => handleChange(e.target.name, e.target.value)}

                            />
                            <CheckboxPrivacidad aceptado={aceptaPoliticaPrivacidad} setAceptado={setAceptaPoliticaPrivacidad} />
                        </div>
                    )}


                    <hr />
                    <div className={styles.botonesSubmit}>
                        <button
                            type='submit'
                            id="buttonSubmitDelete"
                            className={styles.buttonSubmitDelete}
                            onClick={handleDeletePlan}
                        >
                            Eliminar
                        </button>
                        <button
                            type='submit'
                            id="buttonSubmitUpdate"
                            className={styles.buttonSubmitUpdate}
                            onClick={handleSubmitUpdate}
                        >
                            Actualizar
                        </button>
                    </div>
                    {loading &&
                        <div className={styles.loading}>
                            <Loading />
                        </div>}
                    <ModalConfirmacion
                        texto={'¿Estás segur@ de que deseas eliminar el plan?'}
                        titulo={'Eliminar Plan'}
                        isOpen={modal}
                        onRequestClose={handleCloseModal}
                        onConfirm={handleSubmitDelete}
                    />
                </section>)
            }

            {erroresFormulario && <p className={styles.error} ref={errorRef}>{erroresFormulario}</p>}
            {error && <p className={styles.error} ref={errorRef}>{error}</p>}
            {errorUsuarioApi && <p className={styles.error} ref={errorRef}>{errorUsuarioApi}</p>}
            {errorOrganizacionApi && <p className={styles.error} ref={errorRef}>{errorOrganizacionApi}</p>}
            {mensaje !== '' && <p className={styles.mensaje} ref={errorRef}>{mensaje}</p>}
        </article >

    );
};

export default PublicacionItemEdit