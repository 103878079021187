import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom';
/* import 'rc-slider/assets/index.css';
import ReactPaginate from 'react-paginate'; */
import Pagination from 'rc-pagination';
import "rc-pagination/assets/index.css";

import styles from './Home.module.css'
import PrimerHTML from './PrimerHTML/PrimerHTML';
import InputSearchPlace from '../../components/InputSearchPlace/InputSearchPlace';
import Loading from '../../components/Loading/Loading';
import RadioDistancia from '../../components/RangeRadioDistancia/RadioDistancia';
import Categorias from '../../components/Categorias/Categorias';
import { Coordenadas } from '../../interfaces/Coordenadas';
import { getLastLocation } from '../../utils/getLastLocationStorage';
import FiltroFechas from '../../components/FiltroFechas/FiltroFechas';
import usePlanesApi from '../../hooks/usePlanesApi';
import ListaPlanes from '../../components/ListaPlanes/ListaPlanes';
import axios from 'axios';
import Patrocinador from '../../components/Patrocinador/Patrocinador';
/* import Autocomplete from "react-google-autocomplete"; */
import { Adsense } from '@ctrl/react-adsense';
import GooglePlacesAutocomplete from '../../components/GooglePlacesAutocomplete/GooglePlacesAutocomplete';
import { saveLocation } from '../../utils/saveLocationStorage';

const Home = () => {

  const [page, setPage] = useState(1);

  const [ubicacion, setUbicacion] = useState({
    municipio: "",
    direccion: "",
    ubicacion: {
      type: 'Point',
      coordinates: ["", ""],
    },
  });
  /*   const [nombreMunicipio, setNombreMunicipio] = useState(''); */
  const [radio, setRadio] = useState(50);
  const [categorias, setCategorias] = useState(['Otros']);
  const [token, setToken] = useState(null)
  const [selectedDate, setSelectedDate] = useState({
    fechaInicioFiltro: null,
    fechaFinFiltro: null,
  })

  /*  const [coordenadas, setCoordenadas] = useState<Coordenadas>({
     lng: 0,
     lat: 0
   }) */

  const limit = 30;


  const { loading, setLoading, error, noPlanes, planes, totalPlanesPaginado, mensajeApiPlanes, getPlanes } = usePlanesApi()

  /*   const token = localStorage.getItem('coordenadasStorage'); */


  useEffect(() => {
    setToken(localStorage.getItem('coordenadasStorage') ? true : false)
    const fetchLastLocation = async () => {
      const { coordenadasStorage, municipioStorage } = await getLastLocation();

         setUbicacion(prevState => ({
          ...prevState,
           direccion: municipioStorage,
         }));
   
         if (coordenadasStorage && municipioStorage) {
          setUbicacion(prevState => ({
            ...prevState,
            direccion: municipioStorage,
            ubicacion: {
              type: 'Point',
              coordinates: [coordenadasStorage.lng, coordenadasStorage.lat],
            },
          }));
   
           await getPlanes(coordenadasStorage.lng, coordenadasStorage.lat, municipioStorage, radio, categorias, page, limit, selectedDate.fechaInicioFiltro, selectedDate.fechaFinFiltro)
         }
    };

    fetchLastLocation();

  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [loading])

  useEffect(() => {
    if (ubicacion?.municipio && ubicacion?.ubicacion) {
      setPage(1)

      getPlanes(ubicacion.ubicacion.coordinates[0], ubicacion.ubicacion.coordinates[1], ubicacion.municipio, radio, categorias, 1, limit, selectedDate.fechaInicioFiltro, selectedDate.fechaFinFiltro)

    }
  }, [ubicacion?.municipio])

  /*  useEffect(() => {
     // Renderizar la nueva página cuando cambia el estado de `page`
     if (page !== currentPage) {
       getPlanes(coordenadas.lng, coordenadas.lat, nombreMunicipio, radio, categoria, page, limit);
       setPage(page);
     }
   }, [page]); */

  const handleBuscarPlanes = async (e) => {
    if (e.type === "click" || (e.type === "keyup" && e.key === "Enter")) {
      setToken(true)
      setLoading(true)
      const response = await axios.get(
        `
        https://api.tomtom.com/search/2/geocode/${ubicacion.direccion}.JSON?key=S29BGno6l29jpwfwkW5n6nzArEBknY8D&countrySet=ES
        `
      );

      const primerResultado = response.data.results[0]

      const primerMunicipio = primerResultado.address.municipalitySubdivision
        ? primerResultado.address.municipalitySubdivision
        : primerResultado.address.municipality;

      setPage(1)


      setUbicacion({
     
        direccion: primerMunicipio,
        municipio: primerMunicipio,
        ubicacion: {
          type: 'Point',
          coordinates: [primerResultado.position.lon,  primerResultado.position.lat],
        }
      });


      await getPlanes(primerResultado.position.lon, primerResultado.position.lat, primerMunicipio, radio, categorias, 1, limit, selectedDate.fechaInicioFiltro, selectedDate.fechaFinFiltro);

      setLoading(false)
      saveLocation({ lng: primerResultado.position.lon, lat: primerResultado.position.lat }, primerMunicipio);
    }


  }

  const handlePageClick = (page) => {

    /*   console.log(data)
      const selectedPage = data.selected + 1; */
    setPage(page);
    getPlanes(ubicacion.ubicacion.coordinates[0], ubicacion.ubicacion.coordinates[1], ubicacion.municipio, radio, categorias, page, limit, selectedDate.fechaInicioFiltro, selectedDate.fechaFinFiltro);
  };

  return (
    <section className={styles.sectionHome}>
      {/* <Adsense
        client="ca-pub-6032150543018427"
        slot="7259870550"
        style={{ display: 'block' }}
        format="auto"
        adTest="on"  // Habilitar modo de prueba
      />
      <Adsense
        client="ca-pub-6032150543018427"
        slot="7259870550"
        style={{ width: 500, height: 300 }}
        format=""
        adTest="on"  // Habilitar modo de prueba
      />
      <Adsense
        client="ca-pub-6032150543018427"
        slot="7259870550"
        style={{ display: 'block' }}
        layout="in-article"
        format="fluid"
        adTest="on"  // Habilitar modo de prueba
      /> */}
      <h1>Descubre planes, eventos y actividades. </h1>
      {/*    <div className={styles.formGroup}>
        <InputSearchPlace
          data={ubicacion}
          setData={setUbicacion}
          handleBuscarPlanes={handleBuscarPlanes}
        />
        {mensaje && <p>{mensaje}</p>}
      </div> */}


      <GooglePlacesAutocomplete
        data={ubicacion}
        setData={setUbicacion}
        handleBuscarPlanes={handleBuscarPlanes}
        setToken={setToken}
      />

      {/* <Autocomplete
        apiKey='AIzaSyBQ7VfutSto5dA3k6I6PYw8ukXur7EhlR8'

        onPlaceSelected={(place) => console.log(place)}
      /> */}

      <Categorias
        primeraOpcion={'Todos'}
        categorias={categorias}
        setCategorias={setCategorias}
      />

      <RadioDistancia
        radio={radio}
        setRadio={setRadio}
      />

      <FiltroFechas
        setSelectedDate={setSelectedDate}
      />

      {!token && <PrimerHTML />}
      {/* {planes.length > 0 &&
        (<>
          {nombreMunicipio &&
            <p>Próximos planes en {nombreMunicipio} y alrededores.</p>}
        </>)} */}
      {error && <p>{error}</p>}
      {/* ------- en local ------- */}
      {loading ? (
        <div className={styles.loading}>
          <Loading />
        </div>
      ) :
        (<section>
          {mensajeApiPlanes &&
            <div className={styles.containerNoPlans}>
              <span>{mensajeApiPlanes}</span>
            </div>}
          <div className={styles.planesCard}>
            {planes && planes.map((plan, index) => {
              if (plan.tipo === "Patrocinado") {
                return <Patrocinador key={index} patrocinador={plan} />
              } else {
                return <ListaPlanes
                  key={plan._id}
                  plan={plan}
                  enlaceWebExterior={plan?.enlaceWebExterior}
                  url={`/plan/${plan.titulo.replace(/\s+/g, '-')}/${plan._id}`}
                />
              }
            })}
          </div>
        </section>
        )}
      {/* <ReactPaginate
        previousLabel={page === 1 ? null : '<'}
        nextLabel={page === pageCount ? null : '>'}
        breakLabel={'...'}
        pageCount={pageCount}
        marginPagesDisplayed={1}
        pageRangeDisplayed={3}
        onPageChange={handlePageClick}
        containerClassName={styles.pagination}
      /> */}
      {!noPlanes &&
        <div className={styles.paginationContainer}>
          <Pagination
            pageSize={limit}
            current={page}
            total={totalPlanesPaginado}
            onChange={handlePageClick}
            showTitle={false}
          />
        </div>}

    </section>
  )
}

export default Home