import { useState } from 'react';
import axios from 'axios';
import { urlEndpoints } from '../utils/globalVariables';



const useOrganizacionApi = () => {

    const [loading, setLoading] = useState(false);
    const [errorOrganizacionApi, setErrorOrganizacionApi] = useState(null);
    const [organizacion, setOrganizacion] = useState(null)



    const getOrganizacionById = async (organizacionId) => {
        try {
            setLoading(true);
            setErrorOrganizacionApi(null);

            const response = await axios.get(`${urlEndpoints}/api/organizacion/detalle/${organizacionId}`);

            setOrganizacion(response.data)
            return response;

        }
        catch (error) {
            setErrorOrganizacionApi(error.response.data.mensaje || 'Ha ocurrido un error inesperado al obtener los organizacions, por favor vuelve a intentarlo');
        }
        finally {
            setLoading(false);
        }
    };

    const crearOrganizacion = async (organizacion) => {

        try {
            setLoading(true);
            setErrorOrganizacionApi(null);

            const token = await localStorage.getItem('tokenLogueado');

            // Verificar si el token está presente y si es válido
            if (!token) {
                setErrorOrganizacionApi('Es necesario iniciar sesión para crear una organización');
                setLoading(false);
                return
            }

            const response = await axios.post(`${urlEndpoints}/api/organizacion`, organizacion, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`,
                },
            });

            return response;
        }
        catch (error) {
            console.log(error)
            setErrorOrganizacionApi(error.response.data.mensaje || 'Error al crear el organizacion');
        }
        finally {
            setLoading(false);
        }
    };

    const actualizarOrganizacion = async (organizacionId, organizacion) => {
        try {
            setLoading(true);
            setErrorOrganizacionApi(null);

            const token = localStorage.getItem('tokenLogueado');


            // Verificar si el token está presente y si es válido
            if (!token) {
                setErrorOrganizacionApi('Es necesario iniciar sesión para crear una organización');
                setLoading(false);
                return;
            }

            const response = await axios.patch(`${urlEndpoints}/api/organizacion/${organizacionId}`, organizacion, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`,
                },
            });

            return response;
        }
        catch (error) {

            setErrorOrganizacionApi(error.response?.data?.mensaje || 'Error al actualizar el organizacion, por favor vuelve a intentarlo');
        }
        finally {
            setLoading(false);
        }
    };

    const eliminarOrganizacion = async (organizacionId) => {

        try {
            setLoading(true);
            setErrorOrganizacionApi(null);

            const token = localStorage.getItem('tokenLogueado');

            // Verificar si el token está presente y si es válido
            if (!token) {
                setErrorOrganizacionApi('Es necesario iniciar sesión para crear una organización');
                setLoading(false);
                return;
            }

            const response = await axios.delete(`${urlEndpoints}/api/organizacion/${organizacionId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            await organizacionesDeUnUsuario();

            return response;
        }
        catch (error) {
            /* console.log(error.response) */

            setErrorOrganizacionApi(error.response?.data?.mensaje || 'Error al eliminar el organizacion');
        }
        finally {
            setLoading(false);
        }
    };

    const organizacionesDeUnUsuario = async () => {
        try {
            setLoading(true);

            setErrorOrganizacionApi(null);

            const token = localStorage.getItem('tokenLogueado');

            // Verificar si el token está presente y si es válido
            if (!token) {
                setErrorOrganizacionApi('Es necesario iniciar sesión para añadir eventos a la aplicación');

                setLoading(false);
                return;
            }


            const response = await axios.get(`${urlEndpoints}/api/organizacion/organizaciones-usuario`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            setOrganizacion(response.data)

            return response;
        }
        catch (error) {
            setErrorOrganizacionApi(error.response?.data?.mensaje || 'Error al obtener las organizaciones');
        }
        finally {
            setLoading(false);
        }
    };



    return {
        loading,
        errorOrganizacionApi,
        organizacion,
        getOrganizacionById,
        crearOrganizacion,
        actualizarOrganizacion,
        eliminarOrganizacion,
        organizacionesDeUnUsuario
    };
};

export default useOrganizacionApi;