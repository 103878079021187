import React, { useEffect, useState } from 'react'
import styles from './Publicar.module.css'
import Tipo from './Fases/Tipo/Tipo';
import Categorias from './Fases/FaseCategorias/FaseCategorias';
import Datos from './Fases/Datos/Datos';
import Opcional from './Fases/Opcional/Opcional';
import { FaCircle, FaCheck } from 'react-icons/fa';
import ProgressStepBar from './ProgressStepBar/ProgressStepBar';

const Publicar = () => {

    const [fase, setFase] = useState(1);
    const [categorias, setCategorias] = useState(['Otros']);
    const [mensajeFormulario, setMensajeFormulario] = useState('');
    const [data, setData] = useState({
        titulo: '',
        descripcion: '',
        urlArchivoTemporal: null,
        archivoCartel: null,
        direccion: '',
        municipio: '',
        emailContacto: '',
        telefonoContacto: '',
        ubicacion: {},
        tipo: '',
        fechaInicio: null,
        fechaFin: null,
        horariosDisponibles: [],
        siempreDisponible: false,
        precio: '',
        organizacion: '',
        organizacionId: null
    });

    useEffect(() => {
        setMensajeFormulario(null)
    }, [fase])

    return (
        <section>
            <ProgressStepBar
                step={fase}
            />
            {fase === 1 && (
                <Tipo
                    setFase={setFase}
                    data={data}
                    setData={setData}
                />
            )}
            {fase === 2 && (
                <Categorias
                    setFase={setFase}
                    categorias={categorias}
                    setCategorias={setCategorias}
                />
            )}
            {fase === 3 && (
                <Datos
                    setFase={setFase}
                    data={data}
                    setData={setData}
                    mensajeFormulario={mensajeFormulario}
                    setMensajeFormulario={setMensajeFormulario}
                />
            )}
            {fase === 4 && (
                <Opcional
                    setFase={setFase}
                    data={data}
                    setData={setData}
                    categorias={categorias}
                    mensajeFormulario={mensajeFormulario}
                    setMensajeFormulario={setMensajeFormulario}
                />
            )}
        </section>
    );
}

export default Publicar