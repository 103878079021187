import ReactModal from 'react-modal';
import styles from './ModalDetallePlan.module.css'
import { FC } from 'react';
import { isSameDay } from 'date-fns';
import {formatDateShort} from '../../../utils/formatDate';

interface ModalDetallePlanProps {
  isOpen: boolean;
  onRequestClose: () => void;
  titulo: string;
  fechaInicio: Date;
  fechaFin: Date;
  urlImagen?: string;
  direccion: string;
  lat: number;
  lng: number;
}

// Establece el elemento raíz del modal en el documento (debe hacerse una vez)
ReactModal.setAppElement('#root');

const ModalDetallePlan: FC<ModalDetallePlanProps> = ({
  isOpen,
  onRequestClose,
  titulo,
  fechaInicio,
  fechaFin,
  urlImagen,
  direccion,
  lat,
  lng
}) => {

  const handleGetDirections = () => {

    const mapsURL = `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`;

    window.open(mapsURL, '_blank');
  };
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Detalle"
      className={styles.modal}
      overlayClassName={styles.overlay}
    >
      <h2 className={styles.tituloModalPlan}>{titulo}</h2>
      {urlImagen && <img src={urlImagen} alt="Detalle del plan" className={styles.imagenModalPlan}
        onError={(e) => { const target = e.target as HTMLImageElement; target.style.display = 'none'; }}
      />}
      <div className={styles.infoModalPlan}>
        <p className={styles.direccionModalPlan}>{direccion}</p>
        {isSameDay(fechaInicio, fechaFin)
          ? (
            <p className={styles.fechaModalPlan}>{formatDateShort(fechaInicio)}</p>
          ) : (
            <p className={styles.fechaModalPlan}>
              {formatDateShort(fechaInicio)} - {formatDateShort(fechaFin)}
            </p>
          )}
      </div>
      <div className={styles.botonesModalPlan}>
        <button onClick={onRequestClose} className={styles.botonCerrarModalPlan}>Cerrar</button>
        <button onClick={handleGetDirections} className={styles.botonComoLlegarPlan}>Cómo llegar</button>
      </div>
    </ReactModal>
  );
}

export default ModalDetallePlan;
