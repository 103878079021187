export const getLastLocation = () => {
    try {
        const coordenadasStorageString = localStorage.getItem('coordenadasStorage');
        const municipioStorage = localStorage.getItem('municipioStorage');
        let coordenadasStorage;

        if (coordenadasStorageString) {
            coordenadasStorage = JSON.parse(coordenadasStorageString);
        }

        return {coordenadasStorage, municipioStorage };

    } catch (error) {
        console.log(error);
        return {
            coordenadasStorage: null,
            municipioStorage: null
        };
    }
};