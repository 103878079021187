import { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

import styles from './Registro.module.css'
import useUsuariosApi from '../../hooks/useUsuariosApi';
import Loading from '../../components/Loading/Loading';
import ModalInformativo from '../../components/Modals/ModalInformativo/ModalInformativo';
import CheckboxPrivacidad from '../../components/CheckboxPrivacidad/CheckboxPrivacidad';
import { validateEmail } from '../../utils/validateEmail';


const Registro = () => {
  const [data, setData] = useState({
    nombre: '',
    correo: '',
    password: '',
    confirmarPassword: ''
  });
  const [errorFormulario, setErrorFormulario] = useState(null);
  const [mensaje, setMensaje] = useState(null)
  const [modalVisible, setModalVisible] = useState(false);
  const [aceptado, setAceptado] = useState(false);
  const [selectedInput, setSelectedInput] = useState(null);
  const [error, setError] = useState(null);
  const [errorPassword, setErrorPassword] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const { registrarUsuario, errorUsuarioApi, loading } = useUsuariosApi();

  const navigate = useNavigate();

  const handleSubmitRegistro = async () => {
    setError(null)
    setErrorFormulario(null)

    if (!data.password || !data.confirmarPassword || !data.correo || !data.nombre) {
      setErrorFormulario("Todos los campos son obligatorios");
      return
    }

    if (data.password.length < 8) {
      setErrorPassword('La contraseña debe tener al menos 8 caracteres');
      return
    }

    if (data.password !== data.confirmarPassword) {
      setErrorFormulario('Las contraseñas no coinciden');
      return;
    }

    if (!aceptado) {
      setErrorFormulario('Por favor acepta la política de privacidad');
      return;
    }

    const correctEmail = validateEmail(data.correo)
    if (!correctEmail) {
      setErrorFormulario("El correo electrónico no tiene un formato válido.");
      return;
    }

    // Omitir la propiedad confirmarContrasena al enviar data al backend
    const { confirmarPassword, ...dataSinConfirmar } = data;

    try {
      const res = await registrarUsuario(dataSinConfirmar);

      if (res) {
        setMensaje('')
        reiniciarFormulario()
        setModalVisible(true)
      }
    } catch (error) {
      setError(error || 'Ha ocurrido un error inesperado, por favorm vuelve a intentarlo.')
    }

  };

  const handleSegundoSubmit = async () => {
    setModalVisible(false)
    navigate('/iniciar-sesion');
  }

  const reiniciarFormulario = () => {
    setData({
      nombre: '',
      correo: '',
      password: '',
      confirmarPassword: ''
    });
  }

  const handleInputChange = (inputName, inputValue) => {
    setData({
      ...data,
      [inputName]: inputValue
    });
  };

  const handleNavigationLogin = () => {
    navigate('/iniciar-sesion');
  }

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleInputFocus = (event) => {
    setSelectedInput(event.target.name);
  };

  const handleInputBlur = () => {
    setSelectedInput(null);
  };

  const handleClosePrimerModal = () => {
    setModalVisible(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmitRegistro();
    }
  };

  return (
    <section className={styles.container}>
      <div className={styles.formGroup} >
        <label className={styles.formLabel}>Nombre:</label>
        <input
          className={`${selectedInput === 'nombre' ? styles.focused : ''}`}
          type='text'
          value={data.nombre}
          placeholder="Introduce tu nombre"
          name='nombre'
          onChange={(e) => handleInputChange('nombre', e.target.value)}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          onKeyDown={handleKeyDown}
        />
      </div>

      <div className={styles.formGroup}>
        <label className={styles.formLabel}>Correo electrónico:</label>
        <input
          className={`${selectedInput === 'correo' ? styles.focused : ''}`}
          value={data.correo}
          type="text"
          name='correo'
          autoCapitalize="none"
          placeholder="Introduce tu correo electrónico"
          onChange={(e) => handleInputChange('correo', e.target.value.trim())}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          autoComplete="email"
          onKeyDown={handleKeyDown}
        />
      </div>

      <div className={styles.formGroup}>
        <label className={styles.formLabel}>Contraseña:</label>
        <div className={styles.inputWrapper}>
          <input
            className={`${selectedInput === 'password' ? styles.focused : ''}`}
            value={data.password}
            name='password'
            type={showPassword ? 'text' : 'password'}
            autoCapitalize="none"
            placeholder="Introduce tu contraseña"
            onChange={(e) => handleInputChange('password', e.target.value.trim())}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            onKeyDown={handleKeyDown}
            autoComplete="current-password"
          />
          <div
            className={styles.togglePasswordButton}
            onClick={handleTogglePasswordVisibility}
          >
            {data.password && <>{showPassword ? <FaEyeSlash size={24} /> : <FaEye size={24} />}</>}
          </div>
        </div>
      </div>

      <div className={styles.formGroup}>
        <label className={styles.formLabel}>Confirmar contraseña:</label>
        <div className={styles.inputWrapper}>
          <input
            className={`${selectedInput === 'confirmarPassword' ? styles.focused : ''}`}
            value={data.confirmarPassword}
            name='confirmarPassword'
            type={showPassword ? 'text' : 'password'}
            autoCapitalize="none"
            placeholder="Introduce de nuevo tu contraseña"
            onChange={(e) => handleInputChange('confirmarPassword', e.target.value.trim())}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            onKeyDown={handleKeyDown}
            autoComplete="current-password"
          />
          <div
            className={styles.togglePasswordButton}
            onClick={handleTogglePasswordVisibility}
          >
            {data.confirmarPassword && <>{showPassword ? <FaEyeSlash size={24} /> : <FaEye size={24} />}</>}
          </div>
        </div>
      </div>
      <CheckboxPrivacidad aceptado={aceptado} setAceptado={setAceptado} />


      <button className={styles.buttonRegister} onClick={handleSubmitRegistro} disabled={loading}>
        Me registro
      </button>


      {loading && <Loading />}

      {errorPassword && <p className={styles.textoError}>{errorPassword}</p>}
      {errorUsuarioApi && <p className={styles.textoError}>{errorUsuarioApi}</p>}
      {mensaje && <p className={styles.textoConfirm}>{mensaje}</p>}
      {errorFormulario && <p className={styles.textoError}>{errorFormulario}</p>}
      {error && <p className={styles.textoError}>{error}</p>}


      <div className={styles.containerRegistro}>
        <span className={styles.textoSmall}>¿Ya tienes una cuenta?</span>
        <span className={styles.textoIrLogin} onClick={handleNavigationLogin}>Entra</span>
      </div>

      <ModalInformativo
        onRequestClose={handleClosePrimerModal}
        isOpen={modalVisible}
        onConfirm={handleSegundoSubmit}
        titulo={'Confirmar cuenta'}
        texto={'Te hemos enviado un correo para confirmar tu cuenta, recuerda revisar la carpeta de spam'}
      />
    </section>
  )
}

export default Registro