import React, { useState, FC } from 'react'
import styles from './ModalUpdatePassword.module.css'
import ReactModal from 'react-modal'
import useUsuariosApi from '../../../hooks/useUsuariosApi'
import Loading from '../../Loading/Loading'
import { FaEye, FaEyeSlash } from 'react-icons/fa'

interface ModalUpdatePasswordProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

const ModalUpdatePassword: FC<ModalUpdatePasswordProps> = ({
  isOpen,
  onRequestClose
}) => {

  const [data, setData] = useState({
    passwordPerfilActual: '',
    passwordPerfilNueva: '',
    passwordPerfilConfirm: '',
  })
  const [mensaje, setMensaje] = useState(null)
  const [showPassword, setShowPassword] = useState(false);

  const { errorUsuarioApi, setErrorUsuarioApi, loading, updateDatosUsuario } = useUsuariosApi();


  const handleActualizarPassword = async () => {
    const { passwordPerfilActual, passwordPerfilNueva } = data;

    if (data.passwordPerfilNueva !== data.passwordPerfilConfirm) {
      setErrorUsuarioApi('La nueva contraseña debe ser idéntica a la contraseña de "confirmar contraseña".');
      return;
    }

    setMensaje(null)
    setErrorUsuarioApi(null);

    const formData = new FormData();

    formData.append('passwordPerfilActual', passwordPerfilActual);
    formData.append('passwordPerfilNueva', passwordPerfilNueva);

    const res = await updateDatosUsuario({ passwordPerfilActual, passwordPerfilNueva })

    if (res) {
      setMensaje(res?.data?.mensaje || 'La contraseña ha sido actualizada')
      reiniciarFormulario()
    }
  }

  const reiniciarFormulario = () => {
    setData({
      passwordPerfilActual: '',
      passwordPerfilNueva: '',
      passwordPerfilConfirm: '',
    });
    /* placesRef.current?.clear();
    setBooleanFechaFin(false)
    setBooleanFechaInicio(false) */
  }

  const handleChange = (name, value) => {
    setData({ ...data, [name]: value });
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleActualizarPassword();
    }
  };

  const limpiarDatos = () => {
    reiniciarFormulario();
    setMensaje(null);
    setErrorUsuarioApi(null);
  }

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={() => {
        onRequestClose();
        limpiarDatos();
      }}
      contentLabel="Confirmación"
      className={styles.modal}
      overlayClassName={styles.overlay}
    >
      <h2 className={styles.tituloModal}>Cambiar Contraseña</h2>
      <div className={styles.formGroup}>
        <label htmlFor="passwordPerfilActual" className={styles.formLabelPerfil}>
          Contraseña actual
        </label>
        <div className={styles.inputWrapper}>
          <input
            type="password"
            name="passwordPerfilActual"
            id="passwordPerfilActual"
            className={styles.formInputPerfil}
            autoComplete="off"
            placeholder='Escribe tu contraseña'
            value={data.passwordPerfilActual}
            onChange={(e) => handleChange(e.target.name, e.target.value.trim())}
            onKeyDown={handleKeyDown}
          />
        </div>
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="passwordPerfilNueva" className={styles.formLabelPerfil}>
          Nueva contraseña
        </label>
        <div className={styles.inputWrapper}>
          <input
            type={showPassword ? 'text' : 'password'}
            name="passwordPerfilNueva"
            id="passwordPerfilNueva"
            className={styles.formInputPerfil}
            autoComplete="off"
            placeholder='Escribe la nueva contraseña'
            value={data.passwordPerfilNueva}
            onChange={(e) => handleChange(e.target.name, e.target.value.trim())}
            onKeyDown={handleKeyDown}
          />
          <div
            className={styles.togglePasswordButton}
            onClick={handleTogglePasswordVisibility}
          >
            {data.passwordPerfilNueva && <>{showPassword ? <FaEyeSlash size={24} /> : <FaEye size={24} />}</>}
          </div>
        </div>
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="passwordPerfilConfirm" className={styles.formLabelPerfil}>
          Confirmar contraseña
        </label>
        <div className={styles.inputWrapper}>
          <input
            type={showPassword ? 'text' : 'password'}
            name="passwordPerfilConfirm"
            id="passwordPerfilConfirm"
            className={styles.formInputPerfil}
            autoComplete="off"
            placeholder='Vuelve a escribir la nueva contraseña'
            value={data.passwordPerfilConfirm}
            onChange={(e) => handleChange(e.target.name, e.target.value.trim())}
            onKeyDown={handleKeyDown}
          />
          <div
            className={styles.togglePasswordButton}
            onClick={handleTogglePasswordVisibility}
          >
            {data.passwordPerfilConfirm && <>{showPassword ? <FaEyeSlash size={24} /> : <FaEye size={24} />}</>}
          </div>
        </div>
      </div>

      <div className={styles.botonesModal}>
        <button onClick={() => {
          onRequestClose();
          limpiarDatos();
        }} className={styles.botonCancelar}>Cancelar</button>
        <button onClick={handleActualizarPassword} className={styles.botonActualizar}>Actualizar</button>
      </div>
      {loading && <Loading />}
      {errorUsuarioApi && <p className={styles.textoError}>{errorUsuarioApi}</p>}
      {mensaje && <p>{mensaje}</p>}
    </ReactModal>
  )
}

export default ModalUpdatePassword