import React from 'react'
import styles from './Nosotros.module.css'
import { Bs1CircleFill, Bs2CircleFill, Bs3CircleFill, BsArrowRightCircleFill } from "react-icons/bs";
const Nosotros = () => {
  return (
    <article className={styles.container}>
      <h1 className={styles.titulo}>¿Quiénes somos?</h1>
      <section className={styles.info}>
        <div className={styles.containerTextInfo}>
          <p className={styles.textInfo}>Somos Victoria y Gabriel, tenemos 25 años y desde el verano de 2023 vivimos en un pequeño pueblo de Soria de unos 10 habitantes en invierno y más de 100 en verano. Victoria es maestra de primaria y Gabriel teletrabaja de programador informático.
          </p>
        </div>
        <div className={styles.imageContainer}>
          <img src='/images/nosotros.jpg' alt="Imagen de los creadores de Plan Local, Gabriel y Victoria" className={styles.image}
            onError={(e) => {
              const target = e.target as HTMLImageElement; target.style.display = 'none';
            }}
          />
        </div>
      </section>

      <section className={styles.info}>
        <div className={styles.imageContainer}>
          <img src='/images/foto-pueblo.jpg' alt="Imagen de los creadores de Plan Local, Gabriel y Victoria" className={styles.image}
            onError={(e) => {
              const target = e.target as HTMLImageElement; target.style.display = 'none';
            }}
          />
        </div>
        <div className={styles.containerTextInfo}>
          <p className={styles.textInfo}>En este pueblo nacieron nuestras abuelas y con 16 años se fueron a Barcelona a buscarse la vida. Desde pequeños hemos venido a este pueblo a pasar el verano, y como siempre nos ha gustado este estilo de vida, decidimos quedarnos a vivir todo el año.
          </p>
        </div>
      </section>

      <section className={styles.info}>
        <div className={styles.containerTextInfo}>
          <p className={styles.textInfo}>Desde la cuenta de Repoblando enseñamos nuestro día a día y lo que vamos aprendiendo en el mundo rural en nuestras redes de <a href="https://www.instagram.com/repoblando/" target='_blank'>Instagram</a> y de <a href="https://www.tiktok.com/@repoblando" target='_blank'>TikTok.</a></p>
        </div>
        <div className={styles.imageContainer}>
          <img src='/images/logo-repoblando-sin-fondo.png' alt="Imagen de los creadores de Plan Local, Gabriel y Victoria" className={styles.image}
            onError={(e) => {
              const target = e.target as HTMLImageElement; target.style.display = 'none';
            }}
          />
        </div>
      </section>

      <section className={styles.infoFooter}>
        <div>
          <h2 className={styles.textoPregunta}>¿Por qué hemos creado Plan Local?</h2>
          <ul className={styles.customList}>
            <li>
              <BsArrowRightCircleFill className={styles.icon} />
              <span>
                Cuando nos quedamos a vivir en el pueblo, sabíamos que  todas las semanas eran fiestas en algún pueblo  o había algún evento interesante pero nos costaba saber <strong>qué planes había  por nuestra comarca.</strong>
              </span>
            </li>
            <li>
              <BsArrowRightCircleFill className={styles.icon} />
              <span>
                Vimos que existían <strong> cuentas de Instagram </strong> donde la gente enviaba el cartel de los eventos que se organizaban en sus pueblos pero esto resultaba en un <strong> hilo infinito y desordenado de publicaciones.</strong>
              </span>
            </li>
            <li>
              <BsArrowRightCircleFill className={styles.icon} />
              <span>
                Por lo que decidimos desarrollar una <strong> aplicación para juntar y ordenar toda la oferta de ocio de cualquier lugar</strong>, con el objetivo de conectar los pueblos y las ciudades pequeñas para demostrar que en los <strong>lugares pequeños</strong> también <strong>hay planazos.</strong>
              </span>
            </li>
          </ul>
        </div>
        <div>
          <h2 className={styles.textoPregunta}>¿Para qué me sirve esta aplicación?</h2>
          <ul className={styles.customList}>
            <li>
              <BsArrowRightCircleFill className={styles.icon} />
              <span>
                Si quieres saber <strong>qué planes hay en tu zona </strong> para hacer hoy, el próximo fin de semana o durante tus vacaciones.
              </span>
            </li>
            <li>
              <BsArrowRightCircleFill className={styles.icon} />
              <span>
                Si estás de vacaciones, has ido a pasar unos días al pueblo y quieres <strong>descubrir los  próximos eventos y experiencias </strong> para hacer durante estos días.
              </span>
            </li>
            <li>
              <BsArrowRightCircleFill className={styles.icon} />
              <span>
                Si ofreces una experiencia u organizas eventos <strong>PlanLocal   es la opción ideal </strong> para promocionar tu plan y atraer al público local.
              </span>
            </li>
          </ul>
        </div>
      </section>
    </article>
  )
}

export default Nosotros