import React from 'react'
import { Link } from 'react-router-dom'
import styles from './Footer.module.css'

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <section className={styles.sectionInfo}>
        <div className={styles.divApp}>
          <p>Descárgate la  app</p>
          <a href='https://play.google.com/store/apps/details?id=com.EventZone&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' target="_blank" rel="noopener noreferrer">
            <img alt='Disponible en Google Play' className={styles.googlePlayBadgeFooter} src='/images/googlePlayBadge.png' />
          </a>
          <a href='https://apps.apple.com/es/app/planlocal/id6566418095?itsct=apps_box_link&itscg=30200' target="_blank" rel="noopener noreferrer">
            <img alt='Disponible en Apple Store' className={styles.appleBadgeFooter} src='/images/appleBadge.png' />
          </a>
        </div>
        <div className={styles.divRedesSociales}>
          <p>Síguenos</p>
          <a href="https://www.instagram.com/planlocal_/" target="_blank" rel="noopener noreferrer"><img src='/images/instagram.png' alt="Logo de Instagram - Síguenos en Instagram para obtener actualizaciones y contenido exclusivo" style={{ width: '50px', height: '50px', marginRight: '20px' }} /></a>
          <a href="https://www.facebook.com/EventZoneEvent" target="_blank" rel="noopener noreferrer"><img src='/images/facebook.png' alt="Logo de FaceBook - Síguenos en Instagram para obtener actualizaciones y contenido exclusivo" style={{ width: '50px', height: '50px', marginTop: '20px' }} /></a>
        </div>
        <div className={styles.navbarFooter}>
          <li>
            <Link to="/">Planes</Link>
          </li>
          <li>
            <Link to="/publicar">Publicar</Link>
          </li>
          <li>
            <Link to="/nosotros">Nosotros</Link>
          </li>
          {/* <li>
            <Link to="/contacto">Contacto</Link>
          </li> */}
        </div>
      </section>
      <section className={styles.sectionLegal}>
        <Link to="/aviso-legal" >Aviso legal</Link>
        <Link to="/condiciones-generales" >Condiciones generales</Link>
        <Link to="/cookies" >Cookies</Link>
        <Link to="/politica-privacidad" >Política de privacidad</Link>
      </section>
      <p className={styles.contactoProvisional}>Contacto: planlocal.contacto@gmail.com</p>
      <p className={styles.derechos}>&copy; 2024 PlanLocal.Todos los derechos reservados.</p>
    </footer>
  )
}

export default Footer