import React, { useEffect, useState, useRef } from 'react';
import { FaCheck, FaRegCircle, FaArrowRight } from 'react-icons/fa';
import styles from './Tipo.module.css';

const Tipo = ({ data, setData, setFase }) => {
  const [selected, setSelected] = useState(data.tipo || '');
  const [errorAvanzarFase, setErrorAvanzarFase] = useState(null);
  const errorRef = useRef(null);

  useEffect(() => {
    if (errorAvanzarFase) {
      if (errorRef.current) {
        errorRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    } 
  }, [errorAvanzarFase]);

  const handleAvanzarFase = () => {
    if (!selected) {
      setErrorAvanzarFase("Debes seleccionar una opción");
      return;
    }

    setFase(prevFase => prevFase + 1);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setErrorAvanzarFase(null);
  };

  const handleSelectedPlan = () => {
    setSelected('evento');
    setData(prevData => ({
      ...prevData,
      tipo: 'evento'
    }));
  };

  const handleSelectedExperiencia = () => {
    setSelected('experiencia');
    setData(prevData => ({
      ...prevData,
      tipo: 'experiencia'
    }));
  };

  return (
    <section className={styles.container}>
      <div className={styles.title}>
        <span>SELECCIONA UNA OPCIÓN</span>
      </div>
      <div className={styles.optionsContainer}>
        <div
          className={`${styles.optionCard} ${styles.evento}`}
          onClick={handleSelectedPlan}
        >
          <div className={styles.icon}>
            {selected === 'evento' ? <FaCheck /> : <FaRegCircle />}
          </div>
          <h3 className={styles.optionTitle}>Evento</h3>
          <p className={styles.optionDescription}>
            Se seleccionan las fechas disponibles y siempre aparecerá en la app. Por ejemplo: Tour guiado con reserva previa de lunes a viernes de 15h00 a 19h00.
          </p>
        </div>

        <div
          className={`${styles.optionCard} ${styles.experiencia}`}
          onClick={handleSelectedExperiencia}
        >
          <div className={styles.icon}>
            {selected === 'experiencia' ? <FaCheck /> : <FaRegCircle />}
          </div>
          <h3 className={styles.optionTitle}>Experiencia</h3>
          <p className={styles.optionDescription}>
            Se selecciona una fecha de inicio y una de finalización, tras suceder el evento se elimina automáticamente. Por ejemplo: Las fiestas patronales de un municipio.
          </p>
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.buttonContainer}>
          <button className={styles.continueButton} onClick={handleAvanzarFase}>
            Continuar <FaArrowRight className={styles.flechaAvanzarIcono} />
          </button>
        </div>
        {errorAvanzarFase && (
          <p className={styles.errorText} ref={errorRef}>{errorAvanzarFase}</p>
        )}
      </div>
    </section>
  );
};

export default Tipo;
