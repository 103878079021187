import React, { useEffect, useState } from 'react';
import RoutesComponent from './routes/RoutesComponent';
import './App.css'
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import AuthContext from './context/AuthContext';
import CookieBar from './components/CookieBar/CookierBar';


function App() {

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const comprobarLogin = async () => {
    const value = await localStorage.getItem('tokenLogueado');
    if (value) {
      setIsLoggedIn(true)
    }
  }

  useEffect(() => {
    comprobarLogin()
  }, []);

  return (
    <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn }}>
        <Navbar />
        <RoutesComponent />
        <CookieBar />
        <Footer />
    </AuthContext.Provider>
  );
}

export default App;
