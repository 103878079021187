import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom';
import { FaCircle, FaEye, FaEyeSlash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import styles from './Login.module.css'
import useUsuariosApi from '../../hooks/useUsuariosApi';
import AuthContext from '../../context/AuthContext';
import Loading from '../../components/Loading/Loading';
import ModalOlvidoPassword from '../../components/Modals/ModalOlvidoPassword/ModalOlvidoPassword';
import { GoogleLogin } from '@react-oauth/google';


const Login = () => {
  const [data, setData] = useState({ correo: '', password: '' });
  const [selectedInput, setSelectedInput] = useState(null);
  const [error, setError] = useState(null)
  const [modal, setModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const { errorUsuarioApi, loading, loginNormal, loginGoogle } = useUsuariosApi();

  const { setIsLoggedIn } = useContext(AuthContext);

  const navigate = useNavigate();

  const handleChange = (key, value) => {
    setData(prevData => ({ ...prevData, [key]: value }));
  }
  const handleLogin = async () => {
    try {
      const res = await loginNormal(data);
      if (res) {
        await localStorage.setItem('tokenLogueado', res.data.token);
        setIsLoggedIn(true);
        navigate('/');
      }
    } catch (error) {
      setError(error || "Ha ocurrido un error inesperado, por favor vuelve a intentarlo");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  };

  const handleNavigationRegistro = () => {
    navigate('/registro');
  }

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleInputFocus = (event) => {
    setSelectedInput(event.target.name);
  };

  const handleInputBlur = () => {
    setSelectedInput(null);
  };

  const handleCloseModal = () => {
    setModal(false);
  };

  const handleOpenModal = () => {
    setModal(true);
  };

  const handleLoginSuccess = async (response) => {

    try {
      const res = await loginGoogle(response.credential);
      if (res) {
        await localStorage.setItem('tokenLogueado', res.data.token);
        await localStorage.setItem('google', "true");
        setIsLoggedIn(true);
        navigate('/');
      }else{
        setError(error || "Ha ocurrido un error inesperado, por favor vuelve a intentarlo.");
      }
    } catch (error) {
      setError(error || "Ha ocurrido un error inesperado, por favor vuelve a intentarlo.");
    }
  };

  const handleLoginFailure = () => {
    setError(error || "Ha ocurrido un error inesperado, por favor vuelve a intentarlo.");
  };

  return (
    <section className={styles.container}>
      <h1>Entrar</h1>

      <form className={styles.inputContainer}>
        <div className={styles.inputWrapper}>
          <label htmlFor="correo">
            Correo electrónico
          </label>
          <input
            className={`${styles.input} ${selectedInput === 'correo' ? styles.focused : ''}`}
            type="email"
            name='correo'
            placeholder="Correo"
            id='correo'
            value={data.correo}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            onChange={(e) => handleChange('correo', e.target.value.trim())}
            autoComplete="email"
            onKeyDown={handleKeyDown}
          />
        </div>
        <div className={styles.inputWrapper}>
          <label htmlFor="password">
            Contraseña
          </label>
          <input
            className={`${styles.input} ${selectedInput === 'password' ? styles.focused : ''}`}
            type={showPassword ? 'text' : 'password'}
            name='password'
            id='password'
            placeholder="Contraseña"
            value={data.password}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            onChange={(e) => handleChange('password', e.target.value.trim())}
            onKeyDown={handleKeyDown}
            autoComplete="current-password"
          />
          <div
            className={styles.togglePasswordButton}
            onClick={handleTogglePasswordVisibility}
          >
            {data.password && <>{showPassword ? <FaEyeSlash size={24} /> : <FaEye size={24} />}</>}
          </div>
        </div>

      </form>
      <button className={styles.buttonLogin} onClick={handleLogin}>
        Iniciar sesión
      </button>

      {loading && <Loading />}
      {errorUsuarioApi && <p className={styles.error}>{errorUsuarioApi}</p>}
      {error && <p className={styles.error}>{error}</p>}

      <div className={styles.textoOlvidoPassword}>
        <span onClick={handleOpenModal}>He olvidado mi contraseña</span>
      </div>

      <div>
        <span className={styles.textoSmall}>O bien</span>
      </div>

      <div className={styles.containerGoogle}>
        <GoogleLogin
          onSuccess={handleLoginSuccess}
          onError={handleLoginFailure}
        />
      </div>

      <div className={styles.containerRegistro}>
        <span className={styles.textoSmall}>¿No tienes una cuenta?</span>
        <span className={styles.textoRegistro} onClick={handleNavigationRegistro}>Regístrate</span>
      </div>

      <ModalOlvidoPassword
        isOpen={modal}
        onRequestClose={handleCloseModal}
      />
    </section>
  )
}

export default Login