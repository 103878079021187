import React from 'react'
import { FaCircle, FaCheck } from 'react-icons/fa';
import styles from './ProgressStepBar.module.css';

const ProgressStepBar = ({ step }) => {

    const getIcon = (stepNumber) => {
        if (step > stepNumber) {
            return <FaCheck />;
        }
        if (step === stepNumber) {
            return <FaCircle />;
        }
        return stepNumber;
    };

    return (
        <section className={styles.progressContainer}>
            <div className={styles.stepContainer}>
                <div className={`${styles.progressStep} ${step >= 1 ? styles.active : ''}`}>
                    {getIcon(1)}
                </div>
                <div className={styles.stepLabel}>Tipo</div>
            </div>
            <div className={`${styles.progressLine} ${step > 1 ? styles.completed : ''}`}></div>
            <div className={styles.stepContainer}>
                <div className={`${styles.progressStep} ${step >= 2 ? styles.active : ''}`}>
                    {getIcon(2)}
                </div>
                <div className={styles.stepLabel}>Categoría</div>
            </div>
            <div className={`${styles.progressLine} ${step > 2 ? styles.completed : ''}`}></div>
            <div className={styles.stepContainer}>
                <div className={`${styles.progressStep} ${step >= 3 ? styles.active : ''}`}>
                    {getIcon(3)}
                </div>
                <div className={styles.stepLabel}>Datos</div>
            </div>
            <div className={`${styles.progressLine} ${step > 3 ? styles.completed : ''}`}></div>
            <div className={styles.stepContainer}>
                <div className={`${styles.progressStep} ${step >= 4 ? styles.active : ''}`}>
                    {getIcon(4)}
                </div>
                <div className={styles.stepLabel}>Opcional</div>
            </div>
        </section>
    )
}

export default ProgressStepBar;
