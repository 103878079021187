import React, { useState } from 'react'
import styles from './CrearOrganizacion.module.css'
import CheckboxPrivacidad from '../../components/CheckboxPrivacidad/CheckboxPrivacidad';
import Loading from '../../components/Loading/Loading';
import useOrganizacionApi from '../../hooks/useOrganizacionApi';
import { validateEmail } from '../../utils/validateEmail';

const CrearOrganizacion = () => {
    const [data, setData] = useState({
        nombre: '',
        descripcion: '',
        archivoLogo: null,
        direccion: '',
        emailContacto: '',
        telefonoContacto: '',
    });
    const [erroresFormulario, setErroresFormulario] = useState('');
    const [mensaje, setMensaje] = useState('');
    const [selectedInput, setSelectedInput] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [aceptaPoliticaPrivacidad, setAceptaPoliticaPrivacidad] = useState(false);

    const { crearOrganizacion, errorOrganizacionApi, loading, } = useOrganizacionApi();

    const handleChange = (name, value) => {
        setData({ ...data, [name]: value });
        setMensaje('')
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(URL.createObjectURL(file));
        setData({ ...data, archivoLogo: file });
    };

    const handleSubmit = async (e) => {
        // Evita el envío automático del formulario
        // Llama a tu hook personalizado que realiza la petición a la API
        // Pasando los datos del formulario como parámetros
        // Ejemplo: miHookPersonalizado(formData);
        e.preventDefault();

        setErroresFormulario('')

        setMensaje('')

        const { nombre, descripcion, direccion, emailContacto, telefonoContacto, archivoLogo, } = data;


        if (!nombre || !emailContacto || !archivoLogo) {
            setErroresFormulario("El nombre, el logo y el correo de contacto son obligatorios.")
            return;
        }

        if(!aceptaPoliticaPrivacidad){
            setErroresFormulario("Es necesario aceptar la política de privacidad.")
            return;
        }

        const correctEmail = validateEmail(emailContacto)
      if (!correctEmail) {
        setErroresFormulario("El correo electrónico no tiene un formato válido.");
        return;
      }

        const formData = new FormData();

        formData.append('nombre', nombre.trim());
        formData.append('direccion', direccion.trim());
        formData.append('telefonoContacto', telefonoContacto.trim());
        formData.append('emailContacto', emailContacto.trim());
        formData.append('descripcion', descripcion.trim());
        formData.append('archivoLogo', archivoLogo);

        const res = await crearOrganizacion(formData)

        if (res) {
            setMensaje(res?.data?.mensaje || 'La organización se ha creado correctamente')

            setErroresFormulario('')
        }

    };

    const handleInputFocus = (event) => {
        setSelectedInput(event.target.name);
    };

    const handleInputBlur = () => {
        setSelectedInput(null);
    };


    return (
        <section className={styles.sectionForm}>
            <form className={styles.eventForm} encType="multipart/form-data" onSubmit={handleSubmit}>
                <h1>Crear organización</h1>
                <div className={styles.formGroup}>
                    <label htmlFor="nombre">
                        Nombre
                    </label>
                    <input
                        type="text"
                        name="nombre"
                        id="nombre"
                        maxLength={50}
                        value={data.nombre}
                        className={` ${selectedInput === 'nombre' ? styles.focused : ''}`}
                        onFocus={handleInputFocus}
                        onBlur={handleInputBlur}
                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                        autoComplete="off"
                    />
                </div>
                <div className={styles.formGroup}>
                    <label htmlFor="descripcion">
                        Descripción
                    </label>
                    <textarea
                        name="descripcion"
                        id="descripcion"
                        value={data.descripcion}
                        className={`${styles.formTextarea} ${selectedInput === 'descripcion' ? styles.focused : ''}`}
                        onFocus={handleInputFocus}
                        onBlur={handleInputBlur}
                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                        autoComplete="off"
                    />
                     <small>No es obligatorio</small>
                </div>
                <div className={styles.formGroup}>
                    <label htmlFor="archivo">
                        Logo de la organización
                    </label>
                    <input
                        type="file"
                        name="archivo"
                        id="archivo"
                        className={`${styles.formArchivo} ${selectedInput === 'archivo' ? styles.focused : ''}`}
                        onFocus={handleInputFocus}
                        onBlur={handleInputBlur}
                        accept=".png, .jpg, .jpeg"
                        onChange={handleFileChange}
                    />
                     <small>Los archivos deben tener formato jpg o png.</small>
                    {selectedFile && (
                        <>
                            {/*  Revisar estilos classnName y style !!!!!!!!!!! */}
                            <p>Archivo adjuntado: </p>
                            <img src={selectedFile} className={styles.selectedFile} alt="Preview" style={{ maxWidth: '50%', maxHeight: '50%'}} />
                        </>
                    )}
                </div>
                <div className={styles.formGroup}>
                    <label htmlFor="direccion">
                        Dirección
                    </label>
                    <input
                        type="text"
                        name="direccion"
                        id="direccion"
                        maxLength={50}
                        value={data.direccion}
                        className={` ${selectedInput === 'direccion' ? styles.focused : ''}`}
                        onFocus={handleInputFocus}
                        onBlur={handleInputBlur}
                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                    />
                </div>
                <div className={styles.formGroup}>
                    <label htmlFor="emailContacto">
                        Correo electrónico de contacto
                    </label>
                    <input
                        type="text"
                        name="emailContacto"
                        id="emailContacto"
                        placeholder='Correo electrónico'
                        maxLength={50}
                        value={data.emailContacto}
                        className={` ${selectedInput === 'emailContacto' ? styles.focused : ''}`}
                        onFocus={handleInputFocus}
                        onBlur={handleInputBlur}
                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                    />
                </div>
                <div className={styles.formGroup}>
                    <label htmlFor="telefonoContacto">
                        Teléfono de contacto
                    </label>
                    <input
                        type="text"
                        name="telefonoContacto"
                        id="telefonoContacto"
                        placeholder='Teléfono'
                        maxLength={50}
                        value={data.telefonoContacto}
                        className={`${selectedInput === 'telefonoContacto' ? styles.focused : ''}`}
                        onFocus={handleInputFocus}
                        onBlur={handleInputBlur}
                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                        autoComplete="tel"
                    />
                    <CheckboxPrivacidad aceptado={aceptaPoliticaPrivacidad} setAceptado={setAceptaPoliticaPrivacidad}/>
                </div>
                <button
                    type='submit'
                    className={styles.buttonSubmit}
                    onClick={handleSubmit}
                    disabled={loading} 
                >
                    Crear
                </button>
            </form>
            {loading && <Loading />}
            {erroresFormulario && <p className={styles.error}>{erroresFormulario}</p>}
            {errorOrganizacionApi && <p className={styles.error}>{errorOrganizacionApi}</p>}
            {mensaje !== '' && <p>{mensaje}</p>}
        </section>
    )
}

export default CrearOrganizacion