import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import styles from './InputSearchPlace.module.css'
import { saveLocation } from '../../utils/saveLocationStorage';
import { FaSearch } from 'react-icons/fa';

interface InputSearchPlaceProps {
  data: any;
  setData: React.Dispatch<React.SetStateAction<any>>;
  idInput?: string;
  setNombreMunicipio?: (e) => void;
  handleBuscarPlanes?: (e) => void
}

const InputSearchPlace: React.FC<InputSearchPlaceProps> = ({ data, setData, idInput = 'idInput', setNombreMunicipio, handleBuscarPlanes }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const searchContainerRef = useRef(null);
  const [error, setError] = useState(null);
  const [mensaje, setMensaje] = useState('');

  const resultListRef = useRef(null);
  const initialSearchQuery = useRef('');

  useEffect(() => {
    const handleOutsideClick = (event) => {
      // Verifica si el clic se realizó fuera del componente de búsqueda
      if (searchContainerRef.current && !searchContainerRef.current.contains(event.target)) {
        setSearchResults([]); // Cierra los resultados de búsqueda
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };

  }, []);

  useEffect(() => {
    if (data) {
      setSearchQuery(data.direccion);
      initialSearchQuery.current = data.direccion;
      setSelectedLocation(data.direccion);
    }
  }, [data]);

  useEffect(() => {
    let timerId;


    if (searchQuery && searchQuery !== initialSearchQuery.current) {

      clearTimeout(timerId);
      timerId = setTimeout(searchLocations, 400); // Ajusta el tiempo de debounce según tus necesidades (por ejemplo, 300ms)
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [searchQuery]);

  const handleSearchChange = async (event) => {
    const query = event.target.value;
    setSearchQuery(query);

  };

  const searchLocations = async () => {
    if (searchQuery && searchQuery !== initialSearchQuery.current) {
      if (setNombreMunicipio) setNombreMunicipio(searchQuery)
      try {
        setMensaje('')
        setError('');
        const response = await axios.get(
          `
          https://api.tomtom.com/search/2/geocode/${searchQuery}.JSON?key=S29BGno6l29jpwfwkW5n6nzArEBknY8D&countrySet=ES

          `
        );
        //https://www.mapquestapi.com/geocoding/v1/address?key=PaewkdkQFhgfis8ehhh6sVNup56ZZSBd&location=${searchQuery}&locale=es_ES&country=ES&maxResults=5
        //https://dev.virtualearth.net/REST/v1/Locations?query=${searchQuery}&culture=es-ES&key=Av407K5n8-PbvLMxCx1tqNFK4ya2jH2RSk-NINciJK04YQULLF9Z2L-g2q0BPYCq
        //https://nominatim.openstreetmap.org/search?q=${searchQuery}&format=json&countrycodes=ES
        //https://api.locationiq.com/v1/autocomplete.php?key=pk.9862093e56094b11b4c9b4a9bc626f1a&q=${searchQuery}&tag=place:city,place:town,place:village,highway&accept-language=es&countrycodes=ES
        setSearchResults(response.data.results);
        /* 
                  const primerResultado = response.data.results[0]
        
                  const nombreMunicipio = primerResultado.address.municipalitySubdivision
                    ? primerResultado.address.municipalitySubdivision
                    : primerResultado.address.municipality;
        
                  const direccion = primerResultado.address.freeformAddress;
        
                  setData({
                    ...data,
                    direccion,
                    municipio: nombreMunicipio,
                    ubicacion: {
                      type: 'Point',
                      coordinates: [primerResultado.position.lon, primerResultado.position.lat],
                    }
                  }) */

        /* setSearchQuery(direccion);
        initialSearchQuery.current = direccion;
        setSelectedLocation(direccion); */

        if (response.data.length === "0") {
          setMensaje('No se ha encontrado ninguna dirección, por favor vuelve a intentarlo')
        }
      } catch (error) {
        console.error(error);
        setSearchResults([]);
        setError('No se han encontrado ubicaciones, por favor vuelve a intentarlo');
      }
    } else {
      setSearchResults([]);
    }
  };



  const handleLocationSelect = (location) => {

    let nombreMunicipio = location.address.municipalitySubdivision
      ? location.address.municipalitySubdivision
      : location.address.municipality;

    let direccion = location.address.freeformAddress;
    let municipio;
    if (location.address.streetName && !location.address.streetNumber) {
      municipio = nombreMunicipio + ", " + location.address.streetName;
    } else if (location.address.streetName && location.address.streetNumber) {
      municipio = nombreMunicipio + ", " + location.address.streetName + ", " + location.address.streetNumber;
    } else {
      municipio = nombreMunicipio;
    }

    /*  setSearchQuery(location.display_name); */
    setSearchResults([]);
    /* const { lat, lon, display_name } = location;
    const municipality = display_name.split(',')[0].trim(); */
    let lng = location.position.lon
    let lat = location.position.lat

    setData({
      ...data,
      direccion,
      municipio,
      ubicacion: {
        type: 'Point',
        coordinates: [lng, lat],
      }
    })

    setSearchQuery(direccion);
    initialSearchQuery.current = direccion;
    setSelectedLocation(direccion);

    /*  handleBuscarEventos(); */

    saveLocation({ lng, lat }, nombreMunicipio)
    //saveLocation(data.ubicacion.coordinates, data.direccion)
  };
  /* const handleSearch = (e) => {
    handleSearchChange(e);
    setData(searchResults[0])
    setData({
      ...data,
      direccion: searchResults[0].display_name.split(',')[0].trim(),
      ubicacion: {
        type: 'Point',
        coordinates: [searchResults[0].lon,  searchResults[0].lat],
      }
    })
  } */

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();

      const firstResult = searchResults[0];
      if (firstResult) {
        handleLocationSelect(firstResult);
      }
    }
  };

  return (
    <div className={styles.locationSearchContainer} ref={searchContainerRef}>
      <div className={styles.inputButton}>

        <input
          className={styles.locationSearchInput}
          name="ubicacion"
          id={idInput}
          type="text"
          placeholder="Buscar ubicación"
          value={searchQuery}
          onChange={handleSearchChange}
          onKeyDown={handleKeyDown}
          autoComplete="off"
        />
        {handleBuscarPlanes &&
          <button
            className={styles.botonBuscar}
            onClick={handleBuscarPlanes}
          >
          Buscar
          </button>}

        {/*  <button className="search-button" onClick={handleSearch}>
          <BsSearch size={24} />
        </button> */}
      </div>
      {/* {selectedLocation && <p>Ubicación seleccionada: {selectedLocation}.</p>} */}

      {error && <p>{error}</p>}
      {searchResults.length > 0 && (
        <ul className={styles.locationSearchResults} ref={resultListRef} onKeyDown={handleKeyDown} tabIndex={0}>
          {searchResults.map((result) => (
            <li
              key={result.id}
              onClick={() => handleLocationSelect(result)}
              className={
                selectedLocation &&
                  selectedLocation.id === result.id
                  ? styles.selected
                  : ''
              }
            >
              {result.address.freeformAddress}
            </li>
          ))}
        </ul>
      )}
      {mensaje !== '' && <p>{mensaje}</p>}
    </div>
  );
}

export default InputSearchPlace