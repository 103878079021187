import React, { useEffect, useRef, useState } from 'react'
import styles from './Datos.module.css'
import InputSearchPlace from '../../../../components/InputSearchPlace/InputSearchPlace';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import FechasExperiencia from '../../../../components/FechasExperiencia/FechasExperiencia';
import FechasEvento from '../../../../components/FechasEvento/FechasEvento';
import GooglePlacesAutocompleteComponent from '../../../../components/GooglePlacesAutocomplete/GooglePlacesAutocomplete';


const Datos = ({
  setFase,
  data,
  setData,
  mensajeFormulario,
  setMensajeFormulario,
}) => {

  const [selectedInput, setSelectedInput] = useState(null);
  /* const [selectedFile, setSelectedFile] = useState(null); */
  /*  const [siempreDisponible, setSiempreDisponible] = useState(false) */

  const errorRef = useRef(null);

  useEffect(() => {
    if (mensajeFormulario) {
      if (errorRef.current) {
        errorRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [mensajeFormulario]);

  const handleChange = (name, value) => {

    let transformedValue = value;

    if (name === 'fechaInicio' || name === 'fechaFin') {
      transformedValue = new Date(value);
    }

    setData({ ...data, [name]: transformedValue });
    setMensajeFormulario('')
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    /*  setSelectedFile(URL.createObjectURL(file)); */
    setData({
      ...data,
      urlArchivoTemporal: URL.createObjectURL(file),
      archivoCartel: file
    });
  };

  const removeImage = () => {
    setData({
      ...data,
      archivoCartel: null
    });
  };

  const handleInputFocus = (event) => {
    setSelectedInput(event.target.name);
  };

  const handleInputBlur = () => {
    setSelectedInput(null);
  };

  const handleAvanzarFase = () => {
    setMensajeFormulario('')
    const { titulo, direccion, municipio, ubicacion, archivoCartel, fechaInicio, fechaFin, horariosDisponibles, tipo } = data;

    if (!direccion || !municipio || !ubicacion) {
      setMensajeFormulario('Parece que no has introducido la ubicación, por favor selecciona una dirección ofrecida por el autocompletado.');
      return;
    }

    if (!titulo || !direccion || !municipio || !ubicacion || !archivoCartel) {
      setMensajeFormulario("Todos los campos son obligatorios.")
      return;
    }

    if (tipo === "evento") {
      if (!fechaFin) {
        setMensajeFormulario('Parece que no has indicado la fecha de finalización del evento, por favor pulsa sobre el botón "Seleccionar fecha de fin" para añadir la fecha del evento.')
        return
      }
      if (!fechaInicio) {
        setMensajeFormulario('Parece que no has indicado la fecha de inicio del evento, por favor pulsa sobre el botón "Seleccionar fecha de inicio" para añadir la fecha del evento.')
        return
      }

      if (fechaInicio > fechaFin) {
        setMensajeFormulario('La fecha de inicio no puede ser posterior a la fecha de fin.');
        return;
      }
      const hoy = new Date();
      const ayer = new Date();
      ayer.setDate(hoy.getDate() - 1);

      if (ayer > fechaFin) {
        setMensajeFormulario('La fecha de finalización no es correcta, no se pueden subir eventos del pasado.');
        return;
      }

      //unDiaEnMS ahora equivale a 60 días
      const unDiaEnMs = 1000 * 60 * 60 * 24;  //1000 (un segundo) multiplicado por 60 (un minuto) multiplicado por 60 (una hora) multiplicado por 24 (un día),
      const diferenciaEnMs = fechaFin.getTime() - fechaInicio.getTime();
      const diferenciaEnDias = Math.round(diferenciaEnMs / unDiaEnMs);

      if (diferenciaEnDias > 60) {
        setMensajeFormulario('La diferencia de fechas parece demasiado grande, si tu evento dura tantos días contacta a planlocal.contacto@gmail.com');
        return;
      }
    }

    if (tipo === "experiencia" &&
      (horariosDisponibles.length === 0 || horariosDisponibles.some(horario => !horario.horaInicio || !horario.horaFin))) {
      setMensajeFormulario("No has añadido los días y horarios disponibles");
      return;
    }
    // Nueva comprobación para verificar si la hora de fin es anterior a la hora de inicio
    if (tipo === "experiencia" && horariosDisponibles.some(horario => {
      const [horaInicioHoras, horaInicioMinutos] = horario.horaInicio.split(":").map(Number);
      const [horaFinHoras, horaFinMinutos] = horario.horaFin.split(":").map(Number);
      const horaInicioTotal = horaInicioHoras * 60 + horaInicioMinutos; // Convertir a minutos
      const horaFinTotal = horaFinHoras * 60 + horaFinMinutos; // Convertir a minutos
      return horaFinTotal < horaInicioTotal;
    })) {
      setMensajeFormulario("La hora de finalización no puede ser anterior a la hora de inicio");
      return;
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setFase(prevFase => prevFase + 1)
  }
  console.log(data)
  return (
    <section className={styles.container}>
      <div className={styles.formGroup}>
        {data.tipo === "evento" ?
          <label htmlFor="titulo">
            Título del evento
          </label>
          :
          <label htmlFor="titulo">
            Nombre de la experiencia
          </label>}
        <input
          type="text"
          name="titulo"
          id="titulo"
          maxLength={50}
          value={data.titulo}
          className={` ${selectedInput === 'titulo' ? styles.focused : ''}`}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
          autoComplete="off"
        />
        <small className={styles.textoSmall}>{0 + data.titulo.length} - 50</small>
      </div>

      <div className={styles.formGroup}>
        <label htmlFor="idInput">
          Ubicación
        </label>
        {/* <small>Por favor, introduce la ubicación de la forma más precisa posible (municipio, calle...). Es recomendable seleccionar una opción del autocompletado</small> */}
        {/*         <InputSearchPlace data={data} setData={setData} /> */}
        <GooglePlacesAutocompleteComponent data={data} setData={setData} />
      </div>

      <div className={styles.formGroup}>
        {data.tipo === "evento " ?
          <label htmlFor="archivo">
            Cartel del evento
          </label>
          :
          <label htmlFor="archivo">
            Adjunta un cartel promocional
          </label>}
        <input
          type="file"
          name="archivo"
          id="archivo"
          className={`${styles.formArchivo} ${selectedInput === 'archivo' ? styles.focused : ''}`}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          accept=".png, .jpg, .jpeg"
          onChange={handleFileChange}
        />
        <small className={styles.textoSmall}>Los archivos deben tener formato jpg o png.</small>
      </div>
      {data.urlArchivoTemporal && (
        <div className={styles.imageContainer}>
          {/*   <FaTimes className={styles.closeIcon} onClick={removeImage} /> */}
          <p>Cartel adjuntado: </p>
          <img src={data.urlArchivoTemporal} className={styles.selectedFile} alt="Previsualización cartel adjuntado" />
        </div>
      )}

      {data.tipo === "evento" ?
        (<FechasEvento
          data={data}
          setData={setData} />
        )
        :
        (
          <FechasExperiencia
            /*           siempreDisponible={siempreDisponible}
                      setSiempreDisponible={setSiempreDisponible} */
            data={data}
            setData={setData} />
        )}

      <div className={styles.containerButton}>
        <button className={styles.volverButton} onClick={() => setFase(prevFase => prevFase - 1)}>
          <FaArrowLeft className={styles.flechaVolverIcono} />
        </button>
        <button className={styles.continueButton} onClick={handleAvanzarFase}>
          Continuar <FaArrowRight className={styles.flechaAvanzarIcono} />
        </button>
      </div>
      {mensajeFormulario && <p className={styles.error} ref={errorRef}>{mensajeFormulario}</p>}

    </section>
  )
}

export default Datos