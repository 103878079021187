import ReactModal from 'react-modal';
import styles from './ModalConfirmacion.module.css'
import { FC } from 'react';

// Establece el elemento raíz del modal en el documento (debe hacerse una vez)
ReactModal.setAppElement('#root');

interface ModalConfirmacionProps {
  isOpen: boolean;
  onRequestClose: () => void;
  onConfirm: () => void;
  titulo: string;
  texto: string;
}

const ModalConfirmacion: FC<ModalConfirmacionProps> = ({
  isOpen,
  onRequestClose,
  onConfirm,
  titulo,
  texto
}) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Confirmación"
      className={styles.modal}
      overlayClassName={styles.overlay}
    >
      <h2 className={styles.tituloModal}>{titulo}</h2>
      <p>{texto}</p>
      <div className={styles.botonesModal}>
        <button onClick={onRequestClose} className={styles.botonCancelar}>Cancelar</button>
        <button onClick={onConfirm} className={styles.botonAceptar}>Confirmar</button>
      </div>
    </ReactModal>
  );
}

export default ModalConfirmacion;
