import React, { useState, useEffect, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import styles from "./Navbar.module.css";
import { FaUser,/*  FaTimes */ } from 'react-icons/fa';
import { RiArrowDropDownLine, RiArrowDropUpLine, RiPencilLine } from 'react-icons/ri';
import { AiOutlineUser, AiOutlineHeart } from 'react-icons/ai';
import { FaBuilding } from 'react-icons/fa';
import AuthContext from "../../context/AuthContext";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const { isLoggedIn } = useContext(AuthContext);

  const navbarRef = useRef(null);

  const cuentaRef = useRef(null);

  const toggleDropdown = (e) => {
    e.stopPropagation();
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleMenu = (e) => {
    e.stopPropagation();
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const closeMenu = (e) => {
      if (
        isMenuOpen &&
        navbarRef.current
      ) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('click', closeMenu);
    return () => {
      document.removeEventListener('click', closeMenu);
    };
  }, [isMenuOpen]);

  useEffect(() => {
    const closeMenu = (e) => {
      if (
        isDropdownOpen &&
        cuentaRef.current
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('click', closeMenu);
    return () => {
      document.removeEventListener('click', closeMenu);
    };
  }, [isDropdownOpen]);

  return (
    <nav className={styles.navbar} >
      <div className={styles.navbarBrand}>
        <Link to="/"><img src='/images/Logo.png' alt="Logo de PlanLocal" style={{ width: '60px', height: '60px', borderRadius: 10, marginTop: 5 }} /></Link>
      </div>
      <button className={styles.navbarToggle} onClick={toggleMenu}>
        <span className={styles.navbarToggleIcon}>{isMenuOpen ? <>&#10006;</> : <>&#9776;</>}</span>
      </button>
      <div className={`${styles.navbarDiv}  ${isMenuOpen ? styles.open : ""}`} ref={navbarRef}>
        <ul className={`${styles.navbarMenu} ${isMenuOpen ? styles.open : ""}`}>
          <li className={styles.navbarItem}>
            <Link to="/">Planes</Link>

          </li>
          <li className={styles.navbarItem}>
            <Link to="/publicar">Publicar</Link>
          </li>
          <li className={styles.navbarItem}>
            <Link to="/nosotros">Nosotros</Link>
          </li>
          <li className={styles.navbarItem} ref={cuentaRef} onClick={toggleDropdown}>
            <FaUser size={24} />
            {isDropdownOpen ? <RiArrowDropUpLine size={32} /> : <RiArrowDropDownLine size={32} />}
            {isDropdownOpen && (
              <ul className={styles.navbarDropdownMenu}>
                {isLoggedIn ? (<div onClick={toggleMenu}>
                  <li>
                    <AiOutlineUser color="#555555" size={24} />
                    <Link to="/perfil">Perfil</Link>
                  </li>
                  <li>
                    <AiOutlineHeart color="#555555" size={24} />
                    <Link to="/planes-favoritos">Guardados</Link>
                  </li>
                  <li>
                    <RiPencilLine color="#555555" size={24} />
                    <Link to="/mis-planes">Mis publicaciones</Link>
                  </li>
                  <li>
                    <FaBuilding  color="#555555" size={24} />
                    <Link to="/tus-organizaciones">Mis organizaciones</Link>
                  </li>
                  {/*  <li>
                    <BsPlusCircle color="#555555" size={24} />
                    <Link to="/publicar">Publicar</Link>
                  </li> */}
                  {/* <li>
                    <FaTimes color="#555555" size={24} />
                    <a href="#" onClick={logout}>Cerrar sesión</a>
                  </li> */}
                </div>
                ) : (
                  <div onClick={toggleMenu}>
                    <li>
                      <Link to="/iniciar-sesion">Iniciar sesión</Link>
                    </li>
                    <li>
                      <Link to="/registro">Registrarse</Link>
                    </li>
                  </div>
                )}
              </ul>
            )}
          </li>
          {/* <li className="navbar-item">
            <Link to="/contacto">Contacto</Link>
          </li> */}
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
