import React from 'react'
import './NotFound.css'

const NotFound = () => {
    return (
        <section>
            <h1>404 - Página no encontrada</h1>
            <p>Lo sentimos, la página que estás buscando no existe.</p>
        </section>
    )
}

export default NotFound