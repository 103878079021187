import { useState } from 'react';
import axios from 'axios';
import { Plan } from '../interfaces/Plan';
import { urlEndpoints } from '../utils/globalVariables';



const usePlanesApi = () => {

  const [planes, setPlanes] = useState<Plan[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [noPlanes, setNoPlanes] = useState(false);
  const [plan, setPlan] = useState<Plan>()
  const [totalPlanesPaginado, setTotalPlanesPaginado] = useState(null)
  const [mensajeApiPlanes, setMensajeApiPlanes] = useState(null)


  const getPlanes = async (lng, lat, municipio, radio, categorias, page, limit, fechaInicioFiltro, fechaFinFiltro) => {
    
    try {
      setLoading(true);
      setError(null);
      setMensajeApiPlanes(null)

      const response = await axios.get(`${urlEndpoints}/api/plan`, {
        params: { lng, lat, municipio, radio, categorias, page, limit, fechaInicioFiltro, fechaFinFiltro },
      });

      setPlanes(response.data.planes);
      setTotalPlanesPaginado(response.data.total)
      if (response.data.mensaje) setMensajeApiPlanes(response.data.mensaje)
     /*  if (response.data.mensaje) {
        setPlanes([]);
        setNoPlanes(true);
        setTotalPlanesPaginado(0)
        setMensajeApiPlanes(response.data.mensaje)
      } else {
        setPlanes(response.data.planes);
        setTotalPlanesPaginado(response.data.total)
        setNoPlanes(false);
      } */
      return response;

    }
    catch (error) {
      if (error.response && error.response.data) {
        setError(error.response.data.mensaje || 'Ha ocurrido un error inesperado al obtener los planes, por favor vuelve a intentarlo');
      } else {
        setError('Ha ocurrido un error de red, por favor vuelve a intentarlo');
      }
    }
    finally {
      setLoading(false);
    }
  };

  const crearPlan = async (newPlan) => {

    try {
      setLoading(true);
      setError(null);

      const token = await localStorage.getItem('tokenLogueado');

      if (!token) {
        setError('Es necesario iniciar sesión para añadir planes a la aplicación');
        setLoading(false);
        return
      }

      const response = await axios.post(`${urlEndpoints}/api/plan`, newPlan, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`,
        },
      });

      setMensajeApiPlanes(response.data.mensaje);
      setPlan(response.data.plan)

      return response;
    }
    catch (error) {
      console.log(error)
      setError(error.response.data.mensaje || 'Error al crear el plan');
    }
    finally {
      setLoading(false);
    }
  };

  const getPlanesDeUnUsuario = async () => {
    try {
      setLoading(true);

      setError(null);

      const token = localStorage.getItem('tokenLogueado');

      // Verificar si el token está presente y si es válido
      if (!token) {
        setError('Es necesario iniciar sesión para añadir planes a la aplicación');

        setLoading(false);
        return;
      }


      const response = await axios.get<Plan[]>(`${urlEndpoints}/api/plan/planesUsuario`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.status === 204) {
        setPlanes([]);
        setNoPlanes(true);
      } else {
        setPlanes(response.data);
        setNoPlanes(false);
      }


      return response;

    }
    catch (error) {
      setError(error.response?.data?.mensaje || 'Error al obtener planes');
    }
    finally {
      setLoading(false);
    }
  };

  const actualizarPlan = async (planId, plan) => {
    try {
      setLoading(true);
      setError(null);

      const token = localStorage.getItem('tokenLogueado');


      // Verificar si el token está presente y si es válido
      if (!token) {
        setError('Es necesario iniciar sesión para actualizar planes en la aplicación');

        setLoading(false);
        return;
      }

      const response = await axios.patch(`${urlEndpoints}/api/plan/${planId}`, plan, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`,
        },
      });

      return response;
    }
    catch (error) {

      setError(error.response?.data?.mensaje || 'Error al actualizar el plan, por favor vuelve a intentarlo');
    }
    finally {
      setLoading(false);
    }
  };

  const eliminarPlan = async (planId) => {

    try {
      setLoading(true);
      setError(null);

      const token = localStorage.getItem('tokenLogueado');

      // Verificar si el token está presente y si es válido
      if (!token) {
        setError('Es necesario iniciar sesión para eliminar planes en la aplicación');
        setLoading(false);
        return;
      }

      const response = await axios.delete(`${urlEndpoints}/api/plan/${planId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      await getPlanesDeUnUsuario();

      return response;

    }
    catch (error) {
      /* console.log(error.response) */

      setError(error.response?.data?.mensaje || 'Error al eliminar el plan');
    }
    finally {
      setLoading(false);
    }
  };

  const reportePlan = async (titulo, urlArchivo, urlPlan) => {
    try {
      setLoading(true);
      setError(null);

      const response = await axios.post(`${urlEndpoints}/api/plan/reportar-plan`, { titulo, urlArchivo, urlPlan })
      setError(null);
      return response;

    }
    catch (error) {
      setError(error.response?.data?.mensaje || 'Error al reportar el plan, por favor vuelve a intentarlo');
    }
    finally {
      setLoading(false);
    }
  }

  const anadirPlanFavorito = async (planId) => {
    try {
      setError(null)
      const token = localStorage.getItem('tokenLogueado');

      if (!token) {
        setError('Es necesario iniciar sesión para guardar planes como favoritos en la aplicación');

        return;
      }
      const response = await axios.post(`${urlEndpoints}/api/plan/agregar-plan-favorito/${planId}`, null, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      return response

    } catch (error) {
      console.log(error.response)

      setError(error.response?.data?.mensaje || 'Error al guardar el plan como favorito, por favor vuelve a intentarlo');
    }
  }

  const eliminarPlanFavorito = async (planId) => {

    try {
      setError(null)
      const token = localStorage.getItem('tokenLogueado');

      if (!token) {
        setError('Es necesario iniciar sesión para eliminar planes como favoritos en la aplicación');

        return;
      }
      const response = await axios.patch(`${urlEndpoints}/api/plan/eliminar-plan-favorito/${planId}`, null, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      return response

    } catch (error) {
      console.log(error.response)

      setError(error.response?.data?.mensaje || 'Error al eliminar el plan como favorito, por favor vuelve a intentarlo');
    }
  }

  const getPlanesFavoritos = async () => {
    try {
      setLoading(true);
      setError(null);

      const token = localStorage.getItem('tokenLogueado');

      // Verificar si el token está presente y si es válido
      if (!token) {
        setError('Es necesario iniciar sesión para añadir planes a la aplicación');

        setLoading(false);
        return
      }

      const response = await axios.get<Plan[]>(`${urlEndpoints}/api/plan/planesFavoritos`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.status === 204) {
        setPlanes([]);
        setNoPlanes(true);
      } else {
        setPlanes(response.data);
        setNoPlanes(false);
      }

      return response;
    }
    catch (error) {
      setError(error.response?.data?.mensaje || 'Error al obtener planes');
    }
    finally {
      setLoading(false);
    }
  };

  const getPlanPorId = async (id: string) => {
    try {
      setLoading(true);
      setError(null);

      const response = await axios.get<Plan>(`${urlEndpoints}/api/plan/detalle/${id}`);

      response.data.fechaInicio = new Date(response.data.fechaInicio)
      response.data.fechaFin = new Date(response.data.fechaFin)

      setPlan(response.data);

      return response;
    }
    catch (error) {
      setError(error.response?.data?.mensaje || 'Error inesperado al obtener el plan, por favor vuelve a intentarlo');
    }
    finally {
      setLoading(false);
    }
  };

  const destacarPlan = async (data) => {

    try {
      setLoading(true);
      setError(null);


      const token = await localStorage.getItem('tokenLogueado');

      // Verificar si el token está presente y si es válido
      if (!token) {
        setError('Es necesario iniciar sesión para añadir planes a la aplicación');
        setLoading(false);
        return
      }
console.log(data)
      const response = await axios.post(`${urlEndpoints}/api/emailDestacado`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      setMensajeApiPlanes(response.data.mensaje)

      return response;
    }
    catch (error) {
      console.log(error)
      setError(error.response.data.mensaje || 'Error al destacar el plan, por favor vuelve a intentarlo.');
    }
    finally {
      setLoading(false);
    }
  };

  return {
    plan,
    planes,
    loading,
    error,
    noPlanes,
    totalPlanesPaginado,
    mensajeApiPlanes,
    setLoading,
    getPlanes,
    getPlanesDeUnUsuario,
    setError,
    crearPlan,
    actualizarPlan,
    eliminarPlan,
    reportePlan,
    anadirPlanFavorito,
    getPlanesFavoritos,
    eliminarPlanFavorito,
    getPlanPorId,
    destacarPlan,
  };
};

export default usePlanesApi;