import React, {useEffect} from 'react'
import { useParams } from 'react-router-dom';

import styles from './ConfirmarCuenta.module.css'
import useUsuariosApi from '../../hooks/useUsuariosApi';
import Loading from '../../components/Loading/Loading';

const ConfirmarCuenta = () => {

  const { errorUsuarioApi, loading, confirmarCuenta, dataUsuario } = useUsuariosApi();
  const { token } = useParams();

  useEffect(() => {
    confirmarCuenta(token)
  }, [])
  


  return (
    <section>
      {loading ? <Loading/> :  <h1>{dataUsuario}</h1>}
     {errorUsuarioApi && !dataUsuario && <h1>{errorUsuarioApi}</h1>}
    </section>

  )
}

export default ConfirmarCuenta