import React from 'react'
import styles from './FechasEvento.module.css'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import es from 'date-fns/locale/es';

const FechasEvento = ({ data, setData }) => {

  const handleFechaInicioChange = (date) => {
    setData({ ...data, fechaInicio: date });
  }

  const handleFechaFinChange = (date) => {
    setData({ ...data, fechaFin: date });
  }

  return (
    <div>
      <div className={styles.textoPregunta}>
        <span>¿Cuándo será el evento?</span>
      </div>
      <div className={styles.containerFechas}>
        <div className={styles.formGroup}>
          <label htmlFor="fechaInicio">
            Desde
          </label>
          <DatePicker
            selected={data.fechaInicio}
            onChange={handleFechaInicioChange}
            showTimeSelect
            locale={es}
            id='fechaInicio'
            name="fechaInicio"
            className={styles.formFecha}
            dateFormat="dd/MM/yyyy HH:mm"
            timeFormat="HH:mm"
            timeIntervals={15}
            timeCaption="Hora"
            placeholderText="dd/mm/aaaa - --:--"
            minDate={new Date()}
            autoComplete="off"
          />
        </div>


        <div className={styles.formGroup}>
          <label htmlFor="fechaFin">
            Hasta
          </label>
          <DatePicker
            selected={data.fechaFin}
            onChange={handleFechaFinChange}
            showTimeSelect
            locale={es}
            name="fechaFin"
            id='fechaFin'
            className={styles.formFecha}
            dateFormat="dd/MM/yyyy HH:mm" // Formato de fecha y hora
            timeFormat="HH:mm" // Formato de hora
            timeIntervals={15} // Intervalos de tiempo en minutos
            timeCaption="Hora" // Etiqueta para la hora
            placeholderText="dd/mm/aaaa - --:--"
            minDate={new Date()}
            autoComplete="off"
          />
        </div>
      </div>
    </div>
  )
}

export default FechasEvento