import { useState } from 'react'
import { useParams } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

import styles from './RestablecerPassword.module.css'
import useUsuariosApi from '../../../hooks/useUsuariosApi';
import Loading from '../../../components/Loading/Loading';


const RestablecerPassword = () => {

    const [data, setData] = useState({
        passwordNueva: '',
        passwordConfirm: '',
    })
    const [mensaje, setMensaje] = useState('');
    const [selectedInput, setSelectedInput] = useState(null);
    const [error, setError] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const { errorUsuarioApi, loading, restablecerPassword } = useUsuariosApi();

    const { token } = useParams();


    const handleChange = (name, value) => {
        setData({ ...data, [name]: value });
    };

    const reiniciarFormulario = () => {
        setData({
            passwordNueva: '',
            passwordConfirm: '',
        });
        /* placesRef.current?.clear();
        setBooleanFechaFin(false)
        setBooleanFechaInicio(false) */
    }

    const handleSubmitRestablecerPassword = async () => {
        setMensaje(null)
        setError(null)

        if (data.passwordConfirm !== data.passwordNueva) {
            setError('Las contraseñas no coinciden');
            return;
        }

        if (data.passwordConfirm.length < 8) {
            setError("La nueva contraseña debe tener al menos 8 caracteres");
            return;
        }

        const res = await restablecerPassword(data, token);

        if (res) {
            setMensaje(res?.data?.mensaje || 'El evento se ha creado correctamente');
            reiniciarFormulario()
        }

    }

    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleInputFocus = (event) => {
        setSelectedInput(event.target.name);
    };

    const handleInputBlur = () => {
        setSelectedInput(null);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSubmitRestablecerPassword()
        }
    };

    return (
        <section className={styles.containerRestablecerPassword}>
            <h1>Restablecer Contraseña</h1>
            <div className={styles.formGroupRestablecerPassword}>
                <label htmlFor="restablecerPassword" className={styles.formLabelRestablecerPassword}>
                    Nueva contraseña
                </label>
                <div className={styles.containerPassword}>
                    <input
                         type={showPassword ? 'text' : 'password'}
                        name="passwordNueva"
                        id="restablecerPassword"
                        className={`${styles.formInputRestablecerPassword} ${selectedInput === 'passwordNueva' ? styles.focused : ''}`}
                        autoComplete="off"
                        placeholder='Escribe la nueva contraseña'
                        value={data.passwordNueva}
                        onFocus={handleInputFocus}
                        onBlur={handleInputBlur}
                        onChange={(e) => handleChange(e.target.name, e.target.value.trim())}
                        onKeyDown={handleKeyDown}
                    />
                    <div
                        className={styles.togglePasswordButton}
                        onClick={handleTogglePasswordVisibility}
                    >
                        {data.passwordNueva && <>{showPassword ? <FaEyeSlash size={24} /> : <FaEye size={24} />}</>}
                    </div>
                </div>
            </div>
            <div className={styles.formGroupRestablecerPassword}>
                <label htmlFor="restablecerConfirmPassword" className={styles.formLabelRestablecerPassword}>
                    Confirmar contraseña
                </label>
                <div className={styles.containerPassword}>
                    <input
                         type={showPassword ? 'text' : 'password'}
                        name="passwordConfirm"
                        id="restablecerConfirmPassword"
                        className={`${styles.formInputRestablecerPassword} ${selectedInput === 'passwordConfirm' ? styles.focused : ''}`}
                        autoComplete="off"
                        placeholder='Vuelve a escribir la nueva contraseña'
                        value={data.passwordConfirm}
                        onFocus={handleInputFocus}
                        onBlur={handleInputBlur}
                        onChange={(e) => handleChange(e.target.name, e.target.value.trim())}
                        onKeyDown={handleKeyDown}
                    />
                     <div
                         className={styles.togglePasswordButton}
                        onClick={handleTogglePasswordVisibility}
                    >
                        {data.passwordConfirm && <>{showPassword ? <FaEyeSlash size={24} /> : <FaEye size={24} />}</>}
                    </div>
                </div>
            </div>
            <button
                type='submit'
                className={styles.submitButtonRestablecerPassword}
                onClick={handleSubmitRestablecerPassword}
            >
                Actualizar
            </button>
            {loading && <Loading />}
            {errorUsuarioApi && <p className={styles.textoError}>{errorUsuarioApi}</p>}
            {error && <p className={styles.textoError}>{error}</p>}
            {mensaje && <p>{mensaje}</p>}
        </section>
    )
}

export default RestablecerPassword